import React from "react";
import { useState, useEffect, useRef } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import "ckeditor5/ckeditor5.css";
import { useTheme } from "@mui/material";
// import { UploadEditorImage } from "./UploadEditorImage";
import {
	ClassicEditor,
	AccessibilityHelp,
	Autoformat,
	AutoImage,
	Autosave,
	// Base64UploadAdapter,
	BlockQuote,
	Bold,
	CloudServices,
	Essentials,
	Heading,
	ImageBlock,
	ImageCaption,
	ImageInline,
	/*
	 * ImageInsert,
	 * ImageInsertViaUrl,
	 */
	ImageResize,
	ImageStyle,
	/*
	 * ImageTextAlternative,
	 * ImageToolbar,
	 * ImageUpload,
	 */
	Indent,
	IndentBlock,
	Italic,
	Link,
	LinkImage,
	List,
	ListProperties,
	// Markdown,
	
	// MediaEmbed,
	Paragraph,
	PasteFromOffice,
	SelectAll,
	TableCaption,
	TableCellProperties,
	TableColumnResize,
	TableProperties,
	TableToolbar,
	TextTransformation,
	TodoList,
	Underline,
	Undo
} from "ckeditor5";

const Editor = ({
	postContent,
	setPostContent
}: any) => {

	const editorContainerRef = useRef(null);
	const editorRef = useRef(null);
	const [ isLayoutReady, setIsLayoutReady ] = useState(false);
	const theme: any = useTheme();

	useEffect(() => {

		setIsLayoutReady(true);

		return () => setIsLayoutReady(false);
	
	}, []);

	const editorConfig: any = {
		toolbar: {
			items: [
				"undo",
				"redo",
				"|",
				"heading",
				"|",
				"bold",
				"italic",
				"underline",
				"|",
				"link",
				"insertImage",
				"mediaEmbed",
				"insertTable",
				"blockQuote",
				"|",
				"bulletedList",
				"numberedList",
				"todoList",
				"outdent",
				"indent"
			],
			shouldNotGroupWhenFull: false
		},
		plugins: [
			AccessibilityHelp,
			Autoformat,
			AutoImage,
			Autosave,
			BlockQuote,
			Bold,
			CloudServices,
			Essentials,
			Heading,
			ImageBlock,
			ImageCaption,
			ImageInline,
			/*
			 * ImageInsert,
			 * ImageInsertViaUrl,
			 */
			ImageResize,
			ImageStyle,
			// ImageTextAlternative,

			// ImageToolbar,

			// ImageUpload,
			Indent,
			IndentBlock,
			Italic,
			Link,
			LinkImage,
			List,
			ListProperties,
			// Markdown,

			// MediaEmbed,
			Paragraph,
			PasteFromOffice,
			SelectAll,
			TableCaption,
			TableCellProperties,
			TableColumnResize,
			TableProperties,
			TableToolbar,
			TextTransformation,
			TodoList,
			Underline,
			Undo
		],
		heading: {
			options: [
				{
					model: "paragraph",
					title: "Paragraph",
					class: "ck-heading_paragraph"
				},
				{
					model: "heading1",
					view : "h1",
					title: "Heading 1",
					class: "ck-heading_heading1"
				},
				{
					model: "heading2",
					view : "h2",
					title: "Heading 2",
					class: "ck-heading_heading2"
				},
				{
					model: "heading3",
					view : "h3",
					title: "Heading 3",
					class: "ck-heading_heading3"
				},
				{
					model: "heading4",
					view : "h4",
					title: "Heading 4",
					class: "ck-heading_heading4"
				},
				{
					model: "heading5",
					view : "h5",
					title: "Heading 5",
					class: "ck-heading_heading5"
				},
				{
					model: "heading6",
					view : "h6",
					title: "Heading 6",
					class: "ck-heading_heading6"
				}
			]
		},
		image: {
			toolbar: [
				"toggleImageCaption",
				"imageTextAlternative",
				"|",
				"imageStyle:inline",
				"imageStyle:wrapText",
				"imageStyle:breakText",
				"|",
				"resizeImage"
			]
		},
		// extraPlugins: [ MyCustomUploadAdapterPlugin ],
		initialData: postContent,
		link       : {
			addTargetToExternalLinks: true,
			defaultProtocol         : "https://",
			decorators              : {
				toggleDownloadable: {
					mode      : "manual",
					label     : "Downloadable",
					attributes: {
						download: "file"
					}
				}
			}
		},
		list: {
			properties: {
				styles    : true,
				startIndex: true,
				reversed  : true
			}
		},
		placeholder: "Type or paste your content here!",
		table      : {
			contentToolbar: [
				"tableColumn", "tableRow", "mergeTableCells", "tableProperties", "tableCellProperties"
			]
		}
	};

	return (
		<div>
			<div style={{ "--text-color": theme?.palette?.background?.paper } as React.CSSProperties}>
				<div className="main-container">
					<div className="editor-container editor-container_classic-editor" ref={editorContainerRef}>
						<div className="editor-container__editor">
							<div
								ref={editorRef}
							>
								{isLayoutReady &&
                  <CKEditor
                  	onChange={(event, editor) => {

                  		const data = editor.getData();

                  		setPostContent(data);
                  	
                  	}
                  	}
                  	editor={ClassicEditor}
                  	config={editorConfig}
										
                  />
								}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);

};

export default Editor;
