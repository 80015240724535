import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

// material-ui
import {
	Avatar,
	Box,
	// Chip,
	ClickAwayListener,
	List,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	Paper,
	Popper,
	Typography
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
// import AccountCircleRoundedIcon from "@mui/icons-material/AccountCircleRounded";
import LanguageRoundedIcon from "@mui/icons-material/LanguageRounded";
import ContactSupportRoundedIcon from "@mui/icons-material/ContactSupportRounded";


// third-party
import { FormattedMessage } from "react-intl";
import PerfectScrollbar from "react-perfect-scrollbar";

// project imports

// import User1 from "../../../../../assets/icons/user-round.svg";

// import useAuth from "../../../../../Hooks/useAuth";
import MainCard from "../../../../UI/cards/MainCard";
import Transitions from "../../../../UI/extend/Transitions";
import { useSelector } from "../../../../../store";

// assets

// import { IconLogout } from "@tabler/icons";
import useConfig from "../../../../../Hooks/useConfig";
import config from "../../../../../config/appConfig";
// import { auth } from "../../../../../firebase";

// import { signOut } from "firebase/auth";

// Profile Menu

const ProfileSection = () => {

	const theme: any = useTheme();
	const { borderRadius }: any = useConfig();
	const navigate = useNavigate();
	const routes = config.routes;
	const { profileIconPath } = useSelector((state: any) => state.user);

	const [ selectedIndex, setSelectedIndex ] = useState(-1);

	const [ open, setOpen ] = useState(false);
	const anchorRef: any = useRef(null);

	// const handleLogout = async () => {

	// 	try {

	// 		// await logout();

	// 		await signOut(auth);

	// 		navigate("/");

	// 	} catch (err) {

	// 		console.error(err);

	// 	}

	// };

	const handleClose = (event: any) => {

		if (anchorRef.current && anchorRef.current.contains(event.target)) {

			return;

		}
		setOpen(false);

	};
	const handleListItemClick = (event: any, index: any, route: any = "") => {

		setSelectedIndex(index);

		handleClose(event);

		if (route && route !== "") {

			navigate(route);

		}

	};
	const handleToggle = () => {

		setOpen(prevOpen => !prevOpen);

	};

	const prevOpen = useRef(open);
	useEffect(() => {

		if (prevOpen.current === true && open === false) {

			anchorRef.current.focus();

		}

		prevOpen.current = open;

	}, [ open ]);

	return (
		<>
			<Avatar
				src={profileIconPath}
				sx={{
					...theme.typography.mediumAvatar,
					margin: "8px 0 8px 8px !important",
					cursor: "pointer"
				}}
				ref={anchorRef}
				aria-controls={open ? "menu-list-grow" : undefined}
				aria-haspopup="true"
				aria-label="user-account"
				color="inherit"
				onClick={handleToggle}
				alt="user-images"
			/>

			<Popper
				placement="bottom"
				open={open}
				anchorEl={anchorRef.current}
				role={undefined}
				transition
				disablePortal
				modifiers={[
					{
						name   : "offset",
						options: {
							offset: [ 0, 14 ]
						}
					}
				]}
			>
				{({ TransitionProps }) =>
					<ClickAwayListener onClickAway={handleClose}>
						<Transitions in={open} {...TransitionProps}>
							<Paper>
								{open &&
									<MainCard
										border={false}
										elevation={16}
										content={false}
										boxShadow
										shadow={theme.shadows[16]}
									>
										<PerfectScrollbar
											style={{
												height   : "100%",
												maxHeight: "calc(100vh - 250px)",
												overflowX: "hidden"
											}}
										>
											<Box sx={{ p: 2, pt: 0 }}>
												<List
													component="nav"
													sx={{
														width                         : "100%",
														maxWidth                      : 350,
														minWidth                      : 300,
														backgroundColor               : theme.palette.background.paper,
														borderRadius                  : "10px",
														[theme.breakpoints.down("md")]: {
															minWidth: "100%"
														},
														"& .MuiListItemButton-root": {
															mt: 0.5
														}
													}}
												>
													<ListItemButton
														sx={{ borderRadius: `${borderRadius}px` }}
														selected={selectedIndex === 0}
														onClick={event => handleListItemClick(event, 0, routes.announcement) }
													>
														<ListItemIcon>
															<LanguageRoundedIcon />
														</ListItemIcon>
														<ListItemText
															primary={
																<Typography variant="body2">
																	<FormattedMessage id="community" />
																</Typography>
															}
														/>
													</ListItemButton>
													<ListItemButton
														sx={{ borderRadius: `${borderRadius}px` }}
														selected={selectedIndex === 1}
														onClick={event => handleListItemClick(event, 1, routes?.login) }
													>
														<ListItemIcon>
															<ContactSupportRoundedIcon />
														</ListItemIcon>
														<ListItemText
															primary={
																<Typography variant="body2">
																	<FormattedMessage
																		id="courses"
																		defaultMessage="Courses"
																	/>
																</Typography>
															}
														/>
													</ListItemButton>
												</List>
											</Box>
										</PerfectScrollbar>
									</MainCard>
								}
							</Paper>
						</Transitions>
					</ClickAwayListener>
				}
			</Popper>
		</>
	);

};

export default ProfileSection;
