import { createSlice, PayloadAction } from "@reduxjs/toolkit";

// create userSlice for above data

interface userType {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  points: number;
  profileCoverPath: string;
  profileIconPath: string;
  userType: string;
  userVerified: boolean;
  skillLevel: string;
  userProgressData: any;
  userAddress: any;
  lastViewedPost: string;
  lastViewedPostTime: string;
}

interface AppUserType { user: userType; }

const initialStateValues: userType = {

	id                : "",
	email             : "",
	firstName         : "",
	lastName          : "",
	points            : 0,
	profileCoverPath  : "",
	profileIconPath   : "",
	userType          : "",
	userVerified      : false,
	skillLevel        : "",
	userProgressData  : {},
	userAddress       : {},
	lastViewedPost    : "",
	lastViewedPostTime: ""

};

const userSlice = createSlice({
	name        : "user",
	initialState: initialStateValues,
	reducers    : {
		setFirstName(state, action: PayloadAction<string>) { state.firstName = action.payload; },
		setLastName(state, action: PayloadAction<string>) { state.lastName = action.payload; },
		setPoints(state, action: PayloadAction<number>) { state.points = action.payload; },
		setProfileCoverPath(state, action: PayloadAction<string>) { state.profileCoverPath = action.payload; },
		setProfileIconPath(state, action: PayloadAction<string>) { state.profileIconPath = action.payload; },
		setUserType(state, action: PayloadAction<string>) { state.userType = action.payload; },
		setUserVerified(state, action: PayloadAction<boolean>) { state.userVerified = action.payload; },
		setSkillLevel(state, action: PayloadAction<string>) { state.skillLevel = action.payload; },
		setUserProgressData(state, action: PayloadAction<any>) { state.userProgressData = action.payload; },
		setUser(state, action: PayloadAction<AppUserType>) {

			state.firstName = action.payload.user.firstName;
			state.lastName = action.payload.user.lastName;
			state.points = action.payload.user.points;
			state.profileCoverPath = action.payload.user.profileCoverPath;
			state.profileIconPath = action.payload.user.profileIconPath;
			state.userType = action.payload.user.userType;
			state.userVerified = action.payload.user.userVerified;
			state.email = action.payload.user.email;
			state.id = action.payload.user.id;
			state.skillLevel = action.payload.user.skillLevel;
			state.userProgressData = action.payload.user.userProgressData;
			state.userAddress = action.payload.user.userAddress;
			state.lastViewedPost = action.payload.user.lastViewedPost;
			state.lastViewedPostTime = action.payload.user.lastViewedPostTime;

		},
		setUserAddress(state, action: PayloadAction<any>) { state.userAddress = action.payload?.userAddress; },
		setLastViewedPost(state, action: PayloadAction<string>) { state.lastViewedPost = action.payload; },
		setLastViewedPostTime(state, action: PayloadAction<string>) { state.lastViewedPostTime = action.payload; }
	}
});

export const { setFirstName, setLastName, setPoints, setProfileCoverPath, setProfileIconPath, setUserType, setUserVerified, setUser, setSkillLevel, setUserProgressData, setUserAddress, setLastViewedPost, setLastViewedPostTime } = userSlice.actions;


// get data functions
export const getFirstName = (state: AppUserType) => state.user.firstName;
export const getLastName = (state: AppUserType) => state.user.lastName;
export const getPoints = (state: AppUserType) => state.user.points;
export const getProfileCoverPath = (state: AppUserType) => state.user.profileCoverPath;
export const getProfileIconPath = (state: AppUserType) => state.user.profileIconPath;
export const getUserType = (state: AppUserType) => state.user.userType;
export const getUserVerified = (state: AppUserType) => state.user.userVerified;
export const getUser = (state: AppUserType) => state.user;
export const getSkillLevel = (state: AppUserType) => state.user.skillLevel;
export const getUserProgressData = (state: AppUserType) => state.user.userProgressData;
export const getUserAddress = (state: AppUserType) => state.user.userAddress;
export const getLastViewedPost = (state: AppUserType) => state.user.lastViewedPost;
export const getLastViewedPostTime = (state: AppUserType) => state.user.lastViewedPostTime;

export default userSlice.reducer;