import React, { useState } from "react";
import { Button, Typography, useMediaQuery } from "@mui/material";
import Select from "../../../components/UI/Select";
// import ClearIcon from "@mui/icons-material/Clear";
import { Col, Row } from "rsuite";
import { useEffect } from "react";
import ApiRequest from "../../../api/ApiRequest";
import { APIUrl } from "../../../api/endPoints";
import Form from "react-bootstrap/Form";
import { useTheme } from "@mui/material";
import ModalComponent from "../../../components/modal";
import MainButton from "../../../components/UI/extend/MainButton";

const AddLevel = ({ isAddLevelModelOpen, setIsAddLevelModelOpen, courseOptionsList }:any) => {

	const [ courseOptions, setCourseOptions ] = useState<any>([]);
	const [ selectedCourse, setSelectedCourse ] = useState<any>(null);
	const [ selectedModule, setSelectedModule ] = useState<any>(null);
	const [ moduleOptions, setModuleOptions ] = useState<any>([]);
	const [ isError, setIsError ] = useState<boolean>(false);
	const [ errorMessage, setErrorMessage ] = useState<string>("");

	const [ levelName, setLevelName ] = useState<string>("");
	const [ levelDescription, setLevelDescription ] = useState<string>("");
	const theme: any = useTheme();
	const matchesXS = useMediaQuery(theme.breakpoints.down("md"));


	useEffect(() => {

		if (courseOptionsList && courseOptionsList.length > 0) {

			setCourseOptions(courseOptionsList);

		}
	
	}, [ courseOptionsList ]);


	const fetchAllModules = (courseId: string) => {

		ApiRequest("post", APIUrl.getAllModules, { courseId: courseId }).then((response: any) => {

			if (response?.data && response?.data.length > 0) {

				const options: any = [];

				response?.data?.map((item: any) => {

					options.push({ label: item.moduleName, value: item._id });

				});

				setModuleOptions(options);

			} else {
				
				setModuleOptions([]);
				
			}

		}).catch((error: any) => {

			console.error("Error fetching data", error);

		});
	
	};

	const handleCourseSelect = (selectedCourse: any) => {

		setSelectedCourse(selectedCourse);
		fetchAllModules(selectedCourse.value);

	};

	const handleModuleSelect = (selectedModule: any) => {
		
		setSelectedModule(selectedModule);
	
	};

	
	// };
	const handleAddLevel = () => {

		if (!selectedCourse || !selectedModule || levelName === "") {

			setIsError(true);
			setErrorMessage(" * Please fill all fields ");
			
			return;
		
		}
		const level: any = {
			levelData:
			{
				courseId        : selectedCourse.value,
				moduleId        : selectedModule.value,
				levelName       : levelName,
				levelDescription: levelDescription,
				levelIcon       : "" }
		};
		
		ApiRequest("post", APIUrl.addLevel, level).then((response: any) => {

			if (response) {
	
				setIsAddLevelModelOpen(false);
	
			}
	
		}).catch((error: any) => {
	
			console.error("Error fetching data", error);
	
		});

	
		setIsError(false);
		setErrorMessage("");
		setLevelName("");
		setLevelDescription("");
		setSelectedCourse(null);
		setSelectedModule(null);
	
	};
	

	const handleCancel = () => {

		setIsError(false);
		setErrorMessage("");
		setIsAddLevelModelOpen(false);
		setLevelName("");
		setLevelDescription("");
		setSelectedCourse(null);
		setSelectedModule(null);
	
	};

	return (
		<div>

			<ModalComponent
				show = {isAddLevelModelOpen}
				closeButton = {true}
				size = "lg"
				onHide = {handleCancel}
				modalTitle = {
					<div>
						<Typography variant="h4" className="sh-modal-title">Create Level Module</Typography>
					</div>
				}
				modalContent = {
					<div>
						<Row className="sh-add-module-select-module-con">
							<Col className="sh-add-select-course-con">
								<Typography variant="h5" className="sh-add-module-label">Select Course <span style={{ color: theme?.palette?.error?.main }} className="sh-add-lesson-marginTop">*</span></Typography>
								<Select
									className="sh-add-module-select-course"
									options={courseOptions}
									value={selectedCourse}
									handleChange={handleCourseSelect}
									placeholder="Select Course"
								/>
							</Col>
							<Col className="sh-add-module-name-con">
								<Typography variant="h5" className="sh-add-module-label">Select Module <span style={{ color: theme?.palette?.error?.main }} className="sh-add-lesson-marginTop">*</span></Typography>
								<Select
									className="sh-add-module-select-course"
							
									options={moduleOptions}
									value={selectedModule}
									handleChange={handleModuleSelect}
									placeholder="Select Module"
								/>
							</Col>

						</Row>
						<Row className="sh-add-module-name-con">
							<Col className="sh-add-module-description-con-col sh-add-module-des-pad"
							>
								<Typography variant="h5" className="sh-add-module-name-label">Level Name <span style={{ color: theme?.palette?.error?.main }} className="sh-add-lesson-marginTop">*</span></Typography>
								<Form.Control
									className="sh-add-module-name-text-field sh-add-module-name-text-field-level custom-placeholder"
									id="outlined-basic"
									placeholder="Enter Level Name"
									value={levelName}
									style={{ backgroundColor: theme?.palette.background.paper, color: theme?.palette.text.primary }}
									onChange={(e: any) => setLevelName(e?.target?.value)}
									color="secondary"
									onFocus={(e: any) => {

										try {

											const focusColor: string = theme.palette.secondary.main;

											e.target.style.borderColor = focusColor;
											e.target.style.borderWidth = "2px";
											e.target.style.fontSize = "13px";
											e.target.style.boxShadow = `rgba(0, 0, 0, 0.16) 0px 1px 0px, ${focusColor} 0px 0px 0px 0.3px`;
									
										} catch (error: any) {

											console.error("Error on focus", error);

										}
							
									}}
									onBlur={(e: any) => {

										try {

											e.target.style.borderColor = "";
											e.target.style.boxShadow = "";
									
										} catch (error: any) {

											console.error("Error on blur", error);

										}
							
									}}
								/>
								<style>
									{`
  						.custom-placeholder::placeholder {
								color:${theme.palette.text.primary} !important;
								opacity: 1;
								font-size: 14px;
  							}
							`}
								</style>
							</Col>
						</Row>
						<Row className="sh-add-module-description-con-row">
							<Col className="sh-add-module-description-con-col">
								<Typography variant="h5" className="sh-add-module-description-label">Level Description</Typography>
								<Form.Control
									as="textarea"
									placeholder="Enter Module Description"
									className="sh-add-module-description-text-area sh-add-module-fontsize-minheight custom-placeholder"
									value={levelDescription}
									onChange={(e: any) => {

										setLevelDescription(e?.target?.value);

									}}
									onFocus={(e: any) => {

										const focusColor: string = theme.palette.secondary.main;
										e.target.style.borderColor = focusColor;
										e.target.style.borderWidth = "2px";
										e.target.style.boxShadow = `rgba(0, 0, 0, 0.16) 0px 1px 0px, ${focusColor} 0px 0px 0px 0.3px`;
							
									}}
									onBlur={(e: any) => {

										e.target.style.borderColor = "";
										e.target.style.boxShadow = "";
							
									}}
									style={{ backgroundColor: theme?.palette.background.paper, color: theme?.palette.text.primary }}
								/>
						
							</Col>
						</Row>
						<Row className="sh-add-level-head-button-alignment" style={{ paddingTop: "15px" }}>
							
							{/* <div className="sh-add-level-button-alignment"> */}
							{/* <Col>
									<Button
										className="sh-add-module-add-module-btn"
										color="secondary"
										onClick={handleCancel}
										style={{
											borderColor: theme.palette.secondary.main,
											color      : theme.palette.secondary.main
										}}
									>
                Cancel
									</Button>
								</Col> */}
							{/* <Col>
							
									<Button
										className="sh-add-module-add-module-btn"
										variant="contained"
										color="secondary"
										onClick={handleAddLevel}
							
									>
                    Add Level
									</Button>
						
								</Col> */}
							{/* </div> */}
						</Row>
					</div>
				}
				modalFooter = {
					<div>
						<div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-end" }}>
							<Row style={{ float: "right" }}>
								<Col className="sh-add-level-error-msg-alignment-error" >
									{
										isError &&
							<div className="">
								<Typography variant="subtitle1" sx={{ color: theme?.palette?.secondary.main }}> {errorMessage} </Typography>
							</div>
									}
								</Col>
							</Row>
						</div>
						<Row>
							<div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", alignItems: "center" }}>
								<MainButton
									type= "scale"
									direction="bottom"
									style={{ position: "relative", marginRight: "10px" }}
									// offset={matchesXS ? 0 : 20}
									scale={1.04}
								>
									<Button
										className="sh-add-module-add-module-btn"
										color="secondary"
										onClick={handleCancel}
										style={{
											borderColor: theme.palette.secondary.main,
											color      : theme.palette.secondary.main
										}}
									>
                Cancel
									</Button>

								</MainButton>
								<MainButton
									type="scale"
									direction="bottom"
									style={{ position: "relative" }}
									offset={matchesXS ? 0 : 20}
									scale={1.04}>
									<Button
										className="sh-add-module-add-module-btn"
										variant="contained"
										color="secondary"
										onClick={handleAddLevel}
									>
                    Add Level
									</Button>
								</MainButton>
							</div>
						</Row>
					</div>
				}

				
			/>
			
			
		</div>
				
			
	);

};

export default AddLevel;