import PropTypes from "prop-types";
import React, { createContext } from "react";

// project import
import defaultConfig from "../config/config";
import useLocalStorage from "../Hooks/useLocalStorage";

// initial state
const initialState = {
	...defaultConfig,
	onChangeLayout       : () => { },
	onChangeDrawer       : () => { },
	onChangeMenuType     : () => { },
	onChangePresetColor  : () => { },
	onChangeLocale       : () => { },
	onChangeRTL          : () => { },
	onChangeContainer    : () => { },
	onChangeFontFamily   : () => { },
	onChangeBorderRadius : () => { },
	onChangeOutlinedField: () => { }
};

// Config Context & Provider

const ConfigContext = createContext(initialState);

function ConfigProvider({ children }: any) {

	const [ config, setConfig ] = useLocalStorage("app-config", {
		layout        : initialState.layout,
		drawerType    : initialState.drawerType,
		fontFamily    : initialState.fontFamily,
		borderRadius  : initialState.borderRadius,
		outlinedFilled: initialState.outlinedFilled,
		navType       : initialState.navType,
		presetColor   : initialState.presetColor,
		locale        : initialState.locale,
		rtlLayout     : initialState.rtlLayout
	});

	const onChangeLayout = (layout: any) => {

		setConfig({
			...config,
			layout
		});

	};

	const onChangeDrawer = (drawerType: any) => {

		setConfig({
			...config,
			drawerType
		});

	};

	const onChangeMenuType = (navType: any) => {

		setConfig({
			...config,
			navType
		});

	};

	const onChangePresetColor = (presetColor: any) => {

		setConfig({
			...config,
			presetColor
		});

	};

	const onChangeLocale = (locale: any) => {

		setConfig({
			...config,
			locale
		});

	};

	const onChangeRTL = (rtlLayout: any) => {

		setConfig({
			...config,
			rtlLayout
		});

	};

	const onChangeContainer = () => {

		setConfig({
			...config,
			container: !config.container
		});

	};

	const onChangeFontFamily = (fontFamily: any) => {

		setConfig({
			...config,
			fontFamily
		});

	};

	const onChangeBorderRadius = (event: any, newValue: any) => {

		setConfig({
			...config,
			borderRadius: newValue
		});

	};

	const onChangeOutlinedField = (outlinedFilled: any) => {

		setConfig({
			...config,
			outlinedFilled
		});

	};

	return (
		<ConfigContext.Provider
			value={{
				...config,
				onChangeLayout,
				onChangeDrawer,
				onChangeMenuType,
				onChangePresetColor,
				onChangeLocale,
				onChangeRTL,
				onChangeContainer,
				onChangeFontFamily,
				onChangeBorderRadius,
				onChangeOutlinedField
			}}
		>
			{children}
		</ConfigContext.Provider>
	);

}

ConfigProvider.propTypes = {
	children: PropTypes.node
};

export { ConfigContext, ConfigProvider };

