import React, { useEffect, useState } from "react";

// mui imports
import { Typography, Container, Grid, CardMedia, Rating } from "@mui/material";
import { useTheme, useMediaQuery } from "@mui/material";
// import StarIcon from "@mui/icons-material/Star";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";

// rsuite imports
import { Row, Col } from "rsuite";

// navigate  import
import { useNavigate, useLocation } from "react-router-dom";
import config from "../../config/appConfig";
import { APIUrl } from "../../api/endPoints";

// store import
import { useSelector } from "../../store";

// custom imports
import ApiRequest from "../../api/ApiRequest";
import CustomLoader from "../../components/UI/cards/CustomLoader";
import SubCard from "../../components/UI/cards/SubCard";
import MainButton from "../../components/UI/extend/MainButton";
import Back from "../../components/UI/Back";

// assests

// import svgImage from "../../assets/images/dummy.svg";

const ListModules = () => {

	// navigte imports
	const navigate = useNavigate();
	const location = useLocation();
	const state = location.state;

	// theme qurey imports
	const theme: any = useTheme();
	const matchesXS = useMediaQuery(theme.breakpoints.down("md"));

	// user data imports
	const { id } = useSelector((state: any) => state.user);

	// useState
	const [ isUserDataLoading, setIsUserDataLoading ] = useState(true);
	const [ courseData, setCourseData ] = useState([]);
	const [ copyCourseData, setCopyCourseData ] = useState([]);
	const [ filterTab, setFilterTab ] = useState("all");
	const [ hoveredCardIndex, setHoveredCardIndex ] = useState(-1);

	let isDataFetched = false;

	useEffect(() => {

		if (isDataFetched && id !== "") return;

		isDataFetched = true;

		if (state && state.id) {

			setIsUserDataLoading(false);

			const payload = {
				courseId: state.id
			};

			ApiRequest("post", APIUrl.listAllModuleByCourseId, payload).then((response: any) => {

				setCourseData(response);
				setCopyCourseData(response);
				setIsUserDataLoading(false);

			}).catch((error: any) => {

				console.error(error);
				setIsUserDataLoading(false);


			});

		} else {

			navigate(config.routes.course);

		}


	}, [ ]);

	const handleFilterChange = ( event: any, newValue: any ) => {

		setFilterTab(newValue);

		if (newValue === config?.enrolled) {

			const enrolledData = copyCourseData.filter((course: any) => {

				return course.unlocked;

			});

			setCourseData(enrolledData);

		} else {

			setCourseData(copyCourseData);

		}

	};

	const filteredCourseData = courseData.filter((course: any) => {

		return course.level > 0;
	
	});

	return (
		<>{
			isUserDataLoading ? <CustomLoader /> : <Container maxWidth="lg">
				<div>
					<Back
						props={{
							route  : config?.routes?.course,
							state  : null,
							toolTip: "Back to Course List"
						}}
					/>
				</div>
				<Row style={{
					textAlign: "center"
				}}>
					<Col className="course-list-header-col-con" md={12} sm={12} xs={12}>
						<Typography variant="h2"><span style={{
							color: theme?.palette?.text.primary
						}}>{state?.name}</span></Typography>
					</Col>
				</Row>
				<Row
					className="sh-list-modules-filter-tab-con"
				>
					<TabContext value={filterTab} >
						<TabList onChange={handleFilterChange} style={{ minHeight: "20px" }} className="sh-list-modules-filter-tab-tablist-con">
							<Tab
								className="sh-list-modules-filter-tab"
								style={{
									minHeight      : "20px",
									backgroundColor: filterTab == config?.all ? theme?.palette?.secondary?.main : "#fff",
									color          : filterTab == config?.all ? "#fff" : "#000"
								}}
								label="All"
								value={config?.all}
							/>
							<Tab
								className="sh-list-modules-filter-tab"
								style={{
									minHeight      : "20px",
									backgroundColor: filterTab == config?.enrolled ? theme?.palette?.secondary?.main : "#fff",
									color          : filterTab == config?.enrolled ? "#fff" : "#000"
								}}
								label="Enrolled Modules"
								value={config?.enrolled}
							/>
						</TabList>
					</TabContext>
				</Row>
				<Row>
					<Col style={{ width: "100%" }} md={12} sm={12} xs={12}>
						{filteredCourseData.length > 0 &&
							<Grid container spacing={2}>

								{/* {courseData && courseData.map((course: any, index: any) => { */}
								{filteredCourseData && filteredCourseData.map((course: any, index: any) => {

									return <>
										<Grid item
											xs={12}
											sm={6}
											md={3}
											lg={3}
											xl={3}
											key={index}
										>
											<div
												onMouseEnter={() => setHoveredCardIndex(index)}
												onMouseLeave={() => setHoveredCardIndex(-1)}
											>
												<MainButton
													type="scale"
													direction="bottom"
													style={{ position: "relative", cursor: "pointer", minHeight: "22em", maxHeight: "22em" }}
													offset={matchesXS ? 0 : 20}
													scale={1.04}>
													<SubCard
														onClick={() => {

															const newCourseData = course;

															if (course.level > 0) {

																newCourseData["courseType"] = state?.name;

																if (!newCourseData?.unlocked) {

																	navigate(config.routes.moduleIntro, { state: { moduleData: JSON.stringify(newCourseData) } });

																} else if (newCourseData?.unlocked) {

																	navigate(config?.routes?.video, { state: { moduleData: newCourseData } });

																}

															}

														}}
														content={true}
														contentClass="course-list-card-content"
														key={course._id}
														style={{ minHeight: "22em", maxHeight: "22em" }}
													>
														<div style={{ position: "relative" }}>
															<CardMedia
																component="img"
																// src={`${config.appDomain}/${course.moduleIcon}` || svgImage}
																alt={course.moduleName}
																height="150"
																width="150"
																image={`${config.appDomain}/${course.moduleIcon}`}
																title={course.moduleName}
															/>
															{
																hoveredCardIndex === index && (!course.unlocked || course.level == 0) &&
											<div
												className="sh-list-modules-over-lay"
												onClick={() => {

													const newCourseData = course;
	
													newCourseData["courseType"] = state?.name;
	
													if (!newCourseData?.unlocked) {
	
														navigate(config.routes.moduleIntro, { state: { moduleData: JSON.stringify(newCourseData) } });
	
													} else if (newCourseData?.unlocked) {
	
														navigate(config?.routes?.video, { state: { moduleData: newCourseData } });
	
													}
	
												}}
											>
												<Typography style={{ fontSize: "1.2em" }} variant="body1">{ course.level == 0 ? "No Content" : "Unlock Now" }</Typography>
											</div>
															}
														</div>
														<Grid container spacing={2}>
															<Grid item xs={12} sm={12} md={12}>
																<Typography className="course-desc-con" sx={{
																	// color: theme?.palette?.text?.primary
																	color: theme?.palette?.text?.primary
																}} variant="h5">{course.moduleName}</Typography>

																<div className="sh-list-modules-lock-icon-videos-text-con">
																	<Typography className="course-duration-con"
																		style={{ color: theme?.palette?.text.secondary }}
																		variant="body1">
																		<span style={{ color: theme?.palette?.text.primary }}
																		>{course?.level ? course?.level : 0}</span > Videos</Typography>
																	{
																		!course.unlocked && <LockOutlinedIcon />
																	}
																</div>
																<div>
																	<Rating
																		readOnly
																		defaultValue={course?.averageRating}
																		precision={0.5}
																	/>
																</div>
															</Grid>
														</Grid>

													</SubCard>
												
												</MainButton>
											</div>
										</Grid>
									</>;

								}

								)}
							</Grid>
						}
					</Col>
				</Row>

			</Container >
		}
		</>

	);

};

export default ListModules;
