import React, { useEffect, useRef } from "react";


const RefundPolicy = () => {

	const refundPolicyRef = useRef<HTMLDivElement>(null);

	useEffect(() => {

		if (refundPolicyRef.current) {

			refundPolicyRef.current.scrollIntoView({ behavior: "smooth" });
		
		}
	
	}, []);

	return (
		<div style={{ padding: "20px", lineHeight: 2.5 }}>
			<p><h2><strong>Return, Refund, Exchange, Shipping and Delivery Policy</strong> </h2></p>
			<p><h3>I. Exchange of Violin Technique with Karthick Iyer Textbooks (Applicable in case of Hard Copy Books only):</h3></p>
			<p>
    Hardcopy book/s, purchased from our website&nbsp;&nbsp;<a href="/">www.soulswara.com</a> &nbsp;may be exchanged under the following
    circumstances:
				<ul>
					<li>Unintentional / inadvertent purchase of wrong discipline and/or level textbook/s.</li>
					<li>Dispatch of wrong book/s by Violin Technique with Karthick Iyer.</li>
					<li>Book/s received in damaged condition. Photos must be sent by email to&nbsp;&nbsp;<a
						href="mailto:violintechnique.ki@gmail.com">violintechnique.ki@gmail.com</a>, immediately upon receipt.</li>
				</ul>
			</p>
			<p>
    Following conditions apply for Exchange of hardcopy textbooks:
				<ul>
					<li>Such cases must be notified to Violin Technique with Karthick Iyer immediately after receipt of book/s by
        sending an email to:&nbsp;&nbsp;
					<a href="mailto:violintechnique.ki@gmail.com">violintechnique.ki@gmail.com</a>&nbsp;
					</li>
					<li>Hardcopy book/s intended to be exchanged must be returned such that it is delivered to Violin Technique with
        Karthick Iyer within 10 days from the date of
        delivery at your end.</li>
					<li>Book/s so returned must be properly packed and received by Violin Technique with Karthick Iyer in the same
        condition it was sent. If received in damaged condition,
        it shall not be eligible for exchange.</li>
					<li>Courier/postage charges are non-refundable and no refund would be payable by Violin Technique with Karthick
        Iyer for the return of book/s by the customer.</li>
					<li>The hardcopy book/s returned may be exchanged for hardcopy book/s or eBook/s of the same/lesser value as the
        book/s purchased originally. If the book/s required
        in exchange is/are of a higher value, the differential must be paid for by the customer.</li>
				</ul>
We presume that the customer has accepted the above terms and conditions pertaining to Exchange and/or Return
policies of hardcopy books before placing an order for
the hardcopy textbook/s on our website.
			</p>

			<h3>II. Exchange / Return of Digital content (including but not limited to self-paced courses, eBooks):</h3>
			<p>We assume that the customer has read and accepted the below mentioned terms and conditions before buying /
    subscribing to any digital content on our website.</p>

			<h3>A. Exchange of ebook/s:</h3>
			<p>
    Exchange of E-book/s purchased from our website&nbsp;&nbsp;<a href="/">www.soulswara.com</a> &nbsp;shall be allowed at the sole discretion of
    Violin Technique with Karthick Iyer on a case-to-case basis.
    Exchange of ebook/s purchased may be allowed for unintentional / inadvertent purchase of wrong discipline /
    level.
				<p>Following conditions shall apply for Violin Technique with Karthick Iyer to consider giving permission to exchange:
				</p>

				<ul>
					<li>Such cases must be brought to our notice within 24 hours from the time of placing the order by sending a
        mail to:&nbsp;&nbsp;
					<a href="mailto:violintechnique.ki@gmail.com">violintechnique.ki@gmail.com</a>&nbsp;
					</li>
					<li>eBook/s cannot be exchanged for hardcopy book/s.</li>
					<li>The eBook/s must not have been accessed for use by the customer.</li>
					<li>The ebook/s returned may be exchanged for eBook/s of the same/lesser value as the ebook/s purchased
        originally. If the ebook/s required in exchange is/are of a
        higher value, the differential must be paid for by the customer.</li>
				</ul>
If and only if Violin Technique with Karthick Iyer is convinced that all the above terms and conditions have been
fulfilled/abided by the customer, Violin Technique
with Karthick Iyer may at its sole discretion approve for exchange of eBook/s whereby access to the ebook/s ordered
shall be withdrawn from the user profile and access
shall be given to the required eBook/s in exchange to the same user profile.
			</p>

			<h3>B. Return of ebook/s:</h3>
			<p>
    Return of E-book/s purchased from our website &nbsp;&nbsp;<a href="/">www.soulswara.com</a> &nbsp;shall be allowed at the sole discretion of
    Violin Technique with Karthick Iyer on a case-to-case basis.
				<p> Following conditions shall apply for Violin Technique with Karthick Iyer to consider giving permission to return
    in exchange for other digital products or
    live courses / short courses / summer courses or a redeemable coupon:</p>
			</p>
			<p>
				<ul>
					<li>Such cases must be brought to our notice within 24 hours from the time of placing the order by sending a
        mail to:&nbsp;&nbsp;<a href="mailto:violintechnique.ki@gmail.com">violintechnique.ki@gmail.com</a>&nbsp;</li>
					<li>eBook/s cannot be exchanged for hardcopy book/s.</li>
					<li>The eBook/s must not have been accessed for use by the customer.</li>
				</ul>
If and only if Violin Technique with Karthick Iyer is convinced that all the above terms and conditions have been
fulfilled/abided by the customer, Violin Technique with Karthick
Iyer may, at its sole discretion, approve for return of eBook/s whereby access to the eBook/s ordered shall be
withdrawn from the user profile and the value of the eBook so approved
for return shall be adjusted against any other digital product or online/offline live courses / short courses /
summer courses. The customer is liable to pay for the said digital product
or online/offline live courses / short courses / summer courses the differential price / fee in excess of the value
of the eBook/s returned.
				
			</p>
			<p>In the event, the customer does not want to purchase any digital product or subscribe to any of the live
    course(s) / short course(s) / summer course(s) at that point in time,
    Violin Technique with Karthick Iyer may, at its sole discretion issue a redeemable coupon for the value of the
    eBook/s returned with a suitable validity period and other terms
    and conditions as may be applicable.
			</p>
			<h3>C. Exchange / Return of digital content other than eBooks including but not limited to self-paced courses:</h3>

			<p>
    Digital contents including but not limited to self-paced courses, Master classes etc., purchased from our
    website &nbsp;<a href="/">www.soulswara.com</a> &nbsp;shall NOT be eligible for return or refund thereof.</p>
			<p><h6>Exception:</h6></p>
			<p>Violin Technique with Karthick Iyer may, at its sole discretion, make an exception to the above no return /
    refund policy pertaining to Exchange / Return of digital content
    other than eBooks including but not limited to self-paced courses, provided, the following conditions are
    fulfilled:</p>
			<p>Such cases must be brought to our notice within 24 hours from the time of placing the order by sending a mail to:&nbsp;&nbsp;
				<a href="mailto:violintechnique.ki@gmail.com"> violintechnique.ki@gmail.com</a>&nbsp;</p>
			<p>The digital content should not have been opened or accessed or downloaded.</p>
			<p>In the event, the customer does not want to purchase any digital product or subscribe to any of the live courses
    / short courses / summer courses at that point in time, Violin Technique with Karthick Iyer may, at its sole
    discretion issue a redeemable coupon for the value of the eBook/s returned with a
    suitable validity period and other terms and conditions as may be applicable.</p>
			<p>We presume that the customer has accepted the above terms and conditions pertaining to Exchange and/or Return policies of digital content
					before placing an order on our website.</p>

			<h3>III. Refund of Course fees (including but not limited to online/offline Live courses / Short courses / Summer
    courses etc.)</h3>
			<p>
				<p>Course fee once paid shall NOT be refunded under any circumstances...</p>
				<p>Any fee(s) paid towards offline / online live courses or short courses or summer courses, will not be refunded
    once the student is admitted in the course.</p>
				<p> Any fee(s) paid shall be non-transferable in nature and any other person shall not be permitted to avail the
    classes on behalf of or in the place of the student.</p>
				<p>If the student wishes to discontinue a course, for any reason the student must immediately inform the student
    coordinator over phone call / WhatsApp message / send a mail
    to&nbsp;&nbsp; <a href="mailto:violintechnique.ki@gmail.com">violintechnique.ki@gmail.com</a>. &nbsp;The student is expected to ensure completion of all classes that are pending as
    on the date on which discontinuance is communicated to
    the student coordinator, within a period of 3 months from that date, failing which any / all pending classes
    shall lapse. The term pending classes here mean, classes for
    which the student has already paid fees but not availed during the quarter or course period.</p>
				<p>If the student wishes to take a break from a course** for a specified or unspecified period of time, the student
    must immediately inform the student coordinator over phone
    call / WhatsApp message / send an email to&nbsp;&nbsp;<a href="mailto:violintechnique.ki@gmail.com">violintechnique.ki@gmail.com</a>. &nbsp;Violin Technique with Karthick Iyer
    shall make a note of the amount of fees towards the number of classes
    pending as on the date on which the student starts to take a break. This amount can be adjusted towards the fees
    due at the time of rejoining, provided, the student rejoins within
    a period of 6 months from the date on which the break period was started.</p>
				<p>Note 1: The term course** for this purpose shall include only Live courses and not include Short courses / Summer
    Courses / other similar courses that have a limited duration.</p>
				<p>Note 2: The amount of fee collected towards the classes that are pending as on the date of Break, shall be noted
    down for adjustment only in such cases where the student has
    communicated to Violin Technique with Karthick Iyer, his/her intent to take a Break. Uninformed absence and/or
    discontinuance without any communication through one or more modes
    mentioned above, does not imply communication of Break or Pause in classes and hence the fee towards pending
    classes in such cases shall not be eligible for adjustment at the
    time of rejoining.</p>
				<p>Note 3: When the student wishes to rejoin, Violin Technique with Karthick Iyer shall calculate the fee due as per
    the rules applicable for calculating fees and at the
    rate prevailing at the time of rejoining. The fee so calculated may be adjusted to the extent of the fee amount
    pending with Violin Technique with Karthick Iyer at the time
    of taking a Break, provided it is eligible for adjustment in accordance with the terms and conditions listed
    above.
    The student must pay the fees calculated before starting the classes upon rejoining.</p>
				<p>Note 4: When a student opts for a break, the time slot under the teacher will be released. Violin Technique with
    Karthick Iyer does not guarantee the same
    time slot and / or teacher at the time of rejoining.</p>


				<p>The amount of fees adjusted at the time of rejoining shall be reversed and shall no longer be eligible for
    adjustment in case the student takes a Break from
    classes within 3 months of rejoining.</p>
				<p>It is the sole responsibility of the student to inform the student coordinator over phone call / WhatsApp message
    / send an email to violintechnique.ki@gmail.com at the
    time they wish to rejoin the classes.</p>
				<p>We presume that the customer/student has read and accepted all the above mentioned terms and conditions before
    enrolling/subscribing to any of our courses.</p>
				<p><strong> IMPORTANT NOTE:</strong> Violin Technique with Karthick Iyer reserves the right to modify the refund policy at any time
    without prior notice. If you have any questions or concerns
    about our refund policy, please write to&nbsp;&nbsp;<a href="mailto:violintechnique.ki@gmail.com">violintechnique.ki@gmail.com</a>.&nbsp;</p>
			</p>


			<h3>IV. Shipping and Delivery Policy</h3>
			<p>This Shipping & Delivery Policy is part of our Terms and Conditions (&quot;Terms&quot;) and should therefore be read
    alongside our main Terms & Conditions. Please review our
    Shipping & Delivery Policy carefully before placing an order with us.This policy will apply to all orders placed
    on our website and/or in-store purchases at our own and/or
    partner centres.</p>


			<h3>Shipping and Delivery options</h3>
			<p>In-store pickup is available for books during the operating hours of the respective centre.</p>
			<p>We offer various shipping options through third-party vendors (delivery partners) who may be managing our
    inventory once picked-up for delivery and will be responsible for
    delivery of the products. Books will be handed over to delivery partners within 2-4 business days from the date
    of receiving the order.</p>

			<h3>Shipping Charges</h3>
			<p>Shipping charges may be collected additionally if the product price is excluding delivery charges. In the event
    the price of the product ordered is inclusive of delivery charges,
    additional charges will not be applicable.</p>

			<h3>International Delivery</h3>
			<p>We do not offer international shipping.</p>

			<h3>Questions about returns?</h3>
			<p>If you have questions about returns, please review our Return, Refund & Exchange Policy.</p>

			<h3>How can you contact us about this policy?</h3>
			<p>If you have any further questions or comments, please write to us at:&nbsp;&nbsp;<a href="mailto:violintechnique.ki@gmail.com">violintechnique.ki@gmail.com</a>&nbsp;
			</p>
      
		</div>
	);

};

export default RefundPolicy;
