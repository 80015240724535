// third-party
import { combineReducers } from "redux";

// project imports
import menuReducer from "./slices/menu";
import snackbarReducer from "./slices/snackbar";
import userReducer from "./slices/userSlice";

// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
	snackbar: snackbarReducer,
	menu    : menuReducer,
	user    : userReducer
});

export default reducer;
