import React, { useEffect, useState } from "react";
import { useSelector } from "../../store";
import config from "../../config/appConfig";

const initialCommentsData: any = [
	{
		_id        : "1",
		lessonId   : "1",
		userId     : "1",
		commentText: "Hello",
		replies    : [
			{
				_id      : "1",
				adminId  : "1",
				replyText: "Hello",
				createdAt: new Date()
			}
		],
		createdAt: new Date(),
		updatedAt: new Date()
	}
];

const UserComments = ({ lessonId }: any) => {

	const [ newComment, setNewComment ] = useState("");
	const [ newReply, setNewReply ] = useState("");
	const [ showReplyBox, setShowReplyBox ] = useState(null);
	const { userType } = useSelector((state: any) => state.user);

	const [ userCommentsData, setUserCommentsData ] = useState<any>([]);

	useEffect(() => {

		// Load initial comments (mocked data here)
		setUserCommentsData(initialCommentsData);
	
	}, []);

	const handleCommentSubmit = (e: any) => {

		e.preventDefault();

		const newCommentData = {
			_id        : "2",
			lessonId,
			userId     : "1",
			commentText: newComment,
			replies    : [],
			createdAt  : new Date(),
			updatedAt  : new Date()
		};

		setUserCommentsData((prev: any ) => {

			return [ ...prev, newCommentData ];

		});
		setNewComment("");
	
	};

	const handleReplySubmit = (e: any) => {

		e.preventDefault();

		const newReplyData = {
			adminId  : "1",
			replyText: newReply,
			createdAt: new Date()
		};

		const updatedComments = userCommentsData.map((comment: any) => {

			if (comment["_id"] === showReplyBox) {

				return {
					...comment,
					replies  : [ ...comment.replies, newReplyData ],
					updatedAt: new Date()
				};
			
			}
			
			return comment;
		
		});

		setUserCommentsData(updatedComments);
		setNewReply("");
		setShowReplyBox(null);
	
	};

	return (
		<div className="comment-section">
			{userCommentsData.map((comment: any) =>
				<div key={comment._id} className="comment-thread">
					<div className="user-bubble chat-bubble">
						<p>{comment.commentText}</p>
						<span className="comment-date">{new Date(comment.createdAt).toLocaleString()}</span>
						{userType === config.userTypes.systemAdmin &&
              <button className="reply-icon" onClick={() => setShowReplyBox(comment._id)}>↩</button>
						}
					</div>
					{comment?.replies?.map((reply: any) =>
						<div key={reply._id} className="admin-bubble chat-bubble">
							<p>{reply.replyText}</p>
							<span className="comment-date">{new Date(reply.createdAt).toLocaleString()}</span>
						</div>
					)}
					{userType === config.userTypes.systemAdmin && comment._id === showReplyBox &&
            <form onSubmit={handleReplySubmit} className="reply-form">
            	<input
            		type="text"
            		value={newReply}
            		onChange={e => setNewReply(e.target.value)}
            		placeholder="Type your reply..."
            		required
            	/>
            	<button type="submit">Submit</button>
            </form>
					}
				</div>
			)}
			{userType !== config.userTypes.systemAdmin &&
        <form onSubmit={handleCommentSubmit} className="comment-form">
        	<input
        		type="text"
        		value={newComment}
        		onChange={e => setNewComment(e.target.value)}
        		placeholder="Type your comment..."
        		required
        	/>
        	<button type="submit">Submit</button>
        </form>
			}
		</div>
	);

};

export default UserComments;
