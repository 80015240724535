import React, { useEffect, useState } from "react";

// mui. rsuite, markdown components import
import Container from "@mui/material/Container";
import MainButton from "../../components/UI/extend/MainButton";
import { useTheme, Button, TextField, useMediaQuery } from "@mui/material";
import Typography from "@mui/material/Typography";
import { Row, Col, Progress } from "rsuite";
// import TurndownService from "turndown";

// custom component
import Back from "../../../src/components/UI/Back";
import DropZone from "../../components/UI/DropZone";
import Editor from "../../components/UI/Editor";
import { APIUrl } from "../../api/endPoints";

// axios, router import
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";

// config import
import config from "../../config/appConfig";

// api request import
import ApiRequest from "../../api/ApiRequest";


// const showdown = require('showdown');

// import showdown from "showdown";

const AddPost = () => {

	const navigate = useNavigate();
	const locationState = useLocation()?.state;
	const statePostData = JSON.parse(locationState?.statePostData || "{}");
	const isEditPost = statePostData?.id ? true : false;

	const [ postTitle, setPostTitle ] = useState("");
	const [ isUploadedMediaValid, setIsUploadedMediaValid ] = useState(true);
	const [ uploadPostMediaName, setUploadPostMediaName ] = useState("");
	const [ fileDetails, setFileDetails ] = useState<any>([]);
	const [ postContent, setPostContent ] = useState("");
	const [ isFileUploading, setIsFileUploading ] = useState(false);
	const [ postId, setPostId ] = useState("");
	const [ uploadProgress, setUploadProgress ] = useState(0);
	const [ isUploaded, setIsUploaded ] = useState(false);
	const [ imageContent, setImageContent ] = useState("");
	const theme: any = useTheme();
	const matchesXS = useMediaQuery(theme?.breakpoints.down("md"));

	useEffect(() => {
		
		if (statePostData && statePostData?.postImage && isEditPost) {
			
			setPostId(statePostData?.id);
			setPostContent(statePostData?.content);
			setPostTitle(statePostData?.title);
			setUploadPostMediaName(statePostData?.content && statePostData?.title && statePostData?.id ? `Video Id: ${statePostData?.id}` : "");
			setImageContent(statePostData && statePostData?.postImage ? statePostData?.postImage : "");
		
		}

	}, [ ]);

	const postMediaDrop = (acceptedFiles: any) => {

		if (config?.acceptedImageVideoFormats?.includes(acceptedFiles[0].type)) {

	 		setIsUploaded(false);
			setUploadPostMediaName(acceptedFiles[0].name);
			setIsUploadedMediaValid(true);
			setFileDetails(acceptedFiles);
			setImageContent("");

		} else {

	
			console.error("File type not supported");
			setIsUploadedMediaValid(false);
			setUploadPostMediaName("File type not supported");
	 
		
		}
	
	};

	const uploadImage = () => {

		if (fileDetails) {
			
			const file = fileDetails[0];
			let payload: any = {};

			if (statePostData?.id) {
				
				payload = {
					"title"   : postTitle,
					"content" : postContent,
					"fileType": file.type,
					"fileName": file.name,
					"id"      : statePostData?.id
				};

			} else {

				payload = {
					"title"   : postTitle,
					"content" : postContent,
					"fileType": file.type,
					"fileName": file.name
				};

			}

			setIsFileUploading(true);

			ApiRequest("post", APIUrl.getSignedUrlForUploadPostImage, payload).then(async (res: any) => {

				try {

					if (res?.link) {

						const signedUrl = res?.link;
						setPostId(res?.id);

						const onUploadProgress = (progressEvent: any) => {
	
							const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
							setUploadProgress(percentCompleted);
		
						};

						await axios.put(signedUrl, file, { headers: { "Content-Type": file.type }, onUploadProgress: onUploadProgress });
			
						setIsFileUploading(false);
						setIsUploaded(true);

						setImageContent(fileDetails[0]);
					
					}

				} catch (error) {

					console.error(error);
					setIsFileUploading(false);
					setIsUploaded(false);

				}

			}).catch(error => {

				console.error(error);
				setIsFileUploading(false);
				setIsUploaded(false);
			
			});
		
		}

	};

	const handlePostUpdate = () => {

		const payload: any = {
			"title"  : postTitle,
			"content": postContent
		};

		if (postId) {

			payload["id"] = postId;

		}

		// setIsAddOrDeletePost(true);

		ApiRequest("post", APIUrl.addPost, payload).then(() => {

			// setIsAddOrDeletePost(false);

			navigate(config.routes.announcement);
		
		}).catch(error => {

			// setIsAddOrDeletePost(false);

			console.error(error);
		
		});

		// setUploadPostMediaName("");

		setIsUploadedMediaValid(false);

		setFileDetails([]);
		setUploadPostMediaName("");
		setIsUploadedMediaValid(false);
		setIsFileUploading(false);
		setIsUploaded(false);
	
	};

	return (
		<Container>
			<Row className="sh-announcement-page-back-btn-container-con" >
				<div className="admin-dash-board-back-title-con" style={{ marginBottom: theme.spacing(2) }}
				>
					<Back
						props={{
							route  : config.routes.announcement,
							state  : null,
							toolTip: "Back to Community",
							text   : "Back to Community"
						}}
					/>
				</div>
			</Row>
			<Row>
				<Col className="sh-announcement-page-right-con-container">
					<div>
						<Row className="sh-announcement-page-seperate-row-con">
							<Col className="sh-announcement-page-left-label-side-left">
								<Typography className="sh-announcement-page-post-title-content-label" variant="h5" gutterBottom>
										Post Title <span style={{ color: theme?.palette?.secondary?.main }}>*</span>
								</Typography>
							</Col>
							<Col className="sh-announcement-page-left-label-side">
								<TextField
									value={postTitle}
									fullWidth
									id="outlined-basic"
									placeholder="Post Title"
									variant="outlined"
									onChange={(e: any) => setPostTitle(e?.target?.value)}
								/>
							</Col>
						</Row>
						<Row className="sh-announcement-page-seperate-row-con-editor-con">
							{/* <Col className="sh-announcement-page-left-label-side-left">
								<Typography variant="h5" gutterBottom>Content <span style={{ color: theme?.palette?.secondary?.main }}>*</span></Typography>
							</Col>
							<Col className="sh-announcement-page-left-label-side"> */}
							<Editor
								postContent={postContent}
								setPostContent={setPostContent}
							/>
							{/* </Col> */}
						</Row>
						{/* <Row>
							<Col className="editor-containter-width">
							</Col>
							<Col className="editor-containter-width">
								
							</Col>
						</Row> */}
						<Row className="sh-announcement-page-seperate-row-con">
							<Col className="sh-announcement-page-left-label-side">
			
							</Col>
							<Col className="sh-announcement-page-left-label-side">
								
							</Col>
						</Row>
					</div>
				</Col>
				<Col className="sh-announcement-page-right-side-con-container">
					<Row className="sh-announcement-page-seperate-row-con">
						<Col className="sh-announcement-page-dropzone-con">
							<Typography className="sh-announce-ment-page-upload-image-label" variant="h5" gutterBottom>
										Upload Image <span style={{ color: theme?.palette?.secondary?.main }}>*</span>
							</Typography>
							<Row>
								<DropZone
									dropMedia={postMediaDrop}
									isUploadedMediaValid={isUploadedMediaValid}
									uploadPostMediaName={uploadPostMediaName}
									acceptFileType={config?.acceptedImageFormats}
									accept={config?.acceptedFilesImages}
									isMultiple={false}
									minSize={1024}
									maxSize={52428800}
									// disabled={ postTitle == "" ? true : false || postContent == "" ? true : false }
									disabled={ postTitle == "" || postContent == "" ? true : false }
									disabledMessage="Please fill all the fields"
									message={ fileDetails && fileDetails.length > 0 ? "Please click on Upload button to upload" : "Please Click or drag and drop to upload" }
									minHeight="17em"
									maxHeight="17em"
								/>
								<div className="sh-announcemet-page-image-upload-cancel-btn-con">
									{ fileDetails && fileDetails.length > 0 && <>
										<Button
											variant="contained"
											color="primary"
											// disabled={ !isUploadedMediaValid || !postTitle || !postContent || !fileDetails || isFileUploading || isUploaded }
											disabled={ !uploadPostMediaName || !isUploadedMediaValid || !fileDetails || isFileUploading || isUploaded }
											onClick={() => uploadImage()}
										>Upload</Button> &nbsp;
										<Button
											variant="outlined"
											color="primary"
											onClick={() => {

												setFileDetails([]);
												setUploadPostMediaName("");
												setIsUploadedMediaValid(false);
												setIsFileUploading(false);
												setIsUploaded(false);
												setImageContent("");
												// setImageContent(fileDetails[0]);
									
											}}
										>Remove</Button>
									</>
								
									}
								</div>
							</Row>
							<Row>
								<div className="add-post-community-page-image-upload-content-con">{
									fileDetails && isFileUploading &&
										<Progress.Line percent={uploadProgress} status={
											uploadProgress === 100 ? "success" : "active"
										}
										showInfo={true} strokeWidth={8} strokeColor={theme.palette.primary.main}/>
								}</div>
							</Row>
							<div className="add-post-community-page-add-post-cancel-btn-con">
								<div>
									<MainButton
										type="scale"
										direction="bottom"
										style={{ position: "relative", marginRight: "10px" }}
										offset={matchesXS ? 0 : 20}
										scale={1.04}
									>
										<Button
											className="sh-module-intro-enroll-now-btn"
											variant="outlined"
											color="secondary"
											onClick={() => navigate(config.routes.announcement)}
										>Cancel</Button>
									</MainButton>
								</div>
								<div>

									<MainButton
										type="scale"
										direction="bottom"
										style={{ position: "relative" }}
										offset={matchesXS ? 0 : 20}
										scale={1.04}
									>
										<Button
											className="sh-module-intro-enroll-now-btn"
											variant="contained"
											color="secondary"
											onClick={handlePostUpdate}
											disabled={ !postTitle || !postContent || imageContent == "" }
										>
											{isEditPost ? "Update" : "Add"} Post
										</Button>
									</MainButton>
								</div>
							</div>
						</Col>
					</Row>
				</Col>
			</Row>
		</ Container>
	);

};

export default AddPost;
