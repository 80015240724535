import React, { useState, useEffect, useCallback, useRef, useContext } from "react";

// pre build components
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { MdAddComment } from "react-icons/md";
import { useTheme, useMediaQuery, Button, TextField, IconButton } from "@mui/material";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import MessageIcon from "@mui/icons-material/Message";
import { Row, Col, Progress } from "rsuite";
import axios from "axios";
import { RiDeleteBin5Line } from "react-icons/ri";
import _ from "lodash";
// import Markdown from "react-markdown";

// import remarkGfm from "remark-gfm";
import { useNavigate } from "react-router-dom";

// config import
import config from "../../config/appConfig";

// custom components imports
import CustomLoader from "../../components/UI/cards/CustomLoader";
import ModalComponent from "../../components/modal";

// store imports
import { useSelector } from "../../store";

// api imports
import ApiRequest from "../../api/ApiRequest";
import { APIUrl } from "../../api/endPoints";

// sample image imports
import userIcon from "../../assets/icons/user-round.svg";
import postImage from "../../assets/images/violinLanding.jpg";
import MainButton from "../../components/UI/extend/MainButton";
import communityBanner from "../../assets/images/communityBanner.jpg";
import LikeBlue from "../../assets/images/likeBlue.svg";
// import { FaRegHeart } from "react-icons/fa";
import { FiEdit2 } from "react-icons/fi";
import UserContext from "../../context/AuthContext";
// import { FcLike } from "react-icons/fc";

const Announcement = () => {

	const theme: any = useTheme();
	const matchesXS = useMediaQuery(theme.breakpoints.down("md"));
	const { userType } = useSelector((state: any) => state.user);
	const { userDetails, setUserDetails, setIsAnnouncementFetch } = useContext(UserContext);

	const [ isAddPostModelOpen, setIsAddPostModelOpen ] = useState(false);
	// const [ uploadPostMediaName, setUploadPostMediaName ] = useState("");
	const [ isUploadedMediaValid, setIsUploadedMediaValid ] = useState(true);
	const [ postTitle, setPostTitle ] = useState("");
	const [ postContent, setPostContent ] = useState("");
	const [ postData, setPostData ] = useState<any>([]);
	const [ commentSectionsOpen, setCommentSectionsOpen ] = useState<any>({});
	const [ comments, setComments ] = useState<any>({});
	const [ likes, setLikes ] = useState<any>({});
	const [ likedPosts, setLikedPosts ] = useState<any>({});
	const [ fileDetails, setFileDetails ] = useState<any>([]);
	const [ postId, setPostId ] = useState("");
	const [ uploadProgress, setUploadProgress ] = useState(0);
	const [ isFileUploading, setIsFileUploading ] = useState(false);
	const [ isUploaded, setIsUploaded ] = useState(false);
	const [ page, setPage ] = useState(1);
	const [ isLoading, setIsLoading ] = useState(false);
	const [ hasMore, setHasMore ] = useState(true);
	const [ isAddOrDeletePost, setIsAddOrDeletePost ] = useState(false);
	const contentDiv: any = useRef(null);
	const limit = 50;
	const navigate = useNavigate();
	let isGetPostCallPending: boolean = false;

	let isFirstTime = true;

	useEffect(() => {

		if (isFirstTime) {

			fetchAllPosts(page, limit);
			isFirstTime = false;
		
		}

	
	}, []);

	const fetchAllPosts = async (page: any, limit: any, isFromAdmin = false) => {

		if (!hasMore) return;
		setIsLoading(true);

		fetchAllPostsForAdmin(page, limit, isFromAdmin);

	};

	const fetchAllPostsForAdmin = async (page: any, limit: any, isFromAdmin = false) => {

		if (isGetPostCallPending) return;

		const payload = {
			page,
			limit
		};

		isGetPostCallPending = true;

		ApiRequest("post", APIUrl.listAllPosts, payload).then((res: any) => {

			setIsLoading(false);

			isGetPostCallPending = false;

			const latestPostUpdate = new Date(res?.data?.posts[0]?.updateAt);
			const userLastViewed = new Date(userDetails?.lastViewedPostTime);
			const isNewPostAvailable = userLastViewed < latestPostUpdate;
			const viewTimeInMongoDB = new Date().toISOString();

			if(userDetails?.lastViewedPost !== res?.data?.posts[0]?.id || isNewPostAvailable) {
				
				const payload = {
					"postId"  : res?.data?.posts[0]?.id,
					"viewTime": viewTimeInMongoDB
				};
				
				ApiRequest("post", APIUrl.updateLastViewdPost, payload).then(() => {
					
					setUserDetails({ ...userDetails, lastViewedPost: res?.data?.posts[0]?.id, lastViewedPostTime: viewTimeInMongoDB });
					setIsAnnouncementFetch(true);
					isGetPostCallPending = false;

				}).catch(error => {
					
					isGetPostCallPending = false;
					console.error(error);
					
				});

			}
			
			isGetPostCallPending = false;

			if (isFromAdmin) {

				setPostData(res.data.posts);

				return;

			}

			if (res?.data?.posts?.length) {

				const oldData = postData;
				const newData = _.uniqBy([ ...oldData, ...res.data.posts ], "id");
		
				setPostData(newData);
	
				if (res.data.posts.length < limit) {
	
					setHasMore(false);

				}

			
			} else {

				setHasMore(false);
			
			}

		}).catch(error => {

			console.error(error);
			setIsLoading(false);
			
		});

	};

	useEffect(() => {

		const handleScrollEvent = () => {

			if ((window.innerHeight + window.scrollY) >= document.documentElement.scrollHeight) {

				handleScroll();
			
			}
		
		};

		window.addEventListener("scroll", handleScrollEvent);

		return () => {

			window.removeEventListener("scroll", handleScrollEvent);

		};

	}, [ ]);

	const handleScroll = useCallback(() => {

		try {
			
			const { scrollTop, scrollHeight, clientHeight } = contentDiv.current;
	
			if (scrollTop + clientHeight >= scrollHeight - limit && !isLoading && hasMore) {
	
				setPage((prevPage: any) => prevPage + 1);
				fetchAllPosts(page, limit);
	
			}

		} catch (error) {
			
			console.error(error);
			
		}

	
	}, [
		isLoading, hasMore, page
	]);

	// const postMediaDrop = (acceptedFiles: any) => {

	// 	if (config?.acceptedImageVideoFormats?.includes(acceptedFiles[0].type)) {

	/*
	 * 		setIsUploaded(false);
	 * 		setUploadPostMediaName(acceptedFiles[0].name);
	 * 		setIsUploadedMediaValid(true);
	 * 		setFileDetails(acceptedFiles);
	 */
		
	// 	} else {

	/*
	 * 		console.error("File type not supported");
	 * 		setIsUploadedMediaValid(false);
	 * 		setUploadPostMediaName("File type not supported");
	 */
		
	// 	}
	
	// };

	const uploadImage = () => {

		if (fileDetails) {
			
			const file = fileDetails[0];

			const payload = {
				"title"   : postTitle,
				"content" : postContent,
				"fileType": file.type,
				"fileName": file.name
			};

			setIsFileUploading(true);

			ApiRequest("post", APIUrl.getSignedUrlForUploadPostImage, payload).then(async (res: any) => {

				try {

					if (res?.link) {

						const signedUrl = res?.link;
						setPostId(res?.id);

						const onUploadProgress = (progressEvent: any) => {
	
							const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
							setUploadProgress(percentCompleted);
		
						};

						await axios.put(signedUrl, file, { headers: { "Content-Type": file.type }, onUploadProgress: onUploadProgress });
			
						setIsFileUploading(false);
						setIsUploaded(true);
					
					}

				} catch (error) {

					console.error(error);
					setIsFileUploading(false);
					setIsUploaded(false);

				}

			}).catch(error => {

				console.error(error);
				setIsFileUploading(false);
				setIsUploaded(false);
			
			});
		
		}

	};

	const handlePostUpdate = () => {

		const payload: any = {
			"title"  : postTitle,
			"content": postContent
		};

		if (postId) {

			payload["id"] = postId;

		}

		setIsAddOrDeletePost(true);

		ApiRequest("post", APIUrl.addPost, payload).then(() => {

			onModelClose();
			setIsAddOrDeletePost(false);
		
		}).catch(error => {

			setIsAddOrDeletePost(false);

			fetchAllPosts(1, limit);
			console.error(error);
		
		});

		// setUploadPostMediaName("");

		setIsUploadedMediaValid(false);

		setFileDetails([]);
		// setUploadPostMediaName("");
		setIsUploadedMediaValid(false);
		setIsFileUploading(false);
		setIsUploaded(false);
	
	};

	const onModelClose = () => {

		fetchAllPostsForAdmin(1, limit, true);
		setIsAddPostModelOpen(false);
		// setUploadPostMediaName("");
		setIsUploadedMediaValid(false);
		setPostTitle("");
		setPostContent("");
		setFileDetails([]);
		setIsFileUploading(false);
		setIsUploaded(false);

	
	};

	const toggleCommentSection = (postId: string) => {

		setCommentSectionsOpen((prevSections: any) => ({
			...prevSections,
			[postId]: !prevSections[postId]
		}));

		if (!commentSectionsOpen[postId]) {

			fetchComments(postId);
		
		}
	
	};

	// Handle Like and Unlike Logic
	const handleLike = (postId: string) => {

		setLikedPosts((prevLikedPosts: any) => {

			const hasLiked = prevLikedPosts[postId];

			// Toggle like status
			const updatedLikes = hasLiked
				? Math.max((likes[postId] || 0) - 1, 0)
				: (likes[postId] || 0) + 1;

			setLikes((prevLikes: any) => ({
				...prevLikes,
				[postId]: updatedLikes
			}));

			if (!hasLiked) {

				handleLikeAndDislike(postId, true);
			
			} else {

				handleLikeAndDislike(postId, false);
			
			}

			return {
				...prevLikedPosts,
				[postId]: !hasLiked
			};

		
		});
	
	};

	const handleLikeAndDislike = async (postId: string, like: boolean) => {

		try {

			await ApiRequest("post", APIUrl.likePost, { "id": postId, "isLike": like });

		} catch (error) {

			console.error(error);

		}

	};

	const handleCommentChange = (postId: string, commentText: string) => {

		setComments((prevComments: any) => ({
			...prevComments,
			[postId]: commentText
		}));
	
	};

	const submitComment = (postId: string) => {

		const newComment = comments[postId];

		if (newComment.trim()) {

			// Add new comment to existing comments (dummy)
			setPostData((prevData: any) => {

				return prevData.map((post: any) => {

					if (post.id === postId) {

						const commentObj = {
							
							"commentedBy"   : "You",
							"comment"       : newComment,
							"userProfilePic": userIcon

						};
						

						post.comments = [ ...post.comments, commentObj ];
					
					}
					
					return post;
				
				});
			
			});

			handleAddAndRemoveComment(postId, newComment, true);

			// Clear the input after submission
			setComments((prevComments: any) => ({
				...prevComments,
				[postId]: ""
			}));
		
		}
	
	};

	const handleAddAndRemoveComment = (postId: string, comment: string, isAdd: boolean) => {

		if (isAdd) {

			const payload = {

				"postId" : postId,
				"comment": comment

			};

			ApiRequest("post", APIUrl.addCommentToPost, payload).then(() => {

				// console.info(res);

			}).catch(error => {

				console.error(error);

			});
		
		} else {
			
			const payload = {

				"commentId": postId

			};

			ApiRequest("post", APIUrl.removeCommentToPost, payload).then(() => {

				// console.info(res);

			}).catch(error => {

				console.error(error);

			});

		}


	};

	const fetchComments = (postId: string) => {

		if (postId) {

			const payload = {
				"postId": postId
			};

			ApiRequest("post", APIUrl.listCommentByPost, payload).then((res: any) => {

				if (res?.data) {

					setPostData((prevData: any) => {

						return prevData.map((post: any) => {

							if (post.id === postId) {

								post.comments = res?.data;
							
							}
							
							return post;
						
						});
					
					});
				
				}
			
			}).catch(error => {

				console.error(error);
			
			});
		
		}

	};

	const addPostModalContent = () => {

		return <>
			<Row className="sh-announcement-page-seperate-row-con">
				<Col className="sh-announcement-page-left-label-side">
					<Typography variant="h5" gutterBottom>
                Post Title
					</Typography>
				</Col>
				<Col className="sh-announcement-page-left-label-side">
					<TextField
						fullWidth
						id="outlined-basic"
						placeholder="Post Title"
						variant="outlined"
						onChange={(e: any) => setPostTitle(e?.target?.value)}
					/>
				</Col>
			</Row>
			<Row className="sh-announcement-page-seperate-row-con">
				<Col className="sh-announcement-page-left-label-side">
					<Typography variant="h5" gutterBottom>Content</Typography>
				</Col>
				<Col className="sh-announcement-page-left-label-side">
					{/* <TextField
						minRows={3}
						variant="outlined"
						fullWidth
						multiline
						placeholder="Post Content"
						onChange={(e: any) => setPostContent(e?.target?.value)}
					/>
					<Editor /> */}
				</Col>
			</Row>
			<Row>
				<div>
					{/* <DropZone
						dropMedia={postMediaDrop}
						isUploadedMediaValid={isUploadedMediaValid}
						uploadPostMediaName={uploadPostMediaName}
						acceptFileType={config?.acceptedImageFormats}
						accept={config?.acceptedFilesImages}
						isMultiple={false}
						minSize={1024}
						maxSize={52428800}
						disabled={ postTitle == "" ? true : false || postContent == "" ? true : false }
						disabledMessage="Please fill all the fields"
						message={ fileDetails && fileDetails.length > 0 ? "Please click on Upload button to upload" : "Please Click or drag and drop to upload" }
						minHeight="15em"
						maxHeight="15em"
					/> */}
					{/* <Editor /> */}
				</div>
				<div className="soul-add-post-upload-image-btn-con">
					<div>{
						fileDetails && isFileUploading &&
								<Progress.Line percent={uploadProgress} status={
									uploadProgress === 100 ? "success" : "active"
								}
								showInfo={true} strokeWidth={8} strokeColor={theme.palette.primary.main}/>
					}</div>
					<div>
						{ fileDetails && fileDetails.length > 0 && <>
							<Button
								variant="contained"
								color="primary"
								disabled={ !isUploadedMediaValid || !postTitle || !postContent || !fileDetails || isFileUploading || isUploaded }
								onClick={() => uploadImage()}
							>
								Upload
							</Button> &nbsp;
							<Button
								variant="outlined"
								color="primary"
								onClick={() => {

									setFileDetails([]);
									// setUploadPostMediaName("");
									setIsUploadedMediaValid(false);
									setIsFileUploading(false);
									setIsUploaded(false);
								
								}}
							>
									Remove
							</Button>
						</>
							
						}
					</div>
							
				</div>
			</Row>
		</>;

	};

	const addPostFooter = () => {

		return <>
			<MainButton
				type="scale"
				direction="bottom"
				style={{ position: "relative", marginRight: "10px" }}
				offset={matchesXS ? 0 : 20}
				scale={1.04}
			>
				<Button
					className="sh-module-intro-enroll-now-btn"
					variant="outlined"
					// style={{ backgroundColor: "transparent", border: `0.3px solid ${theme.palette.secondary.main} `, color: theme.palette.secondary.main }}
					color="secondary"
					onClick={onModelClose}
				>
              Cancel
				</Button>
			</MainButton>
			<MainButton
				type="scale"
				direction="bottom"
				style={{ position: "relative" }}
				offset={matchesXS ? 0 : 20}
				scale={1.04}
			>
				<Button
					className="sh-module-intro-enroll-now-btn"
					variant="contained"
					color="secondary"
					onClick={handlePostUpdate}
				>
					Add Post
				</Button>
			</MainButton>
		</>;

	};

	const handleRemovePost = (postId: string) => {

		if (postId) {

			const payload = {
				"id": postId
			};

			setIsLoading(true);
			setIsAddOrDeletePost(true);

			ApiRequest("post", APIUrl.deletePostById, payload).then(() => {

				setIsAddOrDeletePost(false);
				
				setIsLoading(false);
				fetchAllPostsForAdmin(1, limit, true);
			
			}).catch(error => {

				console.error(error);
				setIsAddOrDeletePost(false);
				setIsLoading(false);
				fetchAllPosts(page, limit);

			});
			
		}

	};

	return (
		<>
			<div>
				<div>
					<div className="sh-announcement-banner-image-text-container">
						<img className="sh-community-page-banner-image" src={communityBanner} alt="" />
						<div className="sh-community-page-banner-content">
							<Typography
								variant="h2"
								className="sh-announcement-card-post-title sh-announcement-banner-text"
								style={{ fontStyle: "italic" }}
							>
															The Violin Exchage Forum
							</Typography>
						</div>
					</div>
				</div>
			</div>
			{
				userType === config.userTypes.systemAdmin && <div style={{
					display   			 : "flex",
					justifyContent: "flex-end",
					marginBottom  : "20px"
				}}>
					<MainButton
						type="scale"
						direction="bottom"
						style={{
							position  : "relative",
							background: theme.palette.background.default
						}}
						offset={matchesXS ? 0 : 20}
						scale={1.04}
					>
						<Button
						// className="sh-module-intro-enroll-now-btn"
							variant="contained"
							color="secondary"
							onClick={() => {

								// setIsAddPostModelOpen(true);

								navigate(config.routes.addPost);
						
							}}
						>
            Add Post
						</Button>
					</MainButton>
				</div>
			}
			 {

				isAddOrDeletePost ? <CustomLoader /> : <>
					<div
						// onScroll={handleScroll}
						ref={contentDiv} className="sh-announcement-cards-container">
						{postData && postData?.length > 0 && postData?.map((data: any) => {

							return <div key={data.id} className="sh-announcement-card-con">
								{/* <Card>
									<div>
										<div className="sh-communiy-page-image-content-con-admin">
											<div>
												<div>
													<img className="sh-community-page-image" src={data?.postImage} alt="" />
												</div>
											</div>
											<CardContent>
												<Typography
													variant="h3"
													className="sh-announcement-card-post-title"
												>
													{data?.title}
												</Typography>
												<Typography
													variant="body2"
													className="sh-announcement-card-post-content"
												>
													<Markdown remarkPlugins={[ remarkGfm ]}>{data.content}</Markdown>
												</Typography>
											</CardContent>
										</div>
									</div>
								</Card> */}
								<Card >
									<div>
										<div className="sh-communiy-page-image-content-con-admin">
											<div className="sh-community-page-content-left-side-con">
												<div>
													{
														data?.postImage && <div className="sh-announcement-page-post-content-container-con"
															style={{

															}}
														>
															<img
																src={data?.postImage ? data?.postImage : postImage}
																alt="post-image"
																className="sh-announcement-post-content-image"
															/>
														</div>
													}
												</div>
											</div>
											<div className="sh-community-page-content-right-side-con">
												<div>
													<CardContent>
														{/* <Typography
															variant="h3"
															className="sh-announcement-card-post-title"
															style={{ color: theme.palette.secondary.main }}
														>
															{data?.title}
														</Typography> */}
														<div>
															<div className="sh-announcement-page-register-delete-icon-con">
																<Row>
																	<Col xs={20}>
																		{/* <Whisper placement="top"
																			speaker={data?.title?.length > 25 ? <Tooltip>{data?.title}</Tooltip> : <div></div>}> */}
																		<Typography
																			variant="h3"
																			className="sh-announcement-card-post-title"
																			style={{ color: theme.palette.secondary.main, wordBreak: "break-all" }}
																		>
																			{/* {data?.title && data?.title?.length > 25 ? data?.title?.slice(0, 25) + "..." : data?.title} */}
																			{data?.title}
																		</Typography>
																		{/* </Whisper> */}
																	</Col>
																	<Col xs={4}>
																		<div>
																			<div className="sh-announcement-page-like-delete-btn-con">
																				<div>
																					{
																						userType === config.userTypes.systemAdmin && <span>
																							<RiDeleteBin5Line
																								onClick={() => {

																									window.confirm("Are you sure you want to delete this post?") && handleRemovePost(data.id);
																
																								}}
																								style={{
																									cursor  : "pointer",
																									color   : theme.palette.error.main,
																									fontSize: "20px"
																								}}
																							/>
																						</span>
																					}
																					{
																						userType === config.userTypes.systemAdmin && <span>
																							<FiEdit2
																								color={theme.palette.secondary.main}
																								style={{ fontSize: "18px", marginLeft: "18px", cursor: "pointer" }}
																								onClick={() => {

																									const editPostData = { ...data };

																									editPostData["edit"] = true;

																									navigate(config.routes.addPost, { state: { statePostData: JSON.stringify(editPostData) } });
																						
																								}}
																							/>
																						</span>
																					}
																				</div>
																			</div>
																		</div>
																	</Col>
																</Row>
															</div>
														</div>
														<Typography
															variant="body2"
															className="sh-announcement-card-post-content"
															style={{ wordBreak: "break-all" }}
														>
															{/* <Markdown remarkPlugins={[ remarkGfm ]}>{data.content}</Markdown> */}
															<div dangerouslySetInnerHTML={{ __html: data.content }}></div>
														</Typography>
														<div
															// style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}
															style={{ display: "none" }}
															className="sh-announcement-page-like-only-btn-con"
														>
															<IconButton
																style={{ display: "none" }}
																onClick={() => toggleCommentSection(data.id)} aria-label="message">
																<MessageIcon />
															</IconButton>
															<IconButton
																// style={{ display: "none" }}
																onClick={() => handleLike(data.id)}
																color={likedPosts[data.id] ? "primary" : "default"}
																aria-label="like"
															>
																{ likedPosts[data.id] ? <img src={LikeBlue} className="sh-announcement-page-like-button-fill-icon" alt="Like Icon" /> : <FavoriteBorderIcon />}
																				&nbsp; <span
																	style={{
																		fontSize: "0.8rem",
																		color   : theme.palette.text.primary
																	}}
																>{likes[data.id] || 0}</span>

															</IconButton>
														</div>
														{commentSectionsOpen[data.id] &&
												<div style={{ marginTop: "10px" }}>
													<div className="soul-com-comment-section">
														{data?.comments && data?.comments.map((commentsData: any, index: number) => {

															return <div
																className="com-comment-text-con"
																key={index}>
																<div className="com-comment-main-content-con">
																	<div>
																		<Typography className="com-comment-by-text-con" variant="body2" style={{ color: theme.palette.text.primary }}>{commentsData?.commentedBy}</Typography>
																		<Typography
																			sx={{ backgroundColor: theme.palette.background.default }}
																			className="com-comment-text-content" variant="body2">{commentsData?.comment}
																		</Typography>
																	</div>
																	<img src={ commentsData?.commentedBy == "You" ? data?.userProfilePic : commentsData?.userProfilePic ? commentsData?.userProfilePic : userIcon } alt="user-icon" style={{ width: "40px", borderRadius: "50%" }} />
																</div>
															</div>;
														
														}
														)}
													</div>
													<div
														style={{
															display   : "flex",
															alignItems: "center",
															columnGap : "10px",
															width     : "100%"
														}}
													>
														<div
															style={{
																width: "100%"
															}}
														>
															<TextField
																fullWidth
																// label="Add a comment"
																placeholder="Write your comment"
																variant="outlined"
																value={comments[data.id] || ""}
																// onKeyDown={e => e.key === "Enter" && submitComment(data.id)}
																onChange={e => handleCommentChange(data.id, e.target.value)}
															/>

														</div>
														<div>
															<span
																onClick={() => submitComment(data.id)}
																style={{ cursor: "pointer", color: theme.palette.text.primary }}
															>
																<MdAddComment
																	size={20}
																	color={theme.palette.text.primary}
																/>
															</span>

														</div>
													</div>
												</div>
														}
														{/* <div>
															<div className="sh-announcement-page-register-delete-icon-con">
																<div>
																	<div>
																		<div style={{ backgroundColor: theme?.palette?.primary?.main }} className="sh-announcement-page-register-text-card">Register</div>
																	</div>
																</div>
																<div>
																	<div className="sh-announcement-page-like-delete-btn-con">
																		<div>
																			{
																				userType === config.userTypes.systemAdmin && <span>
																					<RiDeleteBin5Line
																						onClick={() => {

																							window.confirm("Are you sure you want to delete this post?") && handleRemovePost(data.id);
																
																						}}
																						style={{
																							cursor  : "pointer",
																							color   : theme.palette.error.main,
																							fontSize: "20px"
																						}}
																					/>
																				</span>
																			}
																
																		</div>
																		<div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
																			<IconButton
																				style={{ display: "none" }}
																				onClick={() => toggleCommentSection(data.id)} aria-label="message">
																				<MessageIcon />
																			</IconButton>
																			<IconButton
																				// style={{ display: "none" }}
																				onClick={() => handleLike(data.id)}
																				color={likedPosts[data.id] ? "primary" : "default"}
																				aria-label="like"
																			>
																				<FavoriteBorderIcon />&nbsp;<span
																					style={{
																						fontSize: "0.8rem",
																						color   : theme.palette.text.primary
																					}}
																				>{likes[data.id] || 0}</span>

																			</IconButton>
																		</div>
																	</div>
																</div>
															</div>
														</div> */}
													</CardContent>
												</div>
											</div>
										</div>
									</div>
								</Card>
						
							</div>;

						}
						)}
						{isLoading && <Typography>Loading more posts...</Typography>}
						{!hasMore && <Typography>No more posts to load.</Typography>}
					</div>
				</>
				
			 }
			
			<ModalComponent
				show={isAddPostModelOpen}
				size="lg"
				closeButton={true}
				onHide={() => onModelClose()}
				modalTitle="Add Post"
				modalContent={addPostModalContent()}
				modalFooter={addPostFooter()}
			/>
		</>
	);

};

export default Announcement;
