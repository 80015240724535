/* eslint-disable no-undef */
import React, { useEffect } from "react";
import Back from "../../components/UI/Back";
import config from "../../config/appConfig";
import { useTheme, Typography, Container, Grid , useMediaQuery } from "@mui/material";
import { Row, Col } from "rsuite";
import MainButton from "../../components/UI/extend/MainButton";
import SubCard from "../../components/UI/cards/SubCard";
import { useNavigate } from "react-router-dom";
import svgImage from "../../assets/icons/course-icon.svg";
import { useSelector } from "../../store";


const dashboard = () => {

	const theme: any = useTheme();
	const matchesXS = useMediaQuery(theme.breakpoints.down("md"));
	const navigate = useNavigate();
	const { userType } = useSelector((state: any) => state.user);

	useEffect(() => {

		sessionStorage.removeItem("selectedModule");

	}, [ ]);

	const listOfAdminModules = [
		{
			title      : "Courses",
			description: "This module will allow you to manage courses by editing the course details.",
			route      : config.routes.listCourse,
			icon       : <img src={svgImage} alt="Course" width={100} />
		},
		{
			title      : "Modules",
			description: "Create, edit and delete course modules.",
			route      : config.routes.listModules,
			icon       : <img src={svgImage} alt="module" width={100} />
		},
		{
			title      : "Levels",
			description: "Create, edit and delete module's levels.",
			route      : config.routes.listLevels,
			icon       : <img src={svgImage} alt="levels" width={100} />
		},
		{
			title      : "Lesson",
			description: "Create, edit and delete lessons.",
			route      : config.routes.listLessons,
			icon       : <img src={svgImage} alt="lesson" width={100} />
		},
		{
			title      : "Purchase History",
			description: "View and Download purchase history.",
			route      : config.routes.purchaseHistory,
			icon       : <img src={svgImage} alt="Purchase History" width={100} />
		}
	];

	if (userType !== config.userTypes.systemAdmin) {

		return <Container>
			You do not have access to this page. Please contact the admin.
		</Container>;

	} else {

		return <Container>
			<Row>
				<Col md={12} sm={12} xl={12} xs={12} >
					<div className="admin-dash-board-back-title-con" style={{ marginBottom: theme.spacing(2) }}
					>
						<Back
							props={{
								route  : config?.routes?.course,
								state  : null,
								toolTip: "Back to Course List",
								text   : "Back to Course"
							}}
						/>
					</div>
				</Col>
			</Row>
			<Row>
				<Col md={12} sm={12} xl={12} xs={12} >
					<div className="admin-dash-board-back-title-con" style={{ marginBottom: theme.spacing(2) }}
					>
						<Typography variant="h2">
								Admin Dashboard
						</Typography>
					</div>
				</Col>
			</Row>
			<Row>
				<Col style={{ width: "100%", marginTop: "2em" }} md={12} sm={12} xs={12}>
					<Typography variant="h5" style={{ color: theme?.palette?.grey[700] }}>
          				Welcome to the admin dashboard. Please select any one of the following to proceed.
					</Typography>
				</Col>
			</Row>
			<Row>
				<Col style={{ width: "100%", marginTop: "2em" }} md={12} sm={12} xs={12}>
					<Grid container spacing={2}>
						{
							listOfAdminModules.map((module, index) => {

								return <Grid item
									xs={12}
									sm={6}
									md={3}
									lg={3}
									xl={3}
									key={index}
								>
									<MainButton
										type="scale"
										direction="bottom"
										style={{ position: "relative", cursor: "pointer", color: theme?.palette?.text?.primary }}
										offset={matchesXS ? 0 : 20}
										scale={1.04}>
										<SubCard
											onClick={() => {
  
												navigate(module.route);
  
											}}
											content={true}
											contentClass="course-list-card-content"
											style={{ minHeight: "10em", maxHeight: "10em" }}
                  
										>
											<Typography variant="h3" style={{ color: theme?.palette?.secondary?.main }}>
												{module.title}
											</Typography>
											<Typography variant="body1" style={{ color: theme?.palette?.text.primary, marginTop: "1em", fontSize: "0.8rem" }}>
												{module.description}
											</Typography>
											{/* button */}
										</SubCard>
  
									</MainButton>
  
								</Grid>;

							})
						}
          
					</Grid>
				</Col>
			</Row>
    
		</Container>;
	
	}


};


export default dashboard;

