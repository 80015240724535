import React from "react";
import Dropzone from "react-dropzone";
import { useTheme, Typography } from "@mui/material";
import { FiUploadCloud } from "react-icons/fi";

const DropZone = ({
	dropMedia,
	isUploadedMediaValid,
	uploadPostMediaName,
	acceptFileType,
	accept,
	isMultiple,
	minSize,
	maxSize,
	disabled,
	disabledMessage,
	message,
	minHeight,
	maxHeight
}: any) => {

	const theme: any = useTheme();

	const fontColor = disabled ? theme.palette.grey[400] : theme?.palette?.text?.primary;

	const fileTypeMessageString = acceptFileType && acceptFileType?.length > 0 && acceptFileType.map((item: any) => {

		const withoutPrefix = item.split("/")[1];

		return `*.${withoutPrefix}`;

	}).toString();

	return (
		<div style={{ height: "100%" }}>
			<Dropzone
				onDrop={(acceptedFiles: any) => dropMedia(acceptedFiles)}
				accept={accept}
				multiple={isMultiple ? isMultiple : false}
				minSize={minSize ? minSize : 0}
				maxSize={maxSize ? maxSize : undefined}
				disabled={disabled ? disabled : false}
			>
				{({ getRootProps, getInputProps, fileRejections }: any) =>
					<div
						
						className="sh-edit-user-profile-image-upload-section">
						<div
							{...getRootProps({ className: disabled ? " sh-edit-profile-upload-profile-image-disabled" : "sh-edit-profile-upload-profile-image" })}
							style={{
								backgroundColor: theme?.palette?.background.paper,
								border         : `3px dashed ${theme?.palette?.grey[400]}`,
								minHeight      : minHeight ? minHeight : "unset",
								maxHeight      : maxHeight ? maxHeight : "unset",
								display        : "flex"
							}}>
							<input {...getInputProps()} />
							{
								!disabled && isUploadedMediaValid ?
									<Typography>
										{uploadPostMediaName}
										{
											message && message.length > 0 &&

											<Typography
												style={{ color: fontColor }}
												variant="h6"
												gutterBottom>
												{message}

											</Typography>
										}
									</Typography> :
									<div>
										<FiUploadCloud style={{ marginBottom: "0.1em", color: fontColor }} className="sh-dropsone-component-upload-icon" />
										{disabled ?
											<Typography style={{ color: fontColor, marginTop: "2px" }} variant="h6">
												{disabledMessage}
											</Typography> :
											<>
											 {
													fileRejections && fileRejections.length > 0 && !isMultiple ? <Typography style={{ color: theme.palette.error.main, marginTop: "0.5em" }} variant="h6">{fileRejections[0].errors[0].message}</Typography> : <>
														<Typography
															style={{ fontWeight: "300", marginBottom: "0.5em", color: fontColor }}
															variant="h3"
															gutterBottom> Drop files here
														</Typography>
														<Typography
															style={{ color: fontColor, marginBottom: "0.5em" }}
															variant="h6"
															gutterBottom>
															{ fileTypeMessageString ? `Supported format ${fileTypeMessageString}` : "" }
														</Typography>
														<Typography
															style={{ color: fontColor }}
															variant="h6"
															gutterBottom> OR
														</Typography>
														<Typography
															style={{ fontSize: "15px", fontWeight: "400", marginBottom: "0.5em", color: fontColor }}
															variant="h6"
															gutterBottom>Browse files
														</Typography></>
											 }
											</>
                  
										}
                    
									</div>
							}
						</div>
						
					</div>
				}
			</Dropzone>
		</div>
	);

};

export default DropZone;
