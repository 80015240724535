import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import "./assets/scss/style.scss";
import { BASE_PATH } from "./config/config";
import "./index.css";
import { store } from "./store/index";

const root = ReactDOM.createRoot(
	document.getElementById("root") as HTMLElement
);
root.render(
	<Provider store={store} >
		<React.StrictMode>
			<BrowserRouter basename={BASE_PATH}>
				<App />
			</BrowserRouter>
		</React.StrictMode>
	</Provider>
);
