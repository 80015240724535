
import React from "react";
import { FormattedMessage } from "react-intl";
import appConfig from "../../config/appConfig";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

// assets
import { IconLayoutList, IconTemplate } from "@tabler/icons";

const demos = {
	id      : "Soul Swara",
	title   : <FormattedMessage id="soul-swara" />,
	type    : "group",
	icon    : IconTemplate,
	children: [
		{
			id         : "Course",
			title      : <FormattedMessage id="course" />,
			type       : "item",
			url        : appConfig.routes.course,
			icon       : IconLayoutList,
			breadcrumbs: false
		},
		{
			id         : "Profile",
			title      : <FormattedMessage id="profile" />,
			type       : "item",
			url        : appConfig.routes.userProfile,
			icon       : AccountCircleIcon,
			breadcrumbs: false
		},
		{
			id         : "Level",
			title      : <FormattedMessage id="level" />,
			type       : "item",
			url        : appConfig.routes.userLevel,
			icon       : AccountCircleIcon,
			breadcrumbs: false
		}
	]
};

export default demos;
