/* eslint-disable react/display-name */
import React, { Suspense } from "react";
import Loader from "./Loader";

const Loading = (Component: any) => (props: any) =>
	<Suspense
		fallback={<Loader />}>
		<Component {...props} />
	</Suspense>
;

export default Loading;

