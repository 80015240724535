import _ from "lodash";
import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import "./App.css";
import Routes from "./Routes";
import NavigationScroll from "./components/Layout/NavigationScroll";
import Locales from "./components/UI/Locales";
import Snackbar from "./components/UI/extend/Snackbar";
import { AuthContextProvider as AuthProvider } from "./context/AuthContext";
import ThemeCustomization from "./themes";

function App() {

	const location: any = useLocation();

	useEffect(() => {

		if (location && !_.isEmpty(location)) {

			location.href = window.location.href;
			location.host = window.location.host;

			const lastPage = sessionStorage.getItem("LastPage");
			
			if (lastPage) {

				sessionStorage.setItem("previousPage", lastPage);

			}
			
			setTimeout(() => {
				
				sessionStorage.setItem("LastPage", JSON.stringify(location));
			
			}, 1000);
		
		}
	
	}, [ location ]);

	return (
		<ThemeCustomization>
			<Locales>
				<NavigationScroll>
					<AuthProvider>
						<>
							<Routes />
							<Snackbar />
						</>
					</AuthProvider>
				</NavigationScroll>
			</Locales>
		</ThemeCustomization>
	);

}

export default App;
