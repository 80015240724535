import React from "react";
import PropTypes from "prop-types";

// material-ui
import { Box, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import MainButton from "../../../UI/extend/MainButton";
import { Button, useMediaQuery } from "@mui/material";

// project imports
import LogoSection from "../../MinimalLayout/Logo/index";
import config from "../../../../config/appConfig";
import ProfileSection from "./ProfileSection";
// import useAuth from "../../../../Hooks/useAuth";

// toast imports
import toast, { ToastBar, Toaster } from "react-hot-toast";
import { FaTimes } from "react-icons/fa";

//  Main navbar component for header
const Header = () => {

	const theme: any = useTheme();
	const routes = config.routes;
	const navigate = useNavigate();
	// const { isLoggedIn }: any = useAuth();

	const matchesXS = useMediaQuery(theme.breakpoints.down("md"));

	return (
		<>
			{/* logo & toggler button */}
			<Box
				sx={{
					ml                            : 0,
					mr                            : 5,
					[theme.breakpoints.down("md")]: {
						mr: 2
					}
				}}
			>
				<Box component="span" >
					<LogoSection />
				</Box>

			</Box>
			<Box sx={{ display: { xs: "none", sm: "block" } }}>
				<Box >
					<Box
						sx={{
						// width                         : 228,
							display                       : "flex",
							[theme.breakpoints.down("md")]: {
								width: "auto"
							}
						}}>
						<Typography sx={{
							color   : theme?.palette?.success?.light,
							cursor  : "pointer",
							fontSize: { xs: "15px" }
						}} variant="h5" onClick={() => navigate(routes?.course)}>
            COURSES
						</Typography>
					</Box>
				</Box>
			</Box>

			<Box sx={{ display: { xs: "none", sm: "block" } }}>
				<Box>
					<Box sx={{
						ml                            : 4,
						mr                            : 3,
						[theme.breakpoints.down("md")]: {
							mr: 2
						}
					}}>
						<Typography sx={{
							color   : theme?.palette?.success?.light,
							cursor  : "pointer",
							fontSize: { xs: "15px" }
						}} variant="h5"
						onClick={() => navigate(routes.announcement)}
						>
							COMMUNITY
						</Typography>
					</Box>
				</Box>
			</Box>

			{/* mega-menu */}
			<Box sx={{ flexGrow: 1 }} />
			{/* header search */}
			{/* <SearchSection /> */}
			<Box sx={{ flexGrow: 1 }} />
			<Box sx={{ display: { xs: "none", sm: "block" } }}></Box>
			{/* live customization & localization */}

			<Box sx={{ display: { xs: "none", sm: "block" } }}></Box>
			<Box>
				<Box sx={{
					ml                            : 4,
					mr                            : 3,
					[theme.breakpoints.down("md")]: {
						mr: 2
					}
				}}>
					<Typography sx={{
						color   : theme?.palette?.success?.light,
						cursor  : "pointer",
						fontSize: { xs: "15px" }
					}} variant="h5"
					onClick={() => navigate(config.routes.login)}
					>
					SIGN IN
					</Typography>
				</Box>
			</Box>

			<MainButton
				type="scale"
				direction="bottom"
				offset={matchesXS ? 0 : 20}
			>
				<Button
					variant="contained"
					color="primary"
					size={matchesXS ? "small" : "medium"}
					onClick={() => navigate(routes?.register)}
					style={{ borderRadius: 20, width: 100, fontSize: "15px", backgroundColor: theme?.palette?.primary?.main }}
				>

						SIGN UP
				</Button>
			</MainButton>

			<Box sx={{ display: { xs: "block", sm: "none" } }} />
			<Box sx={{ display: { xs: "block", sm: "none" } }}></Box>
			<Box
				sx={{ display: { xs: "block", sm: "none" } }}
			>
				<ProfileSection />
			</Box>

			<Toaster toastOptions={{
				duration: 5000,
				position: "bottom-right",
				style   : {
					borderRadius: "10px",
					background  : "#333",
					color       : "#FFF",
					boxShadow   : "0px 3px 16px #00000029"
				}
			}} >
				{t =>
					<ToastBar toast={t}>
						{({ icon, message }) =>
							<>
								{icon}
								{message}
								{t.type !== "loading" &&
                  <FaTimes className="sf-g-toast-close-btn-style" onClick={() => toast.dismiss(t.id)} />
								}
							</>
						}
					</ToastBar>
				}
			</Toaster>

			{/* mobile header */}
		</>
	);

};

Header.propTypes = {
	handleDrawerToggle: PropTypes.func
};

export default Header;
