// material-ui
import CloseIcon from "@mui/icons-material/Close";
import { Alert, Button, Fade, Grow, IconButton, Slide } from "@mui/material";
import MuiSnackbar, { SnackbarOrigin } from "@mui/material/Snackbar";
import React, { FC } from "react";
import { useDispatch, useSelector } from "../../../store";
import { closeSnackbar } from "../../../store/slices/snackbar";

// Define transition functions
const TransitionSlideLeft: FC = (props: any) => <Slide {...props} direction="left" />;
const TransitionSlideUp: FC = (props: any) => <Slide {...props} direction="up" />;
const TransitionSlideRight: FC = (props: any) => <Slide {...props} direction="right" />;
const TransitionSlideDown: FC = (props: any) => <Slide {...props} direction="down" />;
const GrowTransition: FC = (props: any) => <Grow {...props} />;

// Define animation options
const animation: any = {
	SlideLeft : TransitionSlideLeft,
	SlideUp   : TransitionSlideUp,
	SlideRight: TransitionSlideRight,
	SlideDown : TransitionSlideDown,
	Grow      : GrowTransition,
	Fade
};

// Snackbar component
const Snackbar: FC = () => {

	const dispatch = useDispatch();
	const snackbar = useSelector((state: any) => state.snackbar);
	const { actionButton, anchorOrigin, alert, close, message, open, transition, variant } = snackbar;

	const handleClose = (event: React.SyntheticEvent | MouseEvent, reason?: string) => {

		if (reason === "clickaway") {

			return;
    
		}
		dispatch(closeSnackbar());
  
	};

	const handleCloseSnackbar = () => {

		dispatch(closeSnackbar());

	};

	return (
		<>
			{/* default snackbar */}
			{variant === "default" &&
        <MuiSnackbar
        	anchorOrigin={anchorOrigin as SnackbarOrigin}
        	open={open}
        	autoHideDuration={6000}
        	onClose={handleCloseSnackbar}
        	message={message}
        	TransitionComponent={animation[transition]}
        	action={
        		<>
        			<Button color="secondary" size="small" onClick={handleClose}>
                UNDO
        			</Button>
        			<IconButton size="small" aria-label="close" color="inherit" onClick={handleClose} sx={{ mt: 0.25 }}>
        				<CloseIcon fontSize="small" />
        			</IconButton>
        		</>
        	}
        />
			}

			{/* alert snackbar */}
			{variant === "alert" &&
        <MuiSnackbar
        	TransitionComponent={animation[transition]}
        	anchorOrigin={anchorOrigin as SnackbarOrigin}
        	open={open}
        	autoHideDuration={6000}
        	onClose={handleCloseSnackbar}
        >
        	<Alert
        		variant={alert.variant}
        		color={alert.color}
        		action={
        			<>
        				{actionButton !== false &&
                  <Button size="small" onClick={handleClose} sx={{ color: "background.paper" }}>
                    UNDO
                  </Button>
        				}
        				{close !== false &&
                  <IconButton sx={{ color: "background.paper" }} size="small" aria-label="close" onClick={handleClose}>
                  	<CloseIcon fontSize="small" />
                  </IconButton>
        				}
        			</>
        		}
        		sx={{
        			...alert.variant === "outlined" && {
        				bgcolor: "background.paper"
        			}
        		}}
        	>
        		{message}
        	</Alert>
        </MuiSnackbar>
			}
		</>
	);

};

export default Snackbar;