import React, { useEffect, useState } from "react";

import { Col, Row } from "react-bootstrap";
import { Button, Container, Typography, useTheme } from "@mui/material";
import DataTable from "react-data-table-component";
import config from "../../../config/appConfig";
import Back from "../../../components/UI/Back";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import ApiRequest from "../../../api/ApiRequest";
import { APIUrl } from "../../../api/endPoints";
import Select from "../../../components/UI/Select";
import TooltipComponent from "../../../components/UI/Tooltip";
import { FaExclamationTriangle, FaPen, FaTrash } from "react-icons/fa";
import { FaRotateRight } from "react-icons/fa6";
import ModalComponent from "../../../components/modal";

const ListLevels = () => {

	const theme: any = useTheme();
	const navigate = useNavigate();
	const [ isListCourseDeleteModelOpen, setIsListCourseDeleteModelOpen ] = useState<boolean>(false);
	const [ deleteLevelName, setDeleteLevelName ] = useState<any>({});
	const [ isLoading, setIsLoading ] = useState<boolean>(false);
	const [ modules, setModules ] = useState([]);
	const [ selectedModule, setSelectedModule ] = useState<any>(null);
	const [ levels, setLevels ] = useState([]);

	// eslint-disable-next-line @typescript-eslint/no-var-requires, no-undef
	const { DataTableStyles } = require("../../../Utils/utils");
	useEffect(() => {

		fetchAllModules();
		const savedModule = sessionStorage.getItem("selectedModule");
		if (savedModule) {

			setSelectedModule(JSON.parse(savedModule));
			fetchAllLevelsByModule(JSON.parse(savedModule).value);
		
		}
		

	}, []);
	
	const fetchAllModules = () => {
		
		setIsLoading(true);
		ApiRequest("post", APIUrl.getAllModules, {}).then((response: any) => {

			setIsLoading(false);
			const sortedModules = response.data.map((module: any) => ({
				label: module.moduleName,
				value: module._id
			})).sort((a: any, b: any) => a.label.localeCompare(b.label));

			setModules(sortedModules);
			
		}).catch(error => {

			console.error("Error fetching modules", error);
			setIsLoading(false);
			
		});
	
	};

	const fetchAllLevelsByModule = (moduleId: string) => {

		setIsLoading(true);
		ApiRequest("post", APIUrl.listAllLevelByModule, { moduleId })
			.then((response:any) => {

				setIsLoading(false);
				setLevels(response.data);
			
			})
			.catch(error => {

				console.error("Error fetching levels", error);
				setIsLoading(false);
			
			});
	
	};

	const handleModuleChange = (selectedOption: any) => {

		setSelectedModule(selectedOption);
		sessionStorage.setItem("selectedModule", JSON.stringify(selectedOption));
		fetchAllLevelsByModule(selectedOption.value);

	
	};
	

	const cellRenderer = (text: any) => {

		const cellText = String(text);


		return <> {
			cellText?.length > 25 ? <>
				<TooltipComponent text={cellText} placement="top-start">
					<div>{cellText.substring(0, 25) + "..."}</div>
				</TooltipComponent>
			</> : cellText
		}
		</>;
	
	};
	const handleDelete = () => {

		
		const payload = {
			"levelId": deleteLevelName._id
		};
		
		ApiRequest("post", APIUrl.deleteLevel, payload).then(() => {
			
			toast.success("Level deleted successfully.");
			if (selectedModule?.value) {

				fetchAllLevelsByModule(selectedModule?.value);

			}

			setIsListCourseDeleteModelOpen(false);
			
		}).catch(error => {

			console.error("Error deleting level", error);
			setIsListCourseDeleteModelOpen(false);
			
		});

		setIsListCourseDeleteModelOpen(false);

	};

	const generalSortFunction = (key: string) => (a: any, b: any) => {

		const valueA = a[key];
		const valueB = b[key];
	  
		// Handle cases where values are not present
		if (valueA === undefined || valueB === undefined) return 0;
	  
		// If both values are numbers
		if (!isNaN(valueA) && !isNaN(valueB)) {

		  return parseFloat(valueA) - parseFloat(valueB);
		
		}
	  
		// Otherwise, treat them as strings
		return valueA.toString().toLowerCase().localeCompare(valueB.toString().toLowerCase());
	  
	};

	const editLevel = (row: any) => {

		navigate(`${config.routes.addLevelPage}#edit-level`, { state: row });

	};


	const columns: any = [
		{
			name        : "Order",
			selector    : (row:any) => row.levelOrder,
			cell        : (row:any) => cellRenderer(row.levelOrder ? row.levelOrder : ""),
			sortable    : true,
			center      : true,
			sortFunction: generalSortFunction("levelOrder"),
			width       : "100px"
		},
		{
			name        : "Level Name",
			selector    : (row:any) => row.levelName,
			cell        : (row:any) => cellRenderer(row.levelName ? row.levelName : ""),
			Grow        : 1,
			sortable    : true,
			sortFunction: generalSortFunction("levelName")
		},
		
		{
			name        : "Course Name",
			selector    : (row:any) => cellRenderer(row.courseName ? row.courseName : ""),
			Grow        : 1,
			cell        : (row:any) => cellRenderer(row.courseName ? row.courseName : ""),
			eclipsed    : true,
			sortable    : true,
			sortFunction: generalSortFunction("courseName")
            
		},
		{
			name        : "Module Name",
			selector    : (row:any) => cellRenderer(row.moduleName ? row.moduleName : ""),
			Grow        : 1,
			cell        : (row:any) => cellRenderer(row.moduleName ? row.moduleName : ""),
			sortable    : true,
			sortFunction: generalSortFunction("moduleName")
            
		},

		{
			name        : "Created At",
			selector    : (row:any) => row.createdAt ? new Date(row.createdAt).toLocaleString() : "",
			cell        : (row:any) => cellRenderer(row.createdAt ? new Date(row.createdAt).toLocaleString() : ""),
			sortFunction: generalSortFunction("createdAt"),
			center      : true,
			Grow        : 1
            
		},
		{
			name        : "Updated At",
			selector    : (row:any) => row.updatedAt ? new Date(row.updatedAt).toLocaleString() : "",
			cell        : (row:any) => cellRenderer(row.updatedAt ? new Date(row.updatedAt).toLocaleString() : ""),
			sortFunction: generalSortFunction("updatedAt"),
			center      : true,
			Grow        : 1
		},
		{
			name: "Action",

			center: true,
			cell  : (params : any) =>
				<div className="sh-fontawesome-edit-delete">

					<TooltipComponent text="Edit Level" placement="top-start" >
						<span
							className="sh-fontawesome-edit"
						>
							<FaPen size={15} onClick={()=> editLevel(params)} color={theme.palette.text.primary} />
						</span>
					</TooltipComponent>
					<TooltipComponent text="Delete Level" placement="top-start" >
						<span
							className="sh-fontawesome-edit"
						>
							<FaTrash size={15} onClick={() => {

								setIsListCourseDeleteModelOpen(true);
								setDeleteLevelName(params);
						
							}} color={theme.palette.text.primary} />
						</span>
					</TooltipComponent>
				</div>
            
		}
    
	];

	const handleAddLevel = () => {

		navigate(config.routes.addLevelPage);

	};
	const handleRotate = () => {

		setIsLoading(true);
		fetchAllModules();
		
	};

	
	return (
		<Container >

			<Row>
				<Col md={6} sm={6} xl={6} xs={6} >
					<div className="admin-dash-board-back-title-con" style={{ marginBottom: theme.spacing(2) }}
					>
						<Back
							props={{
								route  : config.routes.dashboard,
								state  : null,
								toolTip: "Back to Admin Dashboard",
								text   : "Back to admin dashboard"
							}}
						/>
					</div>
				</Col>
				<Col
					md={6} sm={6} xl={6} xs={6}
					className="sh-refresh-rotate-icon">
					<Button
						variant="outlined"
						style={{ backgroundColor: theme?.palette?.secondary.main, color: theme.palette.text.primary }}
						className="sh-module-intro-add-btn sh-list-lesson-btn "
						startIcon={<AddIcon />}
						onClick={handleAddLevel}
					>Add
					</Button>&nbsp;&nbsp;&nbsp;
					<Button onClick={handleRotate} style={{ backgroundColor: theme?.palette?.secondary.main, color: theme.palette.background.paper }}
						className="sh-list-lesson-refresh-con-button sh-list-level-height">
						<FaRotateRight color={theme.palette.text.primary} />
					</Button>
				</Col>
			</Row>
			<Row>
				<Col md={12} sm={12} xl={12} xs={12} >
					<div className="admin-dash-board-back-title-con" style={{ marginBottom: theme.spacing(2) }}
					>
						<Typography variant="h2" >List Levels</Typography>
					</div>
				</Col>
			</Row>
			<Row
				className="sh-add-level-select-course"
			>
				<Col xs={6} sm={6} md={6} xl={6} className="sh-add-level-select-course-col"
				><Select
						label="Select Module"
						options={modules}
						className="list-level-min-width-select"
						fullWidth
						value={selectedModule}
						handleChange={handleModuleChange}
						style={{ color: theme.palette.text.primary }}
						placeholder="Select the Module"
					/>
				</Col>
			</Row>
			<Row className="list-level-data-table">
				<Col className="list-level-data-table-col-width">
					<DataTable
						columns={columns}
						// data={ModuleData}
						progressPending={isLoading}
						progressComponent={<div className="sh-table-progress-con"><Typography variant="h4">Loading...</Typography></div>}
						data = {levels}
						customStyles={DataTableStyles}
						fixedHeader
						pagination
						paginationPerPage={10}
					/>
				</Col>
			</Row>
			<ModalComponent
				show={isListCourseDeleteModelOpen}
				size="lg"
				closeButton={true}
				onHide={() => setIsListCourseDeleteModelOpen(false)}
				icon={FaExclamationTriangle}
				modalTitle="Confirm Delete"
				modalContent={<Typography variant="h3" style={{ color: theme.palette.text.primary }}>
				Are you sure you want to delete this {deleteLevelName?.levelName} ?</Typography>}
				modalFooter={
					<div className="" style={{ display: "flex", flexDirection: "row" }}>
						<div style={{ paddingRight: "10px" }}>
							<Button variant="outlined" onClick={() => setIsListCourseDeleteModelOpen(false)} style={{ paddingRight: "10px" }}>Cancel</Button>
						</div>
						<div>
							<Button variant="contained" color="error" onClick={handleDelete}>Delete</Button>
						</div>
					</div>
				}
			/>
		</Container>
        
	);

};
	

export default ListLevels;

