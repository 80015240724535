import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

// material-ui
import {
	Avatar,
	Box,
	// Chip,
	ClickAwayListener,
	List,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	Paper,
	Popper,
	Typography
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import AccountCircleRoundedIcon from "@mui/icons-material/AccountCircleRounded";
import LanguageRoundedIcon from "@mui/icons-material/LanguageRounded";
// import ContactSupportRoundedIcon from "@mui/icons-material/ContactSupportRounded";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";


// third-party
import { FormattedMessage } from "react-intl";
// import PerfectScrollbar from "react-perfect-scrollbar";

// project imports

// import User1 from "../../../../../assets/icons/user-round.svg";

// import useAuth from "../../../../../Hooks/useAuth";
import MainCard from "../../../../UI/cards/MainCard";
import Transitions from "../../../../UI/extend/Transitions";
import { useSelector } from "../../../../../store";

// assets
import { IconLogout } from "@tabler/icons";
import useConfig from "../../../../../Hooks/useConfig";
import config from "../../../../../config/appConfig";
// import { auth } from "../../../../../firebase";

// import { signOut } from "firebase/auth";
import { useDispatch } from "react-redux";
import { setUser } from "../../../../../store/slices/userSlice";
import UserContext from "../../../../../context/AuthContext";
import useAuth from "../../../../../Hooks/useAuth";

// Profile Menu

const ProfileSection = () => {

	const theme: any = useTheme();
	const { borderRadius }: any = useConfig();
	const navigate = useNavigate();
	const routes = config.routes;
	const { profileIconPath, userType } = useSelector((state: any) => state.user);
	const dispatch = useDispatch();
	const { setUserDetails } = useContext(UserContext);

	/*
	 *   const [ sdm, setSdm ] = useState(true);
	 *   const [ value, setValue ] = useState("");
	 *   const [ notification, setNotification ] = useState(false);
	 */
	const [ selectedIndex, setSelectedIndex ] = useState(-1);
	const { logout } = useAuth();
	const [ open, setOpen ] = useState(false);
	const anchorRef: any = useRef(null);

	const handleLogout = async () => {

		try {

			await logout();
			// await signOut(auth);
			
			dispatch(setUser({ user: {
				id                : "",
				email             : "",
				firstName         : "",
				lastName          : "",
				points            : 0,
				profileCoverPath  : "",
				profileIconPath   : "",
				userType          : "",
				userVerified      : false,
				skillLevel        : "",
				userProgressData  : {},
				userAddress       : {},
				lastViewedPost    : "",
				lastViewedPostTime: ""
			} }));

			sessionStorage.removeItem("userDetails");
			setUserDetails(null);
			navigate(config.routes.landingPage);

		} catch (err) {

			console.error(err);

		}

	};

	const handleClose = (event: any) => {

		if (anchorRef.current && anchorRef.current.contains(event.target)) {

			return;

		}
		setOpen(false);

	};
	const handleListItemClick = (event: any, index: any, route: any = "") => {

		// if (location.pathname == route) {
	
		setSelectedIndex(index);
	
		// } else {
				
		// 	setSelectedIndex(-1);
	
		// }

		handleClose(event);

		if (route && route !== "") {

			navigate(route);

		}

	};
	const handleToggle = () => {

		setOpen(prevOpen => !prevOpen);

	};

	const prevOpen = useRef(open);
	useEffect(() => {

		if (prevOpen.current === true && open === false) {

			anchorRef.current.focus();

		}

		prevOpen.current = open;

	}, [ open ]);

	return (
		<>
			<Avatar
				src={profileIconPath}
				sx={{
					...theme.typography.mediumAvatar,
					margin: "8px 0 8px 8px !important",
					cursor: "pointer"
				}}
				ref={anchorRef}
				aria-controls={open ? "menu-list-grow" : undefined}
				aria-haspopup="true"
				aria-label="user-account"
				color="inherit"
				onClick={handleToggle}
				alt="user-images"
			/>

			<Popper
				placement="bottom"
				open={open}
				anchorEl={anchorRef.current}
				role={undefined}
				transition
				disablePortal
				modifiers={[
					{
						name   : "offset",
						options: {
							offset: [ 0, 14 ]
						}
					}
				]}
			>
				{({ TransitionProps }) =>
					<ClickAwayListener onClickAway={handleClose}>
						<Transitions in={open} {...TransitionProps}>
							<Paper>
								{open &&
									<MainCard
										border={false}
										elevation={16}
										content={false}
										boxShadow
										shadow={theme.shadows[16]}
									>
										{/* <Box sx={{ p: 2, pb: 0 }}>
                                            <Stack>
                                                <Stack direction="row" spacing={0.5} alignItems="center">
                                                    <Typography variant="h4">Good Morning,</Typography>
                                                    <Typography component="span" variant="h4" sx={{ fontWeight: 400 }}>
                                                        {user?.name}
                                                    </Typography>
                                                </Stack>
                                                <Typography variant="subtitle2">Project Admin</Typography>
                                            </Stack>
                                            <OutlinedInput
                                                sx={{ width: "100%", pr: 1, pl: 2, my: 2 }}
                                                id="input-search-profile"
                                                value={value}
                                                onChange={(e) => setValue(e.target.value)}
                                                placeholder="Search profile options"
                                                startAdornment={
                                                    <InputAdornment position="start">
                                                        <IconSearch stroke={1.5} size="16px" color={theme.palette.grey[500]} />
                                                    </InputAdornment>
                                                }
                                                aria-describedby="search-helper-text"
                                                inputProps={{
                                                    "aria-label": "weight"
                                                }}
                                            />
                                            <Divider />
                                        </Box>                       */}
										{/* <PerfectScrollbar
											style={{
												height   : "100%",
												maxHeight: "calc(100vh - 250px)",
												overflowX: "auto"
											}}
										> */}
										<Box sx={{ p: 2, pt: 0 }}>
											{/* <UpgradePlanCard />
                                                <Divider />
                                                <Card
                                                    sx={{
                                                        bgcolor:
                                                            theme.palette.mode === "dark"
                                                                ? theme.palette.dark[800]
                                                                : theme.palette.primary.light,
                                                        my: 2
                                                    }}
                                                >
                                                    <CardContent>
                                                        <Grid container spacing={3} direction="column">
                                                            <Grid item>
                                                                <Grid item container alignItems="center" justifyContent="space-between">
                                                                    <Grid item>
                                                                        <Typography variant="subtitle1">Start DND Mode</Typography>
                                                                    </Grid>
                                                                    <Grid item>
                                                                        <Switch
                                                                            color="primary"
                                                                            checked={sdm}
                                                                            onChange={(e) => setSdm(e.target.checked)}
                                                                            name="sdm"
                                                                            size="small"
                                                                        />
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid item>
                                                                <Grid item container alignItems="center" justifyContent="space-between">
                                                                    <Grid item>
                                                                        <Typography variant="subtitle1">Allow Notifications</Typography>
                                                                    </Grid>
                                                                    <Grid item>
                                                                        <Switch
                                                                            checked={notification}
                                                                            onChange={(e) => setNotification(e.target.checked)}
                                                                            name="sdm"
                                                                            size="small"
                                                                        />
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </CardContent>
                                                </Card>
                                                <Divider /> */}
											<List
												component="nav"
												sx={{
													width                         : "100%",
													maxWidth                      : 350,
													minWidth                      : 300,
													backgroundColor               : theme.palette.background.paper,
													borderRadius                  : "10px",
													[theme.breakpoints.down("md")]: {
														minWidth: "100%"
													},
													"& .MuiListItemButton-root": {
														mt: 0.5
													}
												}}
											>
												<ListItemButton
													sx={{ borderRadius: `${borderRadius}px` }}
													selected={selectedIndex === 0}
													onClick={event =>
														handleListItemClick(event, 0, routes.userProfile)
													}
												>
													<ListItemIcon>
														<AccountCircleRoundedIcon />
													</ListItemIcon>
													<ListItemText
														primary={
															<Typography variant="body2">
																<FormattedMessage id="myProfile" />
															</Typography>
														}
													/>
												</ListItemButton>
												{
													userType === config.userTypes.systemAdmin && <>
														<ListItemButton
															sx={{ borderRadius: `${borderRadius}px` }}
															selected={selectedIndex === 1}
															onClick={event =>
																handleListItemClick(event, 1, routes.dashboard)
															}
														>
															<ListItemIcon>
																<AdminPanelSettingsIcon />
															</ListItemIcon>
															<ListItemText
																primary={
																	<Typography variant="body2">
																	Admin Dashboard
																	</Typography>
																}
															/>
														</ListItemButton>
													</>}
													
												<ListItemButton
													sx={{ borderRadius: `${borderRadius}px` }}
													selected={selectedIndex === 2}
													onClick={() => navigate(routes.announcement)}

														
													// onClick={event => handleListItemClick(event, 0, routes.userProfile) }
												>
													<ListItemIcon>
														<LanguageRoundedIcon />
													</ListItemIcon>
													<ListItemText
														primary={
															<Typography variant="body2">
																<FormattedMessage id="community" />
															</Typography>
														}
													/>
												</ListItemButton>
												{/* <ListItemButton
														sx={{ borderRadius: `${borderRadius}px` }}
														selected={selectedIndex === 3}

														// onClick={event => handleListItemClick(event, 0, routes.userProfile) }
													>
														<ListItemIcon>
															<ContactSupportRoundedIcon />
														</ListItemIcon>
														<ListItemText
															primary={
																<Typography variant="body2">
																	<FormattedMessage id="support" />
																</Typography>
															}
														/>
													</ListItemButton> */}
												<ListItemButton
													sx={{ borderRadius: `${borderRadius}px` }}
													selected={selectedIndex === 4}
													onClick={handleLogout}
												>
													<ListItemIcon>
														<IconLogout stroke={1.5} size="20px" />
													</ListItemIcon>
													<ListItemText
														primary={
															<Typography variant="body2">
																<FormattedMessage id="logout" />
															</Typography>
														}
													/>
												</ListItemButton>
											</List>
										</Box>
										{/* </PerfectScrollbar> */}
									</MainCard>
								}
							</Paper>
						</Transitions>
					</ClickAwayListener>
				}
			</Popper>
		</>
	);

};

export default ProfileSection;
