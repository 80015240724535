import axios from "axios";

const axiosInstance = axios.create();
import { auth } from "../firebase";

axiosInstance.interceptors.request.use(async (config: any) => {

	const user = auth.currentUser;
	
	if (user) {
		
		const token = await user.getIdToken();
		
		if (token) {

			config.headers.Authorization = `Bearer ${token}`;

		} else {

			delete config.headers.Authorization;

		}

	}

	return config;

});

axiosInstance.interceptors.response.use((response: any) => {

	return response;

}, async (error: any) => {

	return Promise.reject(error);

});


export default axiosInstance;