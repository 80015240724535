import React, { useState } from "react";
import { Container, Typography, Button } from "@mui/material";
import Select from "../../../components/UI/Select";
import { Col, Row } from "react-bootstrap";
import { useEffect } from "react";
import ApiRequest from "../../../api/ApiRequest";
import { APIUrl } from "../../../api/endPoints";
import Form from "react-bootstrap/Form";
import { useTheme } from "@mui/material";
import Back from "../../../components/UI/Back";
import config from "../../../config/appConfig";
import { useLocation, useNavigate } from "react-router-dom";
// import { set } from "lodash";


const AddLevelPage = () => {

	const [ course, setCourse ] = useState<any>([]);
	const [ courseOptions, setCourseOptions ] = useState<any>([]);
	const [ moduleOptions, setModuleOptions ] = useState<any>([]);
	const [ moduleName, setModuleName ] = useState<any>([]);
	const [ levelName, setLevelName ] = useState<string>("");
	const [ levelDescription, setLevelDescription ] = useState<string>("");
	const [ errorMessage, setErrorMessage ] = useState<string>("");
	const [ isError, setIsError ] = useState<boolean>(false);
	const [ isEditLevel, setIsEditLevel ] = useState<boolean>(false);
	const [ levelOrder, setLevelOrder ] = useState<number>(0);
	const [ moduleData, setModuleData ] = useState<any>([]);

	const location = useLocation();
	const navigate = useNavigate();
	const state = location.state;
	const theme: any = useTheme();

	useEffect(() => {

		fetchAllCourses();

		if (location.hash === "#edit-level" && state?._id) {

			setIsEditLevel(true);
			setLevelDescription(state?.levelDesc);
			setLevelName(state?.levelName);
			setLevelOrder(state?.levelOrder);

		}


	}, []);

	const fetchAllCourses = () => {

		ApiRequest("get", APIUrl.getAllCourse).then((response: any) => {

			if (response) {

				const options: any = [];
				const selectedCourse: any = {};

				response?.map((item: any) => {

					if (state?.courseId === item._id) {

						selectedCourse.label = item.courseName;
						selectedCourse.value = item._id;

					}

					options.push({ label: item.courseName, value: item._id });

				});

				if (state?.courseId) {

					setCourse(selectedCourse);
					fetchAllModules(state?.courseId);

				}

				setCourseOptions(options);

			}

		}).catch((error: any) => {

			console.error("Error fetching data", error);

		});


	};

	const handleCourseSelect = (e: any) => {

		setCourse(e);
		setModuleName([]);
		fetchAllModules(e.value);

	};

	const fetchAllModules = (courseId: string) => {

		ApiRequest("post", APIUrl.getAllModules, { courseId: courseId }).then((response: any) => {

			if (response?.data && response?.data?.length > 0) {

				const module: any = [];
				const selectedModule: any = {};

				setModuleData(response?.data);

				response?.data?.map((item: any) => {

					if (state?.moduleId === item._id) {

						selectedModule.label = item.moduleName;
						selectedModule.value = item._id;

					}

					module.push({ label: item.moduleName, value: item._id });

				});

				if (state?.moduleId) {

					setModuleName(selectedModule);

				}

				if (module && module.length) {

					setModuleOptions(module);

				} else {

					setModuleOptions([]);

				}


			} else {

				setModuleOptions([]);

			}


		}).catch((error: any) => {

			console.error("Error fetching data", error);

		});

	};

	const handleModuleSelect = (e: any) => {

		setModuleName(e);

		const moduleId = e.value;

		if (moduleData) {
			
			const module: any = moduleData.find((item: any) => item._id === moduleId);

			if (module) {

				setLevelOrder(module.level + 1);

			}

		}

	};

	const handleCancel = () => {

		navigate(config.routes.listLevels);

		setCourse([]);
		setModuleName([]);
		setLevelName("");


	};
	const handleAddLevel = () => {


		if (course.value === "" || moduleName.value === "" || levelName.trim() === "") {


			setIsError(true);
			setErrorMessage("*Fill All Required Fields");


			return;

		}

		const level: any = {
			levelData: {
				courseId  : course.value,
				moduleId  : moduleName.value,
				levelName : levelName,
				levelDesc : levelDescription,
				levelIcon : "",
				levelOrder: levelOrder
			}
		};

		if (isEditLevel) {

			level.levelData._id = state._id;

			ApiRequest("post", APIUrl.updateLevel, level).then((response: any) => {

				if (response) {

					navigate(config.routes.listLevels);

				}

			}).catch((error: any) => {

				console.error("Error fetching data", error);

			});

		} else {

			ApiRequest("post", APIUrl.addLevel, level).then((response: any) => {

				if (response) {

					navigate(config.routes.listLevels);

				}

			}).catch((error: any) => {

				console.error("Error fetching data", error);

			});
		
		}
		setIsError(false);
		setErrorMessage("");

	};


	return (
		<Container>
			<Row>
				<Col md={12} sm={12} xl={12} xs={12} >
					<div className="admin-dash-board-back-title-con" style={{ marginBottom: theme.spacing(2) }}
					>
						<Back
							props={{
								route  : config.routes.listLevels,
								state  : null,
								toolTip: "Back to list levels ",
								text   : "Back to Levels"
							}}
						/>
					</div>
				</Col>
			</Row>
			<Row>
				<Col md={12} sm={12} xl={12} xs={12} >
					<div className="admin-dash-board-back-title-con" style={{ marginBottom: theme.spacing(2) }}
					>
						<Typography style={{ color: theme.palette.text.primary }} variant="h2">{isEditLevel ? "Edit Level" : "Add Level"}</Typography>
					</div>
				</Col>
			</Row>

			<Row style={{ marginTop: "2em" }}>
				<Col md={3} sm={3} xl={3} xs={3}
				>
					<Typography style={{ color: theme.palette.text.primary }}>Select Course <span style={{ color: theme?.palette?.error?.main }} className="sh-add-lesson-marginTop">*</span></Typography>
				</Col>
				<Col md={4} sm={4} xl={4} xs={4}
					className="sh-add-module-select-label" >
					<Select
						className="sh-add-module-width"
						options={courseOptions}
						value={course}
						handleChange={handleCourseSelect}
						placeholder="Select Course"
					/>
				</Col>
			</Row>

			<Row style={{ marginTop: "2em" }}>
				<Col md={3} sm={3} xl={3} xs={3}>
					<Typography style={{ color: theme.palette.text.primary }}>Select Module <span style={{ color: theme?.palette?.error?.main }} className="sh-add-lesson-marginTop">*</span></Typography>
				</Col>
				<Col md={4} sm={4} xl={4} xs={4}
					className="sh-style-add-level-page-select">
					<Select
						className="sh-add-module-width"
						options={moduleOptions}
						value={moduleName}
						handleChange={handleModuleSelect}
						isDisabled={!course.value}
						placeholder="Select Module"
					/>
				</Col>
			</Row>
			<Row
				style={{ marginTop: "2em" }}
			>

				<Col md={3} sm={3} xl={3} xs={3}>
					<Typography style={{ color: theme.palette.text.primary }} >Level Order</Typography>
				</Col>

				<Col md={4} sm={4} xl={4} xs={4}
					className="sh-add-level-formcontrol">
					<Form.Control
						className="custom-placeholder"
						style={{ backgroundColor: theme.palette.background.paper, color: theme.palette.text.primary }}
						id="outlined-basic"
						placeholder="Enter level order"
						/*
						 * label="Enter Module Price"
						 * variant="outlined"
						 */
						value={levelOrder}
						// fullWidth
						type="number"
						onChange={(e: any) => setLevelOrder(e?.target?.value)}
						// size="small"
						color="secondary"
						onFocus={(e: any) => {

							try {
	
								const focusColor: string = theme.palette.secondary.main;
	
								e.target.style.borderColor = focusColor;
								e.target.style.borderWidth = "2px";
								e.target.style.fontSize = "13px";
								e.target.style.boxShadow = `rgba(0, 0, 0, 0.16) 0px 1px 0px, ${focusColor} 0px 0px 0px 0.3px`;
										
							} catch (error: any) {
	
								console.error("Error on focus", error);
	
							}
								
						}}
						onBlur={(e: any) => {
	
							try {
	
								e.target.style.borderColor = "";
								e.target.style.boxShadow = "";
										
							} catch (error: any) {
	
								console.error("Error on blur", error);
	
							}
								
						}}
								
					/>
				</Col>

			</Row>

			<Row style={{ marginTop: "2em" }}>
				<Col md={3} sm={3} xl={3} xs={3}>
					<Typography style={{ color: theme.palette.text.primary }}>Level Name <span style={{ color: theme?.palette?.error?.main }} className="sh-add-lesson-marginTop">*</span></Typography>
				</Col>
				<Col md={4} sm={4} xl={4} xs={4}>
					<style>
						{`
						.custom-placeholder::placeholder {
							color:${theme.palette.text.primary} !important;
							opacity: 1;
							font-size: 14px;
						}
						`}
					</style>

					<Form.Control
						className="sh-add-module-name-text-field sh-add-module-name-text-field-level custom-placeholder"
						style={{ backgroundColor: theme.palette.background.paper, color: theme.palette.text.primary }}
						id="outlined-basic"
						placeholder="Enter Level Name"
						value={levelName}
						onChange={(e: any) => setLevelName(e?.target?.value)}
						color="secondary" onFocus={(e: any) => {

							try {

								const focusColor: string = theme.palette.secondary.main;

								e.target.style.borderColor = focusColor;
								e.target.style.borderWidth = "2px";
								e.target.style.fontSize = "13px";
								e.target.style.boxShadow = `rgba(0, 0, 0, 0.16) 0px 1px 0px, ${focusColor} 0px 0px 0px 0.3px`;

							} catch (error: any) {

								console.error("Error on focus", error);

							}

						}}
						onBlur={(e: any) => {

							try {

								e.target.style.borderColor = "";
								e.target.style.boxShadow = "";

							} catch (error: any) {

								console.error("Error on blur", error);

							}

						}}
					/>

				</Col>
			</Row>
			<Row
				style={{ marginTop: "2em" }}
			>

				<Col md={3} sm={3} xl={3} xs={3}>
					<Typography style={{ color: theme.palette.text.primary }} >Level Description</Typography>
				</Col>

				<Col md={4} sm={4} xl={4} xs={4}
					className="sh-add-level-formcontrol sh-add-level-description ">
					<Form.Control
						as="textarea"
						className="custom-placeholder"
						placeholder="Enter level Description"
						style={{ backgroundColor: theme.palette.background.paper, color: theme.palette.text.primary }}
						value={levelDescription}
						onChange={(e: any) => {

							setLevelDescription(e?.target?.value);

						}}
						onFocus={(e: any) => {

							const focusColor: string = theme.palette.secondary.main;


							e.target.style.borderColor = focusColor;
							e.target.style.borderWidth = "2px";
							e.target.style.boxShadow = `rgba(0, 0, 0, 0.16) 0px 1px 0px, ${focusColor} 0px 0px 0px 0.3px`;

						}}
						onBlur={(e: any) => {

							e.target.style.borderColor = "";
							e.target.style.boxShadow = "";

						}}
					/>


				</Col>

			</Row>
			<Row
				
				style={{
					marginTop     : "2em",
					justifyContent: "flex-start"
				}}
			>
				<Col>
					<Button
						variant="outlined"
						color="secondary"
						onClick={handleCancel}
					>
						Cancel
					</Button> &nbsp;&nbsp;
					<Button
						color="secondary"
						variant="contained"
						onClick={handleAddLevel}
					>
						{isEditLevel ? "Update" : "Add Level"}
					</Button>
				</Col>

			</Row>
			<Row className="sh-add-level-error">
				{
					isError &&
					<div className="">
						<Typography variant="subtitle1" sx={{ color: theme?.palette?.error?.main }}> {errorMessage} </Typography>
					</div>
				}
			</Row>


		</Container>

	);

};

export default AddLevelPage;

