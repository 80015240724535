import React, { useEffect, useReducer, useState } from "react";
// import { Col, Row } from "react-bootstrap";
import { Row, Col, Grid } from "rsuite";
import { useNavigate } from "react-router-dom";
import * as Tone from "tone";
import config from "../config/appConfig";
/*
 * import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
 * import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
 */
import { FaChevronLeft } from "react-icons/fa";
import { useTheme } from "@mui/material/styles";
// import { Tooltip } from "@mui/material";
import Select from "../components/UI/Select";
import { Button } from "@mui/material";
import "rsuite/dist/rsuite.css";
import TooltipComponent from "../components/UI/Tooltip";

const initialState = {
	isRecording  : false,
	audioContext : null,
	analyser     : null,
	stream       : null,
	canvasContext: null,
	waveType     : "Sine wave",
	roundingType : "note",
	smoothingType: "basic",
	currentNote  : "No note detected",
	currentHz    : ""
};

const reducer = (state: any, action: any) => {

	switch (action.type) {

	case "SET_STATE":
		return { ...state, ...action.payload };
	default:
		return state;

	}

};

const SoundAnalyzer = () => {

	const navigate = useNavigate();
	const theme: any = useTheme();
	const [ state, dispatch ] = useReducer(reducer, initialState);
	const {
		isRecording,
		audioContext,
		analyser,
		stream,
		canvasContext,
		waveType,
		roundingType,
		smoothingType,
		currentNote,
		currentHz,
		/*
		 * q,
		 * gain,
		 * freq,
		 */
		biquadFilter,
		filterType
	} = state;
	const [ audioInputDevices, setAudioInputDevices ] = useState([]);
	const [ selectedDeviceId, setSelectedDeviceId ] = useState<any>(null);
	const [ notesSequence, setNotesSequence ] = useState<any>([]);

	const noteStrings = [
		"C",
		"C#",
		"D",
		"D#",
		"E",
		"F",
		"F#",
		"G",
		"G#",
		"A",
		"A#",
		"B"
	];

	useEffect(() => {

		const canvas: any = document.querySelector(".visualizer");
		dispatch({
			type   : "SET_STATE",
			payload: {
				canvasContext: canvas.getContext("2d"),
				/*
				 * q: 0.25, gain: 25, freq: 500, filterType: "bandpass"
				 * q: 2, gain: 25, freq: 3000, filterType: "bandpass" // Good at music.
				 */
				// Good at music with noise.
				q            : 1, gain         : 25, freq         : 2000, filterType   : "bandpass"
				/*
				 * q: 1, gain: 25, freq: 1000, filterType: "bandpass" // Good at singing.
				 * q: 1, gain: 50, freq: 1500
				 */
			}
		});

		const getInputAudioDevices = async () => {

			await navigator.mediaDevices.getUserMedia({ audio: true });
			const devices = await navigator.mediaDevices.enumerateDevices();

			const getUsersAudioDetails: any = [];
			devices.forEach(device => {

				if (device.kind === "audioinput" && device.deviceId !== "default" && device.deviceId !== "communications") {

					getUsersAudioDetails.push({
						value: device.deviceId,
						label: device.label
					});

				}

			});

			setAudioInputDevices(getUsersAudioDetails);

			setSelectedDeviceId(getUsersAudioDetails[0]);

		};

		getInputAudioDevices();

	}, []);

	const initAudioContext = async () => {

		try {

			const context = new window.AudioContext();
			const newAnalyser = context.createAnalyser();
			newAnalyser.minDecibels = -100;
			newAnalyser.maxDecibels = -10;
			newAnalyser.smoothingTimeConstant = 0.85;
			newAnalyser.fftSize = 2048;

			const biquadFilter = context.createBiquadFilter();

			// newAnalyser.connect(biquadFilter);

			if (!navigator.mediaDevices?.getUserMedia) {

				alert("Sorry, getUserMedia is required for the app.");

				return;

			} else {

				const constraints = {
					audio: {
						deviceId        : { exact: selectedDeviceId?.value },
						echoCancellation: false,
						noiseSuppression: false,
						latency         : 0,
						sampleRate      : 44100,
						channelCount    : 2,
						audioType       : "audioinput"
					}

				};
				const mediaStream = await navigator.mediaDevices.getUserMedia(
					constraints
				);
				const source = context.createMediaStreamSource(mediaStream);

				biquadFilter.type = state.filterType;
				// biquadFilter.frequency.value = 3000;
				biquadFilter.Q.setTargetAtTime(state.q, context.currentTime, 0.01);
				biquadFilter.frequency.setTargetAtTime(state.freq, context.currentTime, 0.01);
				biquadFilter.gain.setTargetAtTime(state.gain, context.currentTime, 0.01);
				// biquadFilter.gain.setTargetAtTime(10, context.currentTime, 0.01);

				source.connect(biquadFilter);
				biquadFilter.connect(newAnalyser);
				newAnalyser.connect(context.destination);


				dispatch({
					type   : "SET_STATE",
					payload: {
						audioContext: context,
						analyser    : newAnalyser,
						stream      : mediaStream,
						biquadFilter: biquadFilter
					}
				});
				// const player = new Tone.UserMedia().toDestination();

				/*
				 * console.info("Tone.Player", player);
				 * setTonePlayer(player);
				 * Tone.start();
				 */

			}

		} catch (error) {

			console.error("Error initializing audio context:", error);

		}

	};

	const startRecording = () => {

		if (!isRecording) {

			dispatch({ type: "SET_STATE", payload: { isRecording: true } });
			initAudioContext();

		} else {

			stopRecording();

		}

	};

	const stopRecording = () => {

		if (audioContext && analyser && stream) {

			stream.getTracks().forEach((track: any) => track.stop());
			audioContext.close();
			dispatch({
				type   : "SET_STATE",
				payload: {
					isRecording : false,
					audioContext: null,
					analyser    : null,
					stream      : null,
					biquadFilter: null
				}
			});
			/*
			 * if (tonePlayer) {
			 * 	tonePlayer.dispose();
			 * 	setTonePlayer(null);
			 * }
			 */

			// Tone.Transport.stop();

		}

	};

	const extractRhythm = () => {

		if (!analyser) return;

		// Adjust as needed
		const threshold = 0.8;
		const bufferLength = analyser.fftSize;
		const dataArray: any = new Uint8Array(bufferLength);

		const detectBeat = () => {

			if (!isRecording) {

				Tone.Transport.stop();

				return;

			}
			requestAnimationFrame(detectBeat);

			analyser.getByteFrequencyData(dataArray);

			const peak = Math.max(...dataArray) / 255;

			if (peak > threshold) {

				console.info("Beat detected", peak);
				console.info("Beat detected");

			}

			/*
			 * let sum = 0;
			 * for (let i = 0; i < dataArray.length; i++) {
			 *     sum += dataArray[i];
			 * }
			 */

			/*
			 * const average = sum / dataArray.length;
			 * if (average > threshold) {
			 *     console.log("Beat detected");
			 * }
			 */

		};
		Tone.Transport.scheduleRepeat(() => {

			detectBeat();

		}, "8n"
		);

		Tone.Transport.start();

	};

	// Other functions (visualizeWave, visualizeFrequencyBars, autoCorrelate, noteFromPitch, drawNote) remain unchanged

	const visualizeWave = () => {

		extractRhythm();

		if (!canvasContext || !analyser) return;

		const WIDTH = canvasContext.canvas.width;
		const HEIGHT = canvasContext.canvas.height;

		requestAnimationFrame(visualizeWave);

		const bufferLength = analyser.fftSize;
		const dataArray = new Uint8Array(bufferLength);
		analyser.getByteTimeDomainData(dataArray);

		canvasContext.fillStyle = "rgb(200, 200, 200)";
		canvasContext.fillRect(0, 0, WIDTH, HEIGHT);

		canvasContext.lineWidth = 2;
		canvasContext.strokeStyle = "rgb(0, 0, 0)";
		canvasContext.beginPath();

		const sliceWidth = (WIDTH * 1.0) / bufferLength;
		let x = 0;

		for (let i = 0; i < bufferLength; i++) {

			const v = dataArray[i] / 128.0;
			const y = (v * HEIGHT) / 2;

			if (i === 0) {

				canvasContext.moveTo(x, y);

			} else {

				canvasContext.lineTo(x, y);

			}

			x += sliceWidth;

		}

		canvasContext.lineTo(
			canvasContext.canvas.width,
			canvasContext.canvas.height / 2
		);
		canvasContext.stroke();

		// Display current note and Hz value
		canvasContext.fillText(
			`Note: ${currentNote}`,
			10,
			canvasContext.canvas.height - 40
		);
		canvasContext.fillText(
			`Hz: ${currentHz}`,
			10,
			canvasContext.canvas.height - 20
		);

	};

	const visualizeFrequencyBars = () => {

		if (!canvasContext || !analyser) return;

		const bufferLength = analyser.frequencyBinCount;
		const dataArray = new Uint8Array(bufferLength);

		const drawBars = () => {

			requestAnimationFrame(drawBars);

			analyser.getByteFrequencyData(dataArray);

			canvasContext.fillStyle = "rgb(0, 0, 0)";
			canvasContext.fillRect(
				0,
				0,
				canvasContext.canvas.width,
				canvasContext.canvas.height
			);

			const barWidth = (canvasContext.canvas.width / bufferLength) * 2.5;
			let x = 0;

			for (let i = 0; i < bufferLength; i++) {

				const barHeight = dataArray[i];

				canvasContext.fillStyle = "rgb(" + (barHeight + 100) + ",50,50)";
				canvasContext.fillRect(
					x,
					canvasContext.canvas.height - barHeight / 2,
					barWidth,
					barHeight / 2
				);

				x += barWidth + 1;

			}

		};

		drawBars();

		// Display current note and Hz value
		canvasContext.fillText(
			`Note: ${currentNote}`,
			10,
			canvasContext.canvas.height - 40
		);
		canvasContext.fillText(
			`Hz: ${currentHz}`,
			10,
			canvasContext.canvas.height - 20
		);

	};

	// Function to perform autocorrelation
	const autoCorrelate = (buffer: any, sampleRate: any) => {

		// Perform a quick root-mean-square to see if we have enough signal
		const SIZE = buffer.length;
		let sumOfSquares = 0;
		buffer.forEach((val: any) => {

			sumOfSquares += val * val;

		});
		const rootMeanSquare = Math.sqrt(sumOfSquares / SIZE);
		if (rootMeanSquare < 0.01) {

			return -1;

		}

		// Find a range in the buffer where the values are below a given threshold.
		let r1 = 0;
		let r2 = SIZE - 1;
		const threshold = 0.2;

		// Walk up for r1
		for (let i = 0; i < SIZE / 2; i++) {

			if (Math.abs(buffer[i]) < threshold) {

				r1 = i;
				break;

			}

		}

		// Walk down for r2
		for (let i = 1; i < SIZE / 2; i++) {

			if (Math.abs(buffer[SIZE - i]) < threshold) {

				r2 = SIZE - i;
				break;

			}

		}

		// Trim the buffer to these ranges and update SIZE.
		buffer = buffer.slice(r1, r2);
		const newSize = buffer.length;

		// Create a new array of the sums of offsets to do the autocorrelation
		const c = new Array(newSize).fill(0);

		// For each potential offset, calculate the sum of each buffer value times its offset value
		for (let i = 0; i < newSize; i++) {

			for (let j = 0; j < newSize - i; j++) {

				c[i] += buffer[j] * buffer[j + i];

			}

		}

		// Find the last index where that value is greater than the next one (the dip)
		let d = 0;
		while (c[d] > c[d + 1]) {

			d++;

		}

		// Iterate from that index through the end and find the maximum sum
		let maxValue = -1;
		let maxIndex = -1;
		for (let i = d; i < newSize; i++) {

			if (c[i] > maxValue) {

				maxValue = c[i];
				maxIndex = i;

			}

		}

		let T0 = maxIndex;

		// Parabolic interpolation
		const x1 = c[T0 - 1];
		const x2 = c[T0];
		const x3 = c[T0 + 1];

		const a = (x1 + x3 - 2 * x2) / 2;
		const b = (x3 - x1) / 2;
		if (a) {

			T0 = T0 - b / (2 * a);

		}

		return sampleRate / T0;

	};

	// Function to derive note from pitch
	function noteFromPitch(frequency: any) {

		const noteNum = 12 * (Math.log(frequency / 440) / Math.log(2));

		return Math.round(noteNum) + 69;

	}

	const drawNote = () => {

		const bufferLength = analyser.fftSize;
		const buffer = new Float32Array(bufferLength);
		analyser.getFloatTimeDomainData(buffer);
		const autoCorrelateValue = autoCorrelate(buffer, audioContext.sampleRate);

		/*
		 * const MIN_VIOLIN_FREQ = 196;
		 * const MAX_VIOLIN_FREQ = 3000;
		 */
		const MIN_VIOLIN_FREQ = 0;
		const MAX_VIOLIN_FREQ = 30000000;

		if (
			autoCorrelateValue < MIN_VIOLIN_FREQ ||
			autoCorrelateValue > MAX_VIOLIN_FREQ
		) {

			dispatch({
				type   : "SET_STATE",
				payload: {
					currentNote: "No note detected",
					currentHz  : ""
				}
			});

			return;

		}

		// Handle rounding
		let valueToDisplay: any = autoCorrelateValue;
		const roundingValue = roundingType;
		if (roundingValue === "none") {
			// Do nothing
		} else if (roundingValue === "hz") {

			valueToDisplay = Math.round(valueToDisplay);

		} else {

			// Get the closest note
			valueToDisplay = noteStrings[noteFromPitch(autoCorrelateValue) % 12];
			const octave = Math.floor(noteFromPitch(autoCorrelateValue) / 12) - 1;
			valueToDisplay += ` ${octave}`;

		}

		// let smoothingValue = smoothingType;

		if (autoCorrelateValue === -1) {

			dispatch({
				type   : "SET_STATE",
				payload: {
					currentNote: "No note detected",
					currentHz  : ""
				}
			});

			return;

		} else if (autoCorrelateValue >= 10000) {

			dispatch({
				type   : "SET_STATE",
				payload: {
					currentNote: "Too high to detect",
					currentHz  : ""
				}
			});
			/*
			 * setCurrentHz("No note detected");
			 * setCurrentNote();
			 */

			return;

		} else if (autoCorrelateValue <= 20) {

			dispatch({
				type   : "SET_STATE",
				payload: {
					currentNote: "Too low to detect",
					currentHz  : ""
				}
			});

			/*
			 * setCurrentHz("No note detected");
			 * setCurrentNote("Too low to detect");
			 */
			return;

		} else if (autoCorrelateValue === 0) {

			dispatch({
				type   : "SET_STATE",
				payload: {
					currentNote: "No note detected",
					currentHz  : ""
				}
			});

			/*
			 * setCurrentHz("No note detected");
			 * setCurrentNote();
			 */

			return;

		}

		/*
		 * if (smoothingValue === "none") {
		 * 	smoothingThreshold = 99999;
		 * 	smoothingCountThreshold = 0;
		 * } else if (smoothingValue === "basic") {
		 * 	smoothingThreshold = 10;
		 * 	smoothingCountThreshold = 5;
		 * } else if (smoothingValue === "very") {
		 * 	smoothingThreshold = 5;
		 * 	smoothingCountThreshold = 10;
		 * }
		 */

		/*
		 * function noteIsSimilarEnough() {
		 * 	if (typeof valueToDisplay === "number") {
		 * 		return (
		 * 			Math.abs(valueToDisplay - previousValueToDisplay) <=
		 * 			smoothingThreshold
		 * 		);
		 * 	} else {
		 * 		return valueToDisplay === previousValueToDisplay;
		 * 	}
		 * }
		 */

		/*
		 * if (noteIsSimilarEnough()) {
		 * 	if (smoothingCount < smoothingCountThreshold) {
		 * 		smoothingCount++;
		 * 		return;
		 * 	} else {
		 * 		smoothingCount = 0;
		 * 		previousValueToDisplay = valueToDisplay;
		 * 	}
		 * } else {
		 * 	previousValueToDisplay = valueToDisplay;
		 * 	smoothingCount = 0;
		 * 	return;
		 * }
		 */

		if (typeof valueToDisplay === "number") {

			dispatch({
				type   : "SET_STATE",
				payload: {
					currentHz  : valueToDisplay,
					currentNote: valueToDisplay
				}
			});
			/*
			 * setCurrentHz(valueToDisplay);
			 * setCurrentNote();
			 */

		} else {

			dispatch({
				type   : "SET_STATE",
				payload: {
					currentNote: valueToDisplay,
					currentHz  : Math.round(autoCorrelateValue)
				}
			});

			/*
			 * setCurrentHz();
			 * setCurrentNote(valueToDisplay);
			 */

		}

		setNotesSequence((prev: any) => {

			return [ ...prev, valueToDisplay ];

		});

		// console.info(valueToDisplay);

		/*
		 * Update current note and Hz values
		 * setCurrentNote(valueToDisplay);
		 * setCurrentHz(autoCorrelateValue);
		 */

	};

	useEffect(() => {

		if (isRecording && audioContext && analyser) {

			if (waveType === "sine") {

				visualizeWave();

			} else {

				visualizeFrequencyBars();

			}

		}

	}, [
		isRecording, audioContext, analyser, waveType
	]);

	useEffect(() => {

		if (analyser) {

			const drawNoteInterval = setInterval(drawNote, 500);

			return () => clearInterval(drawNoteInterval);

		}

	}, [ analyser, roundingType ]);

	useEffect(() => {

		if (analyser) {

			if (biquadFilter) {

				biquadFilter.Q.setTargetAtTime(state.q, audioContext.currentTime, 0.01);
				biquadFilter.frequency.setTargetAtTime(state.freq, audioContext.currentTime, 0.01);
				// biquadFilter.gain.setTargetAtTime(state.gain, audioContext.currentTime, 0.01);
				biquadFilter.gain.value = state.gain;

			}

		}

	}, [
		state.q, state.gain, state.freq, analyser, biquadFilter
	]);

	useEffect(() => {

		if (analyser) {

			if (biquadFilter) {

				biquadFilter.type = filterType;

			}

		}

	}, [
		filterType, analyser, biquadFilter
	]);

	console.info(notesSequence);

	return (
		<div className="container">
			<Row >
				<Col className="poc-title-col">
					<TooltipComponent text="Back to Dashboard" sx={{ fontWeight: "bold" }} placement="top">
						<FaChevronLeft onClick={() => {

							navigate(config.routes.course);

						}} style={{ color: theme.palette.primary.main, marginRight: "10px", width: "12px", cursor: "pointer" }} />
					</TooltipComponent>
					Sound Analyzer
				</Col>
			</Row>
			<hr />
			<Grid fluid>
				<Row
					// style={{ alignItems: "center" }}
					gutter={50}
				>
					<Col xs={12}>
						<div>
							<table className="controls">
								<tr>
									<td className="controls-cat">
										<label htmlFor="waveType">Select Input Device:</label>
									</td>
									<td className="controls-val">
										<div>
											<Select
												options={audioInputDevices}
												value={selectedDeviceId?.label}
												handleChange={(event: any) => {

													setSelectedDeviceId(event);

												}}
											/>
										</div>
									</td>
								</tr>
								<br />
								<tr>
									<td className="controls-cat">
										<label htmlFor="waveType">Wave type:</label>
									</td>
									<td className="controls-val">
										<Select
											options={config?.waveTypeOptions}
											value={waveType}
											handleChange={(event: any) => {

												dispatch({
													type   : "SET_STATE",
													payload: { waveType: event?.value }
												});

											}
											}
										/>
									</td>
								</tr>
								<br />
								<tr>
									<td className="controls-cat">
										<label htmlFor="roundingType">Rounding type:</label>
									</td>
									<td className="controls-val">
										<Select
											options={config?.roundingTypeOptions}
											value={roundingType}
											handleChange={(event: any) => {

												dispatch({
													type   : "SET_STATE",
													payload: { roundingType: event?.value }
												});

											}
											}
										/>
									</td>
								</tr>
								<br />
								<tr>
									<td className="controls-cat">
										<label htmlFor="smoothingType">Smoothing type:</label>
									</td>
									<td className="controls-val">
										<Select
											options={config?.smoothingTypeOptions}
											value={smoothingType}
											handleChange={(event: any) => {

												dispatch({
													type   : "SET_STATE",
													payload: { smoothingType: event?.value }
												});

											}
											}
										/>
									</td>
								</tr>
								<br />
								<tr>
									<td className="controls-cat">
										<label htmlFor="smoothingType">Filter Type:</label>
									</td>
									<td className="controls-val">
										<Select
											options={config?.filterTypeOptions}
											value={filterType}
											handleChange={(event: any) => {

												dispatch({
													type   : "SET_STATE",
													payload: { filterType: event?.value }
												});

											}
											}
										/>
									</td>
								</tr>
								<br />
								<tr>
									<td className="controls-cat">
										<label htmlFor="smoothingType">Q:</label>
									</td>
									<td className="controls-val">
										<input type="range" id="q-slider" min="0" max="5" step="0.1" value={state.q} onChange={
											e => {

												dispatch({
													type   : "SET_STATE",
													payload: { q: e.target.value }
												});

											}

										} />
										<span style={{ marginLeft: "10px" }}>{state.q}</span>
									</td>
								</tr>
								<br />
								<tr>
									<td className="controls-cat">
										<label htmlFor="smoothingType">Gain:</label>
									</td>
									<td className="controls-val">
										<input type="range" id="gain-slider" min="0" max="100" step="5" value={state.gain} onChange={
											e => {

												dispatch({
													type   : "SET_STATE",
													payload: { gain: e.target.value }
												});

											}
										} />
										<span style={{ marginLeft: "10px" }}>{state.gain}</span>
									</td>
								</tr>
								<br />
								<tr>
									<td className="controls-cat">
										<label htmlFor="smoothingType">Frequency:</label>
									</td>
									<td className="controls-val">
										<input type="range" id="freq-slider" min="100" max="5000" step="50" value={state.freq} onChange={
											e => {

												dispatch({
													type   : "SET_STATE",
													payload: { freq: e.target.value }
												});

											}
										} />
										<span style={{ marginLeft: "10px" }}>{state.freq}</span>
									</td>
								</tr>
							</table>
						</div>
						<div
							className="poc-btn-con "
						>
							<Button
								variant="contained"
								onClick={startRecording}
								sx={{ color: theme.palette.primary.light }}
							>
								{isRecording ? "Stop" : "Start"}
							</Button>&nbsp;
							<Button
								variant="contained"
								sx={{ color: theme.palette.primary.light }}
								onClick={() => {

									stopRecording();
									setNotesSequence([]);
									dispatch({
										type   : "SET_STATE",
										payload: {
											currentNote: "No note detected",
											currentHz  : ""
										}
									});

								}}
							>Reset</Button>
						</div>
					</Col>
					<Col xs={12}>
						<div className="current-values">
							<table className="current-values-table">
								<tr>
									<td className="current-value-table-cat">Current note:</td>
									<td className="current-value-table-val">{currentNote}</td>
								</tr>
								<tr>
									<td className="current-value-table-cat">Hz:</td>
									<td className="current-value-table-val">{currentHz}</td>
								</tr>
							</table>
						</div>
						<canvas className="visualizer"></canvas>
						<div>
							{
								notesSequence.length > 0 ?
									<div>
										<h3>Notes Sequence:</h3>
										<div>
											{
												notesSequence.map((note: any, index: any) => {

													return (
														<span key={index} style={{ marginRight: "10px" }}>{note}</span>
													);

												})
											}
										</div>
										{/* <Button onClick={extractRhythm}>Extract Rhythm</Button> */}
									</div>
									: null
							}
						</div>
					</Col>
				</Row>
			</Grid>
		</div>
	);

};

export default SoundAnalyzer;
