import React from "react";
import { useTheme } from "@mui/material/styles";
import { Box } from "@mui/material";
import { Loader } from "rsuite";


const CustomLoader = () => {

	const theme: any = useTheme();

	return (
		<Box
			sx={{
				backgroundColor: theme?.palette?.background?.default
			}}
		>
			<Loader
				inverse={true}
				
				style={{
					backgroundColor: theme?.palette?.background?.default
				}} size="md" backdrop={false} center={true} speed="slow" />
		</Box>
	);

};

export default CustomLoader;