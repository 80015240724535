import { Container, Typography, useTheme, Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Col, Row } from "rsuite";
import Back from "../../../components/UI/Back";
import config from "../../../config/appConfig";
import Form from "react-bootstrap/Form";
import { useLocation } from "react-router-dom";
import ClearIcon from "@mui/icons-material/Clear";
import { useNavigate } from "react-router-dom";
import Select from "../../../components/UI/Select";
import ApiRequest from "../../../api/ApiRequest";
import { APIUrl } from "../../../api/endPoints";

const EditCourse = () => {

	const theme = useTheme();
	const navigate = useNavigate();
	const location = useLocation();
	const state = location.state?.courseData ? JSON.parse(location.state.courseData) : null;

	const [ courseName, setCourseName ] = useState("");
	const [ courseDescription, setCourseDescription ] = useState("");
	const [ moduleCount, setModuleCount ] = useState("");
	const [ totalHours, setTotalHours ] = useState("");
	const [ totalLesson, setTotalLesson ] = useState("");
	const [ skillLevel, setSkillLevel ] = useState<string>("");
	const [ skillLevelArray, setSkillLevelArray ] = useState<string[]>([]);
	const [ selectedModule, setSelectedModule ] = useState<any>(null);


	useEffect(() => {
		

		if(!location.state) return;
		 
		setCourseName(state.courseName || "");
		setCourseDescription(state.courseDesc || "");
		setTotalLesson(state.lessonCount || "");
		setTotalHours(state.totalHours || "");
		setSkillLevelArray(state.skillLevel);

		const moduleOptions = state.moduleDetails?.map((module: any) => ({
			label: module.moduleName,
			value: module._id
		})) || [];
	
		setModuleCount(moduleOptions);
		setSelectedModule(moduleOptions);
	

	}, [ location ]);


	const handleKeyDown = (e: any) => {

		if (e.key === "Enter" && skillLevel.trim()) {

			e.preventDefault();
			setSkillLevelArray([ ...skillLevelArray, skillLevel.trim() ]);
			setSkillLevel("");

		}

	};

	const handleCancel = () => {

		navigate(config.routes.listCourse);

	};
	const handleDelete = (item: any) => () => {

		setSkillLevelArray((items: any) => items.filter((values: any, index: number) => index !== item));

	};

	const handleCourse = (e:any) => {

		const newValue = e.target.value;
		setCourseName(newValue);
	
	};

	const handleUpdateCourse = () => {

		const payload = {
			"courseDoc": {
				"_id"       : state._id,
				"courseName": courseName,
				"courseDesc": courseDescription,
				"skillLevel": skillLevelArray
			}
		};

		ApiRequest("post", APIUrl.updateCourse, payload).then(() => {

			navigate(config.routes.listCourse);

		}).catch((error: any) => {
			
			console.error(error);

		});

	};

	return (
		<>
			<Container>
				<Row>
					<Col md={12} sm={12} xl={12} xs={12} >
						<div className="admin-dash-board-back-title-con" style={{ marginBottom: theme.spacing(2) }}
						>
							<Back
								props={{
									route  : config.routes.listCourse,
									state  : null,
									toolTip: "Back to list Course",
									text   : "Back to list courses"
								}}
							/>
						</div>
					</Col>
				</Row>
				<Row>
					<Col md={12} sm={12} xl={12} xs={12} >
						<div className="admin-dash-board-back-title-con" style={{ marginBottom: theme.spacing(2) }}
						>
							<Typography variant="h2">Edit Course
							</Typography>
						</div>
					</Col>
				</Row>
				<Row
				>
					<Col xs={24}>
						<Row
							className="sh-editcourse-parent-row">
							<Row
								className="sh-list-course-course-typo">
								<Col xs={5}>
									<Typography style={{ color: theme.palette.text.primary }}> Course Name</Typography>
								</Col>
								<Col xs={8}>
									<Form.Control
										id="outlined-basic"
										style={{ backgroundColor: theme.palette.background.paper, color: theme.palette.text.primary }}
										color="secondary"
										placeholder="Enter the Course name"
										value={courseName}
										onChange={handleCourse}
										onFocus={(e: any) => {

											const focusColor: string = theme.palette.secondary.main;

											e.target.style.borderColor = focusColor;
											e.target.style.borderWidth = "1.5px";
											e.target.style.boxShadow = `rgba(0, 0, 0, 0.16) 0px 1px 0px, ${focusColor} 0px 0px 0px 0.3px`;

										}}
										onBlur={(e: any) => {

											e.target.style.borderColor = "";
											e.target.style.boxShadow = "";

										}}

									/>
								</Col>

							</Row>
							<Row
								className="sh-list-course-course-description">

								<Col xs={5}>

									<Typography style={{ color: theme.palette.text.primary }} >
											Enter the Course Description
									</Typography>
								</Col>
								<Col xs={8}>
									<Form.Control as="textarea" rows={5} placeholder="Enter Module Description"
										className="sh-user-basic-edit-label-div sh-add-module-font-size"
										value={courseDescription}
										style={{ backgroundColor: theme.palette.background.paper, color: theme.palette.text.primary }}

										onChange={(e: any) => setCourseDescription(e.target.value)}
										onFocus={(e: any) => {

											const focusColor: string = theme.palette.secondary.main;

											e.target.style.borderColor = focusColor;
											e.target.style.borderWidth = "1.5px";
											e.target.style.boxShadow = `rgba(0, 0, 0, 0.16) 0px 1px 0px, ${focusColor} 0px 0px 0px 0.3px`;

										}}
										onBlur={(e: any) => {

											e.target.style.borderColor = "";
											e.target.style.boxShadow = "";

										}}
									/>
								</Col>


							</Row>
							<Row
								className="sh-list-course-course-module"
							>
								<Col xs={5} >
									<Typography style={{ color: theme.palette.text.primary }}>
										Module Name
									</Typography>
								</Col>
								<Col xs={8}>
									<Select
										options={moduleCount}
										isMulti
										minHeight={100}
										value={selectedModule}
										isDisabled={true}
										handleChange={(selectedOption: any) => setSelectedModule(selectedOption)}
									/>
								</Col>
							</Row>
							<Row
								className="sh-list-course-course-module"
							>
								<Col xs={5} >
									<Typography style={{ color: theme.palette.text.primary }}>
								Total Lessons
									</Typography>
								</Col>
								<Col xs={8}>
									<Form.Control
										id="outlined-basic"
										style={{ backgroundColor: theme.palette.background.paper , color: theme.palette.grey[500] }}
										disabled={true}
										className="custom-placeholder"
										color="secondary"
										placeholder="Enter the Course name"
										value={totalLesson}
										onChange={(e:any) => setTotalLesson(e.target.value)}
										onFocus={(e: any) => {

											const focusColor: string = theme.palette.secondary.main;

											e.target.style.borderColor = focusColor;
											e.target.style.borderWidth = "1.5px";
											e.target.style.boxShadow = `rgba(0, 0, 0, 0.16) 0px 1px 0px, ${focusColor} 0px 0px 0px 0.3px`;

										}}
										onBlur={(e: any) => {

											e.target.style.borderColor = "";
											e.target.style.boxShadow = "";

										}}
									/>
								</Col>
								<style>
									{`
									.custom-placeholder::placeholder {
										color:${theme.palette.text.primary} !important;
										opacity: 1;
										font-size: 14px;
																	 }
									`}
								</style>
							</Row>
							<Row
								className="sh-list-course-course-module">
								<Col xs={5}>
									<Typography style={{ color: theme.palette.text.primary }} >Total Hours </Typography>
								</Col>
								<Col xs={8}>
									<Form.Control
										id="outlined-basic"
										color="secondary"
										placeholder="Enter the Course name"
										style={{ backgroundColor: theme.palette.background.paper, color: theme.palette.grey[500] }}
										value={totalHours}
										onChange={(e: any) => setTotalHours(e.target.value)}
										disabled
										onFocus={(e: any) => {

											const focusColor: string = theme.palette.secondary.main;

											e.target.style.borderColor = focusColor;
											e.target.style.borderWidth = "1.5px";
											e.target.style.boxShadow = `rgba(0, 0, 0, 0.16) 0px 1px 0px, ${focusColor} 0px 0px 0px 0.3px`;

										}}
										onBlur={(e: any) => {

											e.target.style.borderColor = "";
											e.target.style.boxShadow = "";

										}}
									/>
								</Col>

							</Row>
							<Row
								className="sh-list-course-course-module">
								<Col xs={5}>
									<Typography style={{ color: theme.palette.text.primary }}>Skill Level</Typography>
							
								</Col>
								<Col xs={8}>
									<Form.Control
										style={{ backgroundColor: theme.palette.background.paper, color: theme.palette.text.primary }}
										value={skillLevel}
										onChange={(e: any) => setSkillLevel(e.target.value)}
										onKeyDown={handleKeyDown}
										onFocus={(e: any) => {

											const focusColor: string = theme.palette.secondary.main;

											e.target.style.borderColor = focusColor;
											e.target.style.borderWidth = "1.5px";
											e.target.style.boxShadow = `rgba(0, 0, 0, 0.16) 0px 1px 0px, ${focusColor} 0px 0px 0px 0.3px`;

										}}
										onBlur={(e: any) => {

											e.target.style.borderColor = "";
											e.target.style.boxShadow = "";

										}}
									/>
									<div>
										{
											skillLevelArray.length > 0 && Array.isArray(skillLevelArray) &&
									<div>
										{skillLevelArray.map((data, index) =>
											<div key={index} className="sh-add-module-multi-description-chip-con" style={{ backgroundColor: theme.palette.background.default, color: theme.palette.text.primary }}>
												{data}
												<ClearIcon className="sh-add-module-multi-description-chip-close-icon" style={{ color: theme.palette.text.primary }} onClick={handleDelete(index)} />
											</div>
										)}
									</div>
										}
									</div>
								</Col>
							</Row>
							<Row className="sh-add-module-add-cancel-btn-con">

								<Col>
									<Button
										className="sh-add-module-add-module-btn sh-add-edit-course-cancel-btn sh-module-intro-enroll-now-btn sh-add-cancel-margin"
										color="secondary"
										style={{ color: theme.palette.text.primary, borderColor: theme.palette.secondary.main }}
										onClick={handleCancel}
									>
								Cancel
									</Button>
								</Col>
								<Col>

									<Button
										className="sh-add-module-add-module-btn sh-module-intro-enroll-now-btn"
										variant="contained"
										color="secondary"
										onClick={() => {

											handleUpdateCourse();

										}
										}
									>
								Update
									</Button>

								</Col>
							</Row>

						</Row>
					
					</Col>
					
					
				</Row>


			</Container>
		</>
	);

};

export default EditCourse;
