import LAYOUT_CONST from "../constant";

export const BASE_PATH = "";

export const DASHBOARD_PATH = "/course";
export const HORIZONTAL_MAX_ITEM = 6;

const config = {
// vertical, horizontal
	layout        : LAYOUT_CONST.VERTICAL_LAYOUT,
	// default, mini-drawer
	drawerType    : LAYOUT_CONST.DEFAULT_DRAWER,
	fontFamily    : "Poppins",
	borderRadius  : 8,
	outlinedFilled: true,
	// light, dark
	navType       : "dark",
	// default, theme1, theme2, theme3, theme4, theme5, theme6
	presetColor   : "theme7",
	// 'en' - English, 'fr' - French, 'ro' - Romanian, 'zh' - Chinese
	locale        : "en",
	rtlLayout     : false,
	container     : false,
	sideBarRoutes : {
		poc: "/sound-analysis"
	},
	pageNameWithContentName: {
		"POC": "sound-analysis"
	}
};

export default config;
