import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useAuth from "../../Hooks/useAuth";
import config from "../../config/appConfig";

const GuestMode = ({ children }: any) => {

	const navigate = useNavigate();
	const { isLoggedIn, users }: any = useAuth();
	const location = useLocation();
	const pathName = location.pathname;

	useEffect(() => {

		if (isLoggedIn || pathName === config.routes.announcement) {

			const lastPage: any = sessionStorage.getItem("previousPage");

			if (lastPage && lastPage?.pathName) {
				
				navigate(lastPage.pathName, { replace: true });

				return;

			}

		}

		if (users?.emailVerified === false) {

			navigate(config.routes.login, { replace: true });

			return;

		}


	}, [ users, isLoggedIn ]);

	return children || null;

};

export default GuestMode;
