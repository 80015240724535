import React, { useState } from "react";
import { Rating, Box, Typography, useTheme, TextField } from "@mui/material";
import StarIcon from "@mui/icons-material/Star";

const RatingComponent = ({
	setIsFeedBackModalOpen,
	isToOpen,
	value,
	setValue,
	feedback,
	setFeedback
}: any) => {

	const theme: any = useTheme();
	const [ hover, setHover ] = useState(-1);


	const labels: { [index: number]: any } = {
		0.5: <Typography sx={{ color: theme.palette.error.dark, fontWeight: "bold" }}>Useless</Typography>,
		1  : <Typography sx={{ color: theme.palette.error.dark, fontWeight: "bold" }}>Useless+</Typography>,
		1.5: <Typography sx={{ color: theme.palette.error.light, fontWeight: "bold" }}>Poor</Typography>,
		2  : <Typography sx={{ color: theme.palette.error.light, fontWeight: "bold" }}>Poor+</Typography>,
		2.5: <Typography sx={{ color: theme.palette.text.secondary, fontWeight: "bold" }}>Ok</Typography>,
		3  : <Typography sx={{ color: theme.palette.text.secondary, fontWeight: "bold" }}>Ok+</Typography>,
		3.5: <Typography sx={{ color: theme.palette.success[200], fontWeight: "bold" }}>Good</Typography>,
		4  : <Typography sx={{ color: theme.palette.success[200], fontWeight: "bold" }}>Good+</Typography>,
		4.5: <Typography sx={{ color: theme.palette.success.main, fontWeight: "bold" }}>Excellent</Typography>,
		5  : <Typography sx={{ color: theme.palette.success.main, fontWeight: "bold" }}>Excellent+</Typography>
	};

	function getLabelText(value: number) {

		return `${value} Star${value !== 1 ? "s" : ""}, ${labels[value]}`;
	
	}

	return (
		<div>

			<Box
				sx={{
					width     : 200,
					display   : "flex",
					alignItems: "center"
				}}
			>
				{
					isToOpen ? <>
						<Rating
							name="hover-feedback"
							value={value ? value : 5}
							precision={0.5}
							getLabelText={getLabelText}
							onChange={() => {

								if (isToOpen) {
									
									setIsFeedBackModalOpen(true);
									
									return;
									
								}
								
				
							}}
							emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
						/>
						{value !== null &&
        <Box sx={{ ml: 2 }}>{labels[hover !== -1 ? hover : value]}</Box>
						}
					</> : <>
						<Rating
							name="hover-feedback"
							value={value}
							precision={0.5}
							getLabelText={getLabelText}
							onChange={(event, newValue) => {

								if (isToOpen) {
									
									setIsFeedBackModalOpen(true);
									
									return;
									
								}
								setValue(newValue);
				
							}}
							onChangeActive={(event, newHover) => {

								if (isToOpen) return;

								event.preventDefault();

								setHover(newHover);
				
							}}
							emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
						/>
						{value !== null &&
						<Box sx={{ ml: 2 }}>{labels[hover !== -1 ? hover : value]}</Box>
						}
					</>
				}
				
			</Box>
			{
				!isToOpen && <>
					<Typography sx={{ mt: 2, mb: 1, color: theme.palette.text.secondary, fontWeight: "bold" }}>Your feedback is important to us.</Typography>
					<TextField
						label="Feedback"
						value={feedback}
						onChange={(event: any) => setFeedback(event.target.value)}
						multiline
						rows={4}
						variant="outlined"
						fullWidth
						sx={{ mt: 2 }}
					/>
				</>
			}
			
		</div>
	);
  

};

export default RatingComponent;