import React from "react";
import { useTheme } from "@mui/material/styles";
import Select, { createFilter } from "react-select";

const CustomSelect = (props: any) => {

	const themePallet: any = useTheme();

	return (
		<>
			{
				props.label && <label
					htmlFor={props.name}
					style={{
						color       : themePallet?.palette?.text.primary,
						fontSize    : props.labelfontsize,
						marginBottom: "5px"
					}}
				>
					{props.label}
				</label>
			}
			<Select
				isMulti={props.isMulti}
				className={props.className}
				isDisabled={props.isDisabled}
				isClearable={props.isClearable}
				value={props.value || null}
				onChange={props.handleChange}
				options={props.options}
				placeholder={props.placeholder ? props.placeholder : "Select..."}
				maxMenuHeight={props.maxMenuHeight}
				isSearchable={props.isSearchable}
				inputValue={props.inputValue}
				onInputChange={props.onInputChange}
				filterOption={createFilter({
					matchFrom: "start"
				})}
				styles={{
					control: (styles, state) => ({
						...styles,
						
						borderColor: props.error
							? themePallet.palette.error.main
							: state.isFocused
								? themePallet.palette.secondary.main
								: themePallet.palette.divider,
						backgroundColor: `${themePallet.palette.background.paper} !important`,
						color          : themePallet.palette.text.primary,
						fontSize       : props.fontSize,
						minHeight      : props.minHeight || "40px",
						border         : props.border,
						width          : props.width ? props.width : "100%",
						"&:hover"      : {
							borderColor: themePallet.palette.secondary.main
						},
						"&:focus": {
							borderColor: themePallet.palette.secondary.main
						},
						"&:active": {
							borderColor: themePallet.palette.secondary.main
						},
						"&:disabled": {
							backgroundColor: themePallet.palette.grey[100],
							color          : themePallet.palette.grey[500]
						},
						"&:focus-within": {
							borderColor: themePallet.palette.secondary.main
						}
					}),
					placeholder: styles => ({
						...styles,
						color  : themePallet?.palette?.text?.secondary || "#999",
						opacity: 1
					  }),
					multiValue: styles => ({
						...styles,
						backgroundColor: themePallet.palette.background.default,
						color          : themePallet.palette.text.primary,
						borderRadius   : "4px",
						padding        : "0 4px"
					  }),
					  multiValueLabel: styles => ({
						...styles,
						color: themePallet.palette.text.primary
					  }),
					  multiValueRemove: styles => ({
						...styles,
						color   : themePallet.palette.text.secondary,
						":hover": {
						  backgroundColor: themePallet.palette.error.light,
						  color          : themePallet.palette.error.dark
						}
					  }),
					indicatorsContainer: styles => ({
						...styles,
						padding: "0px 5px"
					}),
					valueContainer: styles => ({
						...styles,
						paddingLeft: "5px"
					}),
					input: styles => ({
						...styles,
						margin : "0px",
						padding: "0px",
						color  : themePallet.palette.text.primary
					}),
					
					menu: styles => ({
						...styles,
						zIndex         : 9999,
						backgroundColor: themePallet?.palette?.background?.paper,
						color          : themePallet?.palette?.text?.primary
					}),
					menuList: styles => ({
						...styles,
						overflowY      : "auto",
						maxHeight      : props.maxMenuHeight || "100px",
						zIndex         : 9999,
						backgroundColor: themePallet.palette.background.paper,
						color          : themePallet.palette.text.primary
					}),

					option: styles => ({
						...styles,
						maxHeight      : props.maxOptionHeight || "100px",
						zIndex         : 9999,
						color          : themePallet.palette.text.primary,
						backgroundColor: themePallet.palette.background.paper,
						cursor         : "pointer",
						"&:hover"      : {
							backgroundColor: themePallet.palette.background.default,
							color          : themePallet.palette.text.secondary
						}

					}),
					singleValue: styles => ({
						...styles,
						color: themePallet.palette.text.primary
					})

				}}
				theme={theme => ({
					...theme,
					borderRadius: 4
					
				})}
				name={props.name}
				isOptionDisabled={option => option.isDisabled}
			/>
		</>
	);

};

export default CustomSelect;
