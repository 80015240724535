import React, { useEffect } from "react";

const PrivacyPolicy = () => {

	useEffect(() => {

		window.scrollTo(0, 0);
	
	}, []);

	return (
		<div style={{ padding: "20px", lineHeight: 2.5 }}>
			<h2><strong>Privacy Policy</strong></h2>
			<p><strong>Last updated: June 01, 2021</strong></p>

			<h3>1. Introduction</h3>
			<p>Welcome to <strong>Violin Technique with Karthick Iyer</strong> (&quot;Company&quot;, &quot;we&quot;, &quot;our&quot;, &quot;us&quot;)!</p>
			<p>Violin Technique with Karthick Iyer (&quot;us&quot;, &quot;we&quot;, or &quot;our&quot;) operates{" "}&nbsp;<a
				target="_blank" href="/" rel="noopener noreferrer"
			>www.soulswara.com</a> (hereinafter referred to as &quot;Service&quot;).</p>
			<p>Our Privacy Policy governs your visit to{" "}&nbsp;<a target="_blank" href="/" rel="noopener noreferrer"> www.soulswara.com</a>, &nbsp;and explains how we collect, safeguard
        and disclose information that results from your use of our Service.</p>
			<p>We use your data to provide and improve Service. By using Service, you agree to the collection and use of
        information in accordance with this policy. Unless otherwise defined in this
        Privacy Policy, the terms used in this Privacy Policy have the same meanings as in our Terms and Conditions.</p>
			<p>Our Terms and Conditions <strong>(&quot;Terms&quot;) </strong> govern all use of our Service and together with the Privacy
        Policy constitutes your agreement with us <strong>(&quot;agreement&quot;)</strong> .</p>

			<h3>2. Definitions</h3>
			<p>Paragraph</p>
			<p><strong>SERVICE</strong> means the &nbsp;&nbsp;<a href="/">www.soulswara.com</a> &nbsp;website operated by Violin Technique with Karthick Iyer.
			</p>
			<p><strong>PERSONAL DATA</strong> means data about a living individual who can be identified from those data (or
        from that and other information either in our possession or likely to come into our
        possession).</p>
			<p> <strong>USAGE DATA</strong> is data collected automatically either generated by the use of Service or from Service infrastructure itself (for example, the duration of a page visit).</p>
			<p><strong>COOKIES</strong> are small files stored on your device (computer or mobile device).</p>
			<p><strong> DATA CONTROLLER</strong> means a natural or legal person who (either alone or jointly or in common with
        other persons) determines the purposes for which and the manner
        in which any personal
        data are, or are to be, processed. For the purpose of this Privacy Policy, we are a Data Controller of your
        data.</p>
			<p><strong>DATA PROCESSORS (OR SERVICE PROVIDERS)</strong> mean any natural or legal person who processes the data
        on behalf of the Data Controller. We may use the services of various
        Service Providers
        in order to process your data more effectively.</p>
			<p><strong>DATA SUBJECT</strong> is any living individual who is the subject of Personal Data.</p>
			<p><strong>THE USER</strong> is the individual using our Service. The User corresponds to the Data Subject, who is
        the subject of Personal Data.</p>

			<h3>3. Information Collection and Use</h3>
			<p>We collect several different types of information for various purposes to provide and improve our Service to you.
			</p>

			<h3>4. Types of Data Collected</h3>
			<h4><strong>Personal Data</strong></h4>
			<p>While using our Service, we may ask you to provide us with certain personally identifiable information that can
        be used to contact or identify you (&quot;Personal Data&quot;). Personally
        identifiable information may include, but is not limited to:</p>
			<ul>
				<li>Email address</li>
				<li>First name and last name</li>
				<li>Phone number</li>
				<li>Address, Country, State, Province, ZIP/Postal code, City</li>
				<li>Cookies and Usage Data</li>
			</ul>
			<p>We may use your Personal Data to contact you with newsletters, marketing or promotional materials and other
            information that may be of interest to you. You may opt out of
            receiving any, or all, of these communications from us by following the unsubscribe link.</p>

			<h4><strong>Usage Data</strong></h4>
			<p>
				<p>We may also collect information that your browser sends whenever you visit our Service or when you access
            Service by or through any device <strong>(&quot;Usage Data&quot;)</strong>.</p>
				<p>This Usage Data may include information such as your computer’s Internet Protocol address (e.g. IP address),
            browser type, browser version, the pages of our Service that you visit, the time and date of your visit, the
            time spent on those pages, unique device identifiers and other diagnostic data.</p>

				<p>When you access Service with a device, this Usage Data may include information such as the type of device
            you use, your device unique ID, the IP address of your device, your device operating system, the type of
            Internet browser you use, unique device identifiers and other diagnostic data.</p>
			</p>


			<h4><strong>Location Data</strong></h4>
			<p>We may use and store information about your location if you give us permission to do so (“Location Data”). We
            use this data to provide features of our Service, to improve and customize
            our Service.</p>
			<p>You can enable or disable location services when you use our Service at any time by way of your device
            settings.</p>

			<h4><strong>Tracking Cookies Data</strong></h4>
			<p>We use cookies and similar tracking technologies to track the activity on our Service and we hold certain
            information.</p>
			<p>Cookies are files with a small amount of data which may include an anonymous unique identifier. Cookies are
            sent to your browser from a website and stored on your device.
            Other tracking technologies are also used such as beacons, tags and scripts to collect and track information
            and to improve and analyze our Service.</p>
			<p>You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if
            you do not accept cookies, you may not be
            able to use some portions of our Service.</p>
			<p>Examples of Cookies we use:</p>
			<ul>
				<li>Session Cookies: We use Session Cookies to operate our Service.</li>
				<li>Preference Cookies: We use Preference Cookies to remember your preferences and various settings.</li>
				<li>Security Cookies: We use Security Cookies for security purposes.</li>
				<li>Advertising Cookies: Advertising Cookies are used to serve you with advertisements that may be relevant
                to you and your interests.</li>
			</ul>

			<h3>5. Use of Data</h3>
			<p>Violin Technique with Karthick Iyer uses the collected data for various purposes:</p>
			<p>At the end of each Billing Cycle, your subscription/non-subscription payment will automatically renew under
            the exact same conditions unless you cancel it or Violin Technique with Karthick Iyer cancels it. You may
            cancel your subscription/non-subscription payment renewal either through your online account management page
            or by contacting{" "}&nbsp;<a href="mailto:violintechnique.ki@gmail.com" >violintechnique.ki@gmail.com</a> &nbsp;customer support team.</p>
			<ul>
				<li>to provide and maintain our Service;</li>
				<li>to notify you about changes to our Service;</li>
				<li>to allow you to participate in interactive features of our Service when you choose to do so;</li>
				<li>to provide customer support;</li>
				<li>to gather analysis or valuable information so that we can improve our Service;</li>
				<li>to monitor the usage of our Service;</li>
				<li>to detect, prevent and address technical issues;</li>
				<li>to fulfil any other purpose for which you provide it;</li>
				<li>to carry out our obligations and enforce our rights arising from any contracts entered into between you
                and us, including for billing and collection;</li>
				<li>to provide you with notices about your account and/or subscription, including expiration and renewal
                    notices, email-instructions, etc.;</li>
				<li>to provide you with news, special offers and general information about other goods, services and
                    events which we offer that are similar to those that
                    you have already purchased or enquired about unless you have opted not to receive such information;
				</li>
				<li>in any other way we may describe when you provide the information;</li>
				<li>for any other purpose with your consent.</li>
			</ul>
			<p>Should automatic billing fail to occur for any reason, Violin Technique with Karthick Iyer reserves the
            right to terminate your access to the Service with immediate effect.</p>

			<h3>6. Retention of Data</h3>
			<p>We will retain your Personal Data only for as long as is necessary for the purposes set out in this Privacy
            Policy. We will retain and use
            your Personal Data to the extent necessary to comply with our legal obligations (for example, if we are
            required to retain your data to comply with applicable laws),
            resolve disputes, and enforce our legal agreements and policies.</p>
			<p>We will also retain Usage Data for internal analysis purposes. Usage Data is generally retained for a
            shorter period, except when this data is used to strengthen the security or to
            improve the functionality of our Service, or we are legally obligated to retain this data for longer
            time periods.</p>

			<h3>7. Transfer of Data</h3>
			<p>Your information, including Personal Data, may be transferred to – and maintained on – computers located
            outside of your state, province,
            country or other governmental jurisdiction where the data protection laws may differ from those of your
            jurisdiction.</p>
			<p>Your consent to this Privacy Policy followed by your submission of such information represents your agreement
            to that transfer.</p>
			<p>Violin Technique with Karthick Iyer will take all the steps reasonably necessary to ensure that your data is
            treated securely and in accordance with this Privacy Policy and no transfer of your Personal Data will take
            place to an organisation or a country unless there are adequate controls in place including the security of
            your data and other personal information.</p>

			<h3>8. Disclosure of Data</h3>
			<p>We may disclose personal information that we collect, or you provide:</p>
			<ul>
				<li>
					<h4><strong>Disclosure for Law Enforcement</strong></h4>
				</li>
				<p>Under certain circumstances, we may be required to disclose your Personal Data if required to do so
                by law or in response to valid requests by public authorities.</p>
				<li>
					<h4><strong>Business Transaction</strong></h4>
				</li>
				<p>If we or our subsidiaries are involved in a merger, acquisition or asset sale, your Personal Data may
                be transferred.</p>
				<li>
					<h4><strong>Other cases.We may disclose your information also:</strong></h4>
				</li>


				<ul>
					<li>to our subsidiaries and affiliates;</li>
					<li>to contractors, service providers, and other third parties we use to support our business;</li>
					<li>to fulfil the purpose for which you provide it;</li>
					<li>for the purpose of including your company’s logo on our website;</li>
					<li>for any other purpose disclosed by us when you provide the information;</li>
					<li>with your consent in any other cases;</li>
					<li>if we believe disclosure is necessary or appropriate to protect the rights, property, or safety
                    of the Company, our customers, or others.</li>
				</ul>
			</ul>

			<h3>9. Security of Data</h3>
			<p>The security of your data is important to us but remember that no method of transmission over the
            Internet or method of electronic storage is 100% secure. While we strive to use
            commercially acceptable means to protect your Personal Data, we cannot guarantee its absolute
            security.</p>
			<p>In certain circumstances, you have the following data protection rights:</p>
			<ul>
				<li>The right to access, update or to delete the information we have on you;</li>
				<li>The right of rectification. You have the right to have your information rectified if that information is
                inaccurate or incomplete;</li>
				<li>The right to object. You have the right to object to our processing of your Personal Data;</li>
				<li>The right of restriction. You have the right to request that we restrict the processing of your personal
                information;</li>
				<li>The right to data portability. You have the right to be provided with a copy of your Personal Data in a
                structured, machine-readable and commonly used format;</li>
				<li>The right to withdraw consent. You also have the right to withdraw your consent at any time where we
                rely on your consent to process your personal information;</li>


			</ul>
			<p>Please note that we may ask you to verify your identity before responding to such requests. Please note, we
            may not be able to provide Service without some necessary data.</p>
			<p>Our Policy on &quot;Do Not Track&quot; Signals: </p>
			<p> We honor Do Not Track signals and do not track, plant cookies, or use advertising when a Do Not Track
            browser mechanism is in place. Do Not Track is a preference you can set in your web browser to inform
            websites that you do not want to be tracked.</p>
			<p>You can enable or disable Do Not Track by visiting the Preferences or Settings page of your web browser.</p>


			<h3>10. Service Providers</h3>
			<p>We may employ third party companies and individuals to facilitate our Service (“Service Providers”),
            provide Service on our behalf, perform Service-related services or
            assist us in analysing how our Service is used.</p>
			<p>These third parties have access to your Personal Data only to perform these tasks on our behalf and
            are obligated not to disclose or use it for any other purpose.</p>

			<h3>11. Analytics</h3>
			<p>We may use third-party Service Providers to monitor and analyze the use of our Service.</p>

			<h3>12. CI/CD tools</h3>
			<p>We may use third-party Service Providers to automate the development process of our Service.</p>
			<p>Consistent with the Children&apos;s Online Privacy Protection Act (&quot;COPPA&quot;), Violin Technique with Karthick Iyer
            does not solicit personal information from children. Visitors 13 years of age and under should remember that
            they are required to obtain an adult&apos;s permission before submitting any personal information to this, or any
            other, website.</p>

			<h3>13. Behavioural Remarketing</h3>
			<p>We may use remarketing services to advertise on third party websites to you after you visited our
            Service. We and our third-party vendors use cookies to inform, optimise and
            serve ads based on your past visits to our Service.</p>

			<h3>14. Payments</h3>
			<p>We may provide paid products and/or services within Service. In that case, we use third-party services for
            payment processing (e.g. payment processors).</p>
			<p>We will not store or collect your payment card details. That information is provided directly to our
            third-party payment processors whose use of your personal information is governed by their Privacy Policy.
            These payment processors adhere to the standards set by PCI-DSS as managed by the PCI Security Standards
            Council, which is a joint effort of brands like Visa, Mastercard, American Express and Discover. PCI-DSS
            requirements help ensure the secure handling of payment information.</p>

			<h3>15. Links to Other Sites</h3>
			<p>Our Service may contain links to other sites that are not operated by us. If you click a third party link,
            you will be directed to that third party’s site. We strongly advise you to review the Privacy Policy of
            every site you visit.</p>
			<p>We have no control over and assume no responsibility for the content, privacy policies or practices of any
            third party sites or services.</p>
			<h3>16. Children&apos;s Privacy</h3>
			<p><strong>If you are under the age of 13, your parent or guardian&apos;s consent is required before you can provide
                any personal information to us for purposes of
                registration and/or other online activities.</strong></p>
			<p>If we ask you to register with the Web site, we may ask for personal information such as your full name and
            e-mail address, and mailing address. Violin Technique with Karthick
            Iyer may use such information to periodically contact you with news, and relevant announcements. Violin
            Technique with Karthick Iyer may sponsor sweepstakes, contests or other
            events (&quot;Promotions&quot;) which require the collection, processing, and storage of individual contact
            information plus demographic information. If you want to join in on our Promotions,
            we may ask you to provide an e-mail address, so that we can let you know if you won a prize. For other
            Violin Technique with Karthick Iyer promotions, we may request your name and
            home address in order to send you products or information by regular mail.</p>
			<p>Consistent with the Children&apos;s Online Privacy Protection Act (&quot;COPPA&quot;), Violin Technique with Karthick Iyer
            does not solicit personal information from children. Visitors 13 years of age and under should remember that
            they are required to obtain an adult&apos;s permission before submitting any personal information to this, or any
            other, website.</p>
			<h3>17. Changes to This Privacy Policy</h3>
			<p>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the
            new Privacy Policy on this page.</p>
			<p>We will let you know via email and/or a prominent notice on our Service, prior to the change becoming
            effective and update “effective date” at the top of this Privacy Policy.</p>
			<p>You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy
            Policy are effective when they are posted on this page.</p>

			<h3>18. Opt Out</h3>
			<p>If you do not want Violin Technique with Karthick Iyer to send you e-mail or regular mail about our
            products and/or services, you can choose to opt-out and have your name removed from
            Violin Technique with Karthick Iyer&apos;s e-mailing list by using the link included with a Violin
            Technique with Karthick Iyer e-mail or by sending an e-mail to{" "}&nbsp;<a href="mailto:violintechnique.ki@gmail.com">violintechnique.ki@gmail.com</a>.&nbsp;
			Violin Technique with Karthick Iyer will occasionally send
            email
            announcing changes or enhancements to our site or services.
            If you do not wish to receive these announcements, please notify{" "}&nbsp;<a
				href="mailto:violintechnique.ki@gmail.com">violintechnique.ki@gmail.com</a>.&nbsp;
			</p>
			<p>Students enrolled in a course or program with Violin Technique with Karthick Iyer are automatically
            opted in to receive all email communications. Most
            communication between Violin Technique with Karthick Iyer and its student body is conducted in this
            manner.</p>
			<ul>
				<li>The various emails keep students up to date on:</li>
				<li>Changes to existing courses and programs</li>
				<li>New courses and programs</li>
				<li>Special deals and offers from our industry partners only offered to our students!</li>
				<li>Other important announcements</li>
			</ul>
			<p>In order to ensure all students are kept up to date on the happenings of the online school, we
                need to be able to communicate through email.</p>
			<h3>19. Contact Us</h3>
			<p>If you have any questions about this Privacy Policy, please contact us by email:{" "}&nbsp;<a href="mailto:violintechnique.ki@gmail.com">violintechnique.ki@gmail.com</a>.&nbsp;
			</p>
		</div>
	);

};

export default PrivacyPolicy;

