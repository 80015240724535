import React, { useEffect, useState } from "react";
import Back from "../../../components/UI/Back";

// config import
import config from "../../../config/appConfig";

// material ui and rsuite import
import { Button, Container, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { Col, Row } from "react-bootstrap";
import { Progress } from "rsuite";
import { useTheme, useMediaQuery } from "@mui/material";

// data table import
import DataTable from "react-data-table-component";
import ApiRequest from "../../../api/ApiRequest";
import { APIUrl } from "../../../api/endPoints";
import { useNavigate } from "react-router-dom";
// import DeleteModal from "../../../components/UI/DeleteModal";
import { RiVideoUploadFill } from "react-icons/ri";
import { FaCloudUploadAlt, FaExclamationTriangle, FaPen, FaTrash } from "react-icons/fa";
import { FaRotateRight } from "react-icons/fa6";
import toast from "react-hot-toast";
import TooltipComponent from "../../../components/UI/Tooltip";
import ModalComponent from "../../../components/modal";
import MainButton from "../../../components/UI/extend/MainButton";
import DropZone from "../../../components/UI/DropZone";
import axios from "axios";

const ModuleLists = () => {

	const theme: any = useTheme();
	const matchesXS = useMediaQuery(theme.breakpoints.down("md"));
	const [ data, setData ] = useState<any>([]);
	const [ isListCourseDeleteModelOpen, setIsListCourseDeleteModelOpen ] = useState<boolean>(false);
	const [ deleteModuleName, setDeleteModuleName ] = useState<any>({});
	const [ isLoading, setIsLoading ] = useState<boolean>(false);
	const [ isIntroVideoModelOpen, setIsIntroVideoModelOpen ] = useState<boolean>(false);
	const [ selectedModule, setSelectedModule ] = useState<any>(null);
	const [ isUploadedMediaValid, setIsUploadedMediaValid ] = useState(false);
	const [ uploadPostMediaName, setUploadPostMediaName ] = useState("");
	const [ isFileUploading, setIsFileUploading ] = useState(false);
	const [ isFileLoaded, setIsFileLoaded ] = useState(false);
	const [ fileDetails, setFileDetails ] = useState<any>(null);
	const [ uploadProgress, setUploadProgress ] = useState(0);

	useEffect(() => {

		fetchAllModules();

	}, []);

	const fetchAllModules = () => {

		setIsLoading(true);
		
		ApiRequest("post", APIUrl.getAllModules, {}).then((response: any) => {

			setIsLoading(false);

			setData(response?.data);

		}).catch((error: any) => {

			console.error("Error fetching data", error);

			setIsLoading(false);
			setData([]);

		}
		);
	
	};
	
	// eslint-disable-next-line @typescript-eslint/no-var-requires, no-undef
	const { DataTableStyles } = require("../../../Utils/utils");
	
	const navigate = useNavigate();

	const cellRenderer = (text: any) => {

		const cellText = String(text);

		return <> {
			cellText?.length > 25 ? <>
				<TooltipComponent text={cellText} placement="top-start">
					<div>{cellText.substring(0, 25) + "..."}</div>
				</TooltipComponent>
			</> : cellText
		}
		</>;

	};


	const generalSortFunction = (key: string) => (a: any, b: any) => {

		const valueA = a[key];
		const valueB = b[key];
	  
		// Handle cases where values are not present
		if (valueA === undefined || valueB === undefined) return 0;
	  
		// If both values are numbers
		if (!isNaN(valueA) && !isNaN(valueB)) {

		  return parseFloat(valueA) - parseFloat(valueB);
		
		}
	  
		// Otherwise, treat them as strings
		return valueA.toString().toLowerCase().localeCompare(valueB.toString().toLowerCase());
	  
	};

	const handlePublishModule = (params: any) => {

		let data: any = {};

		if(params?.isPublished == false) {

			const userConfirm = window.confirm("Are you sure you want to publish this Module?");

			if (!userConfirm) return;

			 data = {
				moduleId   : params._id,
				isPublished: true
			};
			
		} else {
			
			const userConfirm = window.confirm("Are you sure you want to un-publish this Module?");

			if (!userConfirm) return;

			data = {
				moduleId   : params._id,
				isPublished: false
			};

		}

		if (!data.moduleId) return;

		ApiRequest("post", APIUrl.publishModule, data).then(() => {

			params?.isPublished == false ? toast.success("Module published successfully!") : toast.success("Module un-published successfully!");
		
			fetchAllModules();

		}).catch((error: any) => {

			params?.isPublished == false ? toast.error("Error publishing module") : toast.error("Error un-publishing module");

			console.error("Error publishing video", error);

		});

	};

	const columns: any = [
		{
			name        : "Order",
			selector    : (row:any) => cellRenderer(row.moduleOrder ? row.moduleOrder : ""),
			cell        : (row:any) => cellRenderer(row.moduleOrder ? row.moduleOrder : ""),
			sortable    : true,
			center      : true,
			width       : "100px",
			sortFunction: generalSortFunction("moduleOrder")
		},
		{
			name        : "Module Name",
			selector    : (row:any) => cellRenderer(row.moduleName ? row.moduleName : ""),
			cell        : (row:any) => cellRenderer(row.moduleName ? row.moduleName : ""),
			sortable    : true,
			grow        : 2,
			// sortFunction: (a:any, b:any) => a.moduleName.toLowerCase().localeCompare(b.name.toLowerCase())
			sortFunction: generalSortFunction("moduleName")
		},
		{
			name        : "Course Name",
			selector    : (row:any) => cellRenderer(row.courseName ? row.courseName : ""),
			cell        : (row:any) => cellRenderer(row.courseName ? row.courseName : ""),
			sortable    : true,
			grow        : 1.5,
			sortFunction: generalSortFunction("courseName")

		},
		{
			name        : "Package Name",
			selector    : (row:any) => cellRenderer(row.packageName ? row.packageName : ""),
			cell        : (row:any) => cellRenderer(row.packageName ? row.packageName : ""),
			sortable    : true,
			grow        : 1.5,
			// sortFunction: (a:any, b:any) => a.moduleName.toLowerCase().localeCompare(b.name.toLowerCase())
			sortFunction: generalSortFunction("packageName")

		},
		{
			name    : "Levels",
			center  : true,
			grow    : 0.5,
			selector: (row:any) => row.level,
			cell    : (row:any) => row.level,
			sortable: true,
			// sortFunction: (a:any, b:any) => {

			// 	return a.level - b.level;
			
			// }
			sortFunction: generalSortFunction("level")
		},
		
		{
			name        : "Published",
			center      : true,
			selector    : (row:any) => row.isPublished ? "Yes" : "No",
			cell        : (row:any) => row.isPublished ? "Yes" : "No",
			sortable    : true,
			sortFunction: generalSortFunction("isPublished")

		},
		{
			name    : "Created At",
			center  : true,
			// selector: (row:any) => row.createdAt ? new Date(row.createdAt).toLocaleString() : "",
			selector: (row: any) => {

				const createdAt = row.createdAt ? new Date(row.createdAt).toLocaleString() : "";
				
				return createdAt ?
				  <TooltipComponent text={createdAt} placement="top">
						<div>{createdAt}</div>
				  </TooltipComponent>
				 : "";
			  
			},
			grow    : 1.5,
			sortable: true
		},
		{
			name    : "Updated At",
			// selector: (row:any) => row.updatedAt ? new Date(row.updatedAt).toLocaleString() : "",
			selector: (row: any) => {

				const updatedAt = row.updatedAt ? new Date(row.updatedAt).toLocaleString() : "";
				
				return updatedAt ?
				  <TooltipComponent text={updatedAt} placement="top">
						<div>{updatedAt}</div>
				  </TooltipComponent>
				 : "";
			  
			},
			center  : true,
			grow    : 1.5,
			sortable: true
		},
		{
			name: "Action",

			center: true,
			cell  : (params: any)=>
				<div className="sh-fontawesome-edit-delete">
                    
					{/* <FontAwesomeIcon icon={} style={{ color: theme.palette.text.primary }} className="sh-fontawesome-edit" onClick={()=> editModule(params)} /> */}
					<TooltipComponent text="Edit Module" placement="top-start" >
						<span
							className="sh-fontawesome-edit"
						>
							<FaPen size={15} onClick={()=> editModule(params)} color={theme.palette.text.primary} />
						</span>
					</TooltipComponent>
					<TooltipComponent text="Delete Module" placement="top-start" >
						<span
							className="sh-fontawesome-edit"
						>
							<FaTrash size={15} onClick={() => {

								setIsListCourseDeleteModelOpen(true);
								setDeleteModuleName(params);
						
							}} color={theme.palette.text.primary} />
						</span>
					</TooltipComponent>
					<TooltipComponent text={params?.introVideo ? "Update Intro Video" : "Upload Intro Video" } placement="top-start"
					>
						<span
							className="sh-fontawesome-edit"
							onClick={() => {

								setIsIntroVideoModelOpen(true);
								setSelectedModule(params);
							
							}
							}
						>
							<RiVideoUploadFill size={15} color={theme.palette.text.primary} />
						</span>
					</TooltipComponent>
					<TooltipComponent text={ !params?.isPublished ? "Click Publish Module" : "Click to un-publish the module" } placement="top-start"
					>
						<span
							className="sh-fontawesome-edit"
							onClick={() => handlePublishModule(params)}
						>
							<FaCloudUploadAlt
								className="sh-fontawesome-edit"
								size={20}
								style= {{ cursor: "pointer" }}
								color={params?.isPublished == false ? theme?.palette?.success.main : theme?.palette?.grey[500]} />
						</span>
					</TooltipComponent>
				</div>,
			width: "100px"
            
		}
		
  
	];

	const handleDelete = () => {

		ApiRequest("post", APIUrl.deleteModule, { moduleId: deleteModuleName._id }).then(() => {

			setIsListCourseDeleteModelOpen(false);
			fetchAllModules();

			toast.success(`Module Name "${deleteModuleName.moduleName}" deleted successfully!`);

		}).catch((error: any) => {

			console.error("Error deleting module", error);
			setIsListCourseDeleteModelOpen(false);

		});

	};

	const editModule = (row: any) => {
		
		navigate(`${config.routes.addModulePage}#edit-module`, { state: row });
	
	};

	const handleRotate = () => {
		
		fetchAllModules();
	
	};

	const postMediaDrop = (acceptedFiles: any) => {

		setIsFileUploading(true);

		if (config?.acceptedVideoFormats.includes(acceptedFiles[0].type)) {

			setIsFileUploading(false);
			setUploadPostMediaName(acceptedFiles[0].name);
			setFileDetails(acceptedFiles[0]);
			setIsFileLoaded(true);
			setIsUploadedMediaValid(true);

		} else {

			setIsFileUploading(false);
			setIsFileLoaded(false);
			console.error("File type not supported");
			setIsUploadedMediaValid(false);
			setUploadPostMediaName("File type not supported");

		}

	};

	const UploadIntroVideoContent = () => {

		return <div>
			<Typography variant="h4">Upload Intro Video</Typography>
			<div>
				<Row className="sh-add-lesson-dropzone">
					<DropZone
						dropMedia={postMediaDrop}
						isUploadedMediaValid={isUploadedMediaValid}
						uploadPostMediaName={uploadPostMediaName}
						acceptFileType={config?.acceptedVideoFormats}
						accept={config.acceptedFilesVideos}
						message={isFileUploading ? "Uploading File" : isFileLoaded ? "Please Click Upload" : "Please Upload File"}
					/>
				</Row>
				<Row>
					{
						fileDetails && isFileUploading && <Progress.Line percent={uploadProgress} status={ uploadProgress === 100 ? "success" : "active" } className="sh-progress-bar-margin-top" />
					}
				</Row>
			</div>
		</div>;

	};

	const handleUpload = () => {

		if (fileDetails && selectedModule) {

			const payload = {
				courseId: selectedModule.courseId?._id,
				moduleId: selectedModule._id,
				fileType: fileDetails.type,
				fileName: fileDetails.name
			};

			ApiRequest("post", APIUrl.getSignedUrlUploadIntroVideo, payload).then(async (response: any) => {

				const url = response?.link;

				const onUploadProgress = (progressEvent: any) => {
	
					const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
					setUploadProgress(percentCompleted);
	
				};

				await axios.put(url, fileDetails, { headers: { "Content-Type": fileDetails.type }, onUploadProgress: onUploadProgress });

				
				setIsFileUploading(false);
				setIsFileLoaded(false);
				setIsUploadedMediaValid(false);
				setUploadPostMediaName("");
				setIsIntroVideoModelOpen(false);
				setFileDetails(null);
				setUploadProgress(0);
				toast.success("File uploaded successfully");
				

			}).catch((error: any) => {

				console.error("Error uploading file", error);
				setIsFileUploading(false);
				setIsFileLoaded(false);
				setIsUploadedMediaValid(false);
				setUploadPostMediaName("File type not supported");

			});

		}

	};


	return (
    
		<Container>

			<Row>
				<Col md={6} sm={6} xl={6} xs={6} >
					<div className="admin-dash-board-back-title-con" style={{ marginBottom: theme.spacing(2) }}
					>
						<Back
							props={{
								route  : config.routes.dashboard,
								state  : null,
								toolTip: "Back to Admin Dashboard",
								text   : "Back to admin dashboard"
							}}
						/>
					</div>
				</Col>
				<Col
					md={6} sm={6} xl={6} xs={6}
					className="sh-refresh-rotate-icon">
					<Button
						variant="outlined"
						style={{ backgroundColor: theme?.palette?.secondary.main, color: theme.palette.text.primary }}
						// className="sh-module-intro-add-btn"
						className="sh-module-intro-add-btn sh-list-lesson-btn"
						startIcon={<AddIcon />}
						// onClick={() => setIsAddModuleModelOpen(true)}
						onClick={()=> navigate(config.routes.addModulePage)}
					>Add</Button>
					&nbsp;&nbsp;&nbsp;
					<Button onClick={handleRotate} style={{ backgroundColor: theme?.palette?.secondary.main }} className="sh-list-lesson-refresh-icon-button sh-module-intro-add-btn">
						<FaRotateRight color={theme.palette.text.primary} />
					</Button>
				</Col>
			</Row>
			<Row>
				<Col md={12} sm={12} xl={12} xs={12} >
					<div className="admin-dash-board-back-title-con"
					>
						<Typography variant="h2" >List Modules</Typography>
					</div>
				</Col>
			</Row>
			<Row className="module-list-data-table">
				<Col className="module-list-data-table-col">
					<DataTable
						columns={columns}
						progressPending={isLoading}
						progressComponent={<div className="sh-table-progress-con"><Typography variant="h4">Loading...</Typography></div>}
						data={data}
						customStyles={DataTableStyles}
						fixedHeader
						pagination
						paginationPerPage={10}
					/>
				</Col>
			</Row>
			{/* <DeleteModal
				isListCourseDeleteModelOpen = {isListCourseDeleteModelOpen}
				setIsListCourseDeleteModelOpen ={setIsListCourseDeleteModelOpen}
				handleDelete={handleDelete}
				deleteContentPlaceHolder={{
					content: deleteModuleName.moduleName,
					type   : "Module"
				}}
			/> */}
			<ModalComponent
				/*
				 * isListCourseDeleteModelOpen={isListCourseDeleteModelOpen}
				 * props = {{
				 * 	icon        : FaExclamationTriangle,
				 * 	modalTitle  : "Confirm Delete",
				 * 	modalContent: <Typography variant="h3" style={{ color: theme.palette.text.primary }}>
				 * 			Are you sure you want to delete this {deleteModuleName.moduleName} ?</Typography>,
				 * 	modalFooter:
				 * 		<div className="" style={{ display: "flex", flexDirection: "row" }}>
				 * 			<div style={{ paddingRight: "10px" }}>
				 * 				<Button variant="outlined" onClick={() => setIsListCourseDeleteModelOpen(false)} style={{ paddingRight: "10px" }}>Cancel</Button>
				 * 			</div>
				 * 			<div>
				 * 				<Button variant="contained" color="error" onClick={handleDelete}>Delete</Button>
				 * 			</div>
				 * 		</div>,
				 * 	closeButton: true,
				 * 	backdrop   : true,
				 * 	size       : "lg"
				 * }}
				 * onHide={() => setIsListCourseDeleteModelOpen(false)}
				 * show={isListCourseDeleteModelOpen}
				 */
				show={isListCourseDeleteModelOpen}
				size="lg"
				closeButton={true}
				onHide={() => setIsListCourseDeleteModelOpen(false)}
				icon={FaExclamationTriangle}
				modalTitle="Confirm Delete"
				modalContent={<Typography variant="h3" style={{ color: theme.palette.text.primary }}>
					Are you sure you want to delete this {deleteModuleName?.moduleName} ?</Typography>}
				modalFooter={
					<div className="" style={{ display: "flex", flexDirection: "row" }}>
						<div style={{ paddingRight: "10px" }}>
							<Button variant="outlined" onClick={() => setIsListCourseDeleteModelOpen(false)} style={{ paddingRight: "10px" }}>Cancel</Button>
						</div>
						<div>
							<Button variant="contained" color="error" onClick={handleDelete}>Delete</Button>
						</div>
					</div>
				}
			/>
			<ModalComponent
				show={isIntroVideoModelOpen}
				onHide={() => setIsIntroVideoModelOpen(false)}
				modalTitle={`Upload Intro Video for ${selectedModule?.moduleName}`}
				modalContent={<div>
					<UploadIntroVideoContent />
				</div>}
				modalFooter={<div
					style={{
						display       : "flex",
						justifyContent: "flex-end",
						alignItems    : "center"
					}}
				>
					<MainButton
						type="scale"
						direction="bottom"
						style={{ position: "relative", marginRight: "10px" }}
						offset={matchesXS ? 0 : 20}
						scale={1.04}>
						<Button
							className="sh-module-intro-enroll-now-btn"
							variant="outlined"
							color="secondary"
							onClick={() => setIsIntroVideoModelOpen(false)}
						>
              Cancel
						</Button></MainButton>&nbsp;
					<MainButton
						type="scale"
						direction="bottom"
						style={{ position: "relative", marginRight: "10px" }}
						offset={matchesXS ? 0 : 20}
						scale={1.04}>
						<Button
							disabled={!isUploadedMediaValid || isFileUploading || !isFileLoaded}
							className="sh-module-intro-enroll-now-btn"
							variant="contained"
							color="secondary"
							onClick={handleUpload}
						>
              Upload
						</Button>
					</MainButton>
				</div>
				}
			/>
		</Container>
        
	);

};

export default ModuleLists;
